import {
  ChangePasswordDataType,
  NewPasswordDataType,
  OTPDataType,
  ProfileFormDataType,
} from 'src/core/types/profile-form-type';

export const initialValues: ProfileFormDataType = {
  name: '',
  username: '',
  email: '',
  mobile: '',
};

export const initialChangePasswordValues: ChangePasswordDataType = {
  email: '',
  password: '',
};
export const initialNewPasswordValues: NewPasswordDataType = {
  password: '',
  new_password: '',
};

export const initialOTPValues: OTPDataType = {
  otp: '',
};
