import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';
import { appRoutesObj } from 'src/app.paths';
import { Layout } from 'src/components/common/layout-component/layout-component';
import PopupCard from 'src/components/common/PopupCard';
import CommonQuestionsContainer from 'src/containers/user/CommonQuestionsContainer/common-questions-container';
import { opengraphMetaTags } from 'src/core/helpers/example.helper';

function CommonQuestionsPage() {
  const { t } = useTranslation();

  return (
    <Layout>
      <>
        <Helmet
          title={`${t('app.shared.theGarage')} | ${t('container.user.community.communityF&Q')}`}
          meta={[
            ...opengraphMetaTags({
              title: `${t('app.shared.theGarage')} | ${t('container.user.community.communityF&Q')}`,
              description: `${t('component.user.SidebarComponent.theGarageDescreption')}`,
              logo: '/assets/images/logo.png',
            }),
            {
              name: 'description',
              content: `${t('component.user.SidebarComponent.theGarageDescreption')}`,
            },
          ]}
        />
        <PopupCard
          link={appRoutesObj.getBasePath()}
          icon={<IoMdClose size={32} className="text-white" />}
        >
          <CommonQuestionsContainer />
        </PopupCard>
      </>
    </Layout>
  );
}

export default CommonQuestionsPage;
