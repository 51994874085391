import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface PageSelectProps {
  label1: string;
  label2?: string;
  page1?: JSX.Element;
  page2?: JSX.Element;
}
export default function PageSelect(props: PageSelectProps) {
  const { t, i18n } = useTranslation();
  const [toggle, setToggle] = useState(true);

  return (
    <div>
      <div
        className={`flex  ${
          i18n.language === 'en' ? 'font-helveticaMedium text-base' : 'font-arMyriad text-xl'
        } gap-x-10 text-center mobile:justify-center pt-6`}
      >
        <div
          className={`${toggle ? 'text-white' : 'text-neutral'}`}
          onClick={() => setToggle(true)}
        >
          {`${t(props.label1)}`}
          <div className={`${toggle ? 'bg-primary' : 'bg-neutral'} h-px w-40 mobile:w-32 mt-2`} />
        </div>
        {/* <div
          className={`${toggle ? " text-neutral" : "text-white"}`}
          onClick={() => setToggle(false)}
        >
          {`${t(props.label2)}`}
          <div
            className={`${
              toggle ? "bg-neutral" : " bg-primary"
            } h-px w-40 mobile:w-32 mt-2`}
          />
        </div> */}
      </div>
      <div className="w-full h-full">{toggle ? props.page1 : props.page2}</div>
    </div>
  );
}
