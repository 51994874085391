import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiMenu } from 'react-icons/bi';
import { BsXLg } from 'react-icons/bs';
import { PiMoonLight, PiSun } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import ChangeLanguage from 'src/components/common/ChangeLanguageComponent/change-language';
import useModeStore from 'src/core/helpers/useModeStore';

import NavbarButtonComponent from './navbar-button-component';
import LoginButtonComponent from '../login-button-component/login-button-component';

function NavBar() {
  const { mode, setMode } = useModeStore();
  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const handleChangeMode = () => {
    if (localStorage.getItem('mode')) {
      if (localStorage.getItem('mode') === 'dark') {
        localStorage.setItem('mode', 'light');
        setMode('light');
      } else {
        localStorage.setItem('mode', 'dark');
        setMode('dark');
      }
    } else {
      localStorage.setItem('mode', 'dark');
      setMode('dark');
    }
  };
  const changeLanguage = () => {
    if (i18n.language === 'ar') {
      i18n.changeLanguage('en');
      localStorage.setItem('language', 'en');
    } else {
      i18n.changeLanguage('ar');
      localStorage.setItem('language', 'ar');
    }
  };

  const isDarkMode = mode === 'dark';

  return (
    <div className="flex flex-row relative justify-between mobile:flex-row mobile:items-center p-3 h-fit mobile:bg-stone-500 mobile:bg-opacity-10 mobile:p-4 tablet:bg-stone-500 tablet:bg-opacity-10 tablet:p-4">
      <Link to={appRoutesObj.getBasePath()}>
        <img
          className="max-h-10 cursor-pointer mobile:pt-2 laptop:max-h-8 mobile:max-h-[50px]"
          src="/assets/images/Layer.png"
          alt="logo"
        />
      </Link>
      <div className="flex flex-row gap-2">
        <LoginButtonComponent />
        <div className="flex flex-row gap-2 tablet:hidden mobile:hidden">
          <ChangeLanguage />
          <NavbarButtonComponent
            onClick={handleChangeMode}
            iconOrText={isDarkMode ? <PiSun size={18} /> : <PiMoonLight size={18} />}
            color={'white'}
          />
        </div>

        <div className="desktop:hidden laptop:hidden largeLaptop:hidden">
          {openMobileMenu && (
            <div className="fixed z-50 top-0 left-0 h-[120px] w-screen bg-[#262626] text-white flex flex-col gap-4 pt-3 pb-2 px-4">
              <div className="flex flex-row place-self-end">
                <BsXLg size={18} onClick={() => setOpenMobileMenu(false)} />
              </div>
              <p
                onClick={() => {
                  handleChangeMode();
                  setOpenMobileMenu(false);
                }}
                className={`text-white ${
                  i18n.language === 'en'
                    ? 'text-base font-helveticaMedium'
                    : 'text-2xl/[24px] font-arMyriad'
                }`}
              >
                {isDarkMode
                  ? t('component.NavBarComponent.light')
                  : t('component.NavBarComponent.dark')}
              </p>
              <p
                onClick={() => {
                  changeLanguage();
                  setOpenMobileMenu(false);
                }}
                className="text-primary text-base font-helveticaMedium"
              >
                {i18n.language === 'ar' ? 'EN' : 'AR'}
              </p>
            </div>
          )}
          <NavbarButtonComponent
            onClick={() => setOpenMobileMenu(true)}
            iconOrText={<BiMenu size={18} />}
            color={'white'}
          />
        </div>
      </div>
    </div>
  );
}
export default NavBar;
