import { Navigate, Outlet } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import AuthAccessWrapperContainer from 'src/containers/common/auth-access-wrapper-container copy/auth-access-wrapper-container';

function ProtectedRoute() {
  const token = localStorage.getItem('token');

  if (!token) {
    return <Navigate to={appRoutesObj.getLoginPagePath()} />;
  }

  return (
    <AuthAccessWrapperContainer>
      <Outlet />
    </AuthAccessWrapperContainer>
  );
}

export default ProtectedRoute;
