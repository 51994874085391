import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useModeStore from 'src/core/helpers/useModeStore';
import { reservationItems } from 'src/core/statics/booking.static';

import CardComponent from './card-component';

function ReservationComponent() {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === 'dark';

  const [scrollX, setScrollX] = useState<number>(0);
  const scrollDivRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (scrollDivRef.current) {
      // To get the scroll position as a precentage from 0 to 100% OR -100%
      const { scrollLeft, scrollWidth, clientWidth } = scrollDivRef.current;
      const scrollableWidth = scrollWidth - clientWidth;
      const scrollPos = (scrollLeft / scrollableWidth) * 100;
      // To Update the value of the horizontal scroll `scrollX`
      setScrollX(scrollPos);
    }
  };

  useEffect(() => {
    const div = scrollDivRef.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
      return () => {
        div.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollDivRef.current, scrollX]);

  // To change the style of the fading mask based on the position of the scroll
  const maskValue =
    scrollX < 0 && scrollX > -98
      ? 'hover:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-100px),transparent_100%)]'
      : scrollX < -98
        ? 'hover:[mask-image:_linear-gradient(to_left,transparent_0,_black_128px,_black_calc(100%),transparent_0%)]'
        : 'hover:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%),transparent_0%)]';
  const maskValueEng =
    scrollX > 0 && scrollX < 98
      ? 'hover:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-100px),transparent_100%)]'
      : scrollX > 98
        ? 'hover:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%),transparent_0%)]'
        : 'hover:[mask-image:_linear-gradient(to_left,transparent_0,_black_128px,_black_calc(100%),transparent_0%)]';

  return (
    <div
      ref={scrollDivRef}
      className={
        `group flex flex-row w-full desktop:w-[calc(75vw-554px)] 
      largeLaptop:w-[calc(52vw-226px)] laptop:w-[calc(75vw-364px)] flex-nowrap gap-6 overflow-hidden 
      [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-100px),transparent_100%)]
      hover:snap-mandatory
      hover:snap-x
      hover:overflow-x-auto hover:px-1 ` + (i18n.language === 'ar' ? maskValue : maskValueEng)
      }
    >
      <div
        className={`flex flex-row w-fit gap-6 items-center justify-center md:justify-start h-full py-2 group-hover:animate-none ${
          i18n.language === 'ar' ? 'animate-infinite-scroll' : 'animate-infinite-scrollENG'
        } `}
      >
        {reservationItems.map((item, index) => (
          <Link to={item.link} key={index}>
            <CardComponent
              isHover={true}
              className={`w-[415px] h-[119px] snap-center largeLaptop:h-[100px] laptop:h-[100px] rounded-2xl from-neutral-900/10 via-neutral-900/10 to-secondary/10 ${
                i18n.language === 'en' ? 'bg-gradient-to-br' : 'bg-gradient-to-bl'
              } ${isDarkMode ? 'border border-gray-600/50' : 'border border-neutral/50'}`}
            >
              <div className="flex flex-col w-[100%]">
                <div
                  className={`-mt-0.5 pb-1 text-white ${
                    i18n.language === 'ar'
                      ? 'font-arMyriad text-xl'
                      : 'font-helveticaMedium text-base'
                  } `}
                >
                  {t(`${item.title}`)}
                </div>
                <div
                  className={`text-white h-8 pb-2 laptop:text-[10px] ${
                    i18n.language === 'en'
                      ? 'font-helveticaMedium text-sm'
                      : 'font-arMyriad text-right text-lg'
                  }`}
                >
                  {t(`${item.description}`)}
                </div>
              </div>
            </CardComponent>
          </Link>
        ))}
      </div>
      <div
        aria-hidden="true"
        className={`flex flex-row w-fit gap-6 items-center justify-center md:justify-start h-full py-2 group-hover:animate-none group-hover:hidden ${
          i18n.language === 'ar' ? 'animate-infinite-scroll' : 'animate-infinite-scrollENG'
        } `}
      >
        {reservationItems.map((item, index) => (
          <Link to={item.link} key={index}>
            <CardComponent
              isHover={true}
              className={`w-[415px] h-[119px] largeLaptop:h-[100px] laptop:h-[100px] rounded-2xl from-neutral-900/50 via-neutral-900/50 to-secondary/20 ${
                i18n.language === 'en' ? 'bg-gradient-to-br' : 'bg-gradient-to-bl'
              } ${isDarkMode ? 'border border-gray-600/50' : 'border border-neutral/50'}`}
            >
              <div className="flex flex-col w-[100%]">
                <div
                  className={`-mt-0.5 pb-1 text-white ${
                    i18n.language === 'ar'
                      ? 'font-arMyriad text-xl '
                      : 'font-helveticaMedium text-lg'
                  } `}
                >
                  {t(`${item.title}`)}
                </div>
                <div
                  className={`text-white h-8 pb-2 laptop:text-[10px] ${
                    i18n.language === 'en'
                      ? 'font-helveticaMedium text-sm'
                      : 'font-arMyriad text-right text-lg'
                  }`}
                >
                  {t(`${item.description}`)}
                </div>
              </div>
            </CardComponent>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ReservationComponent;
