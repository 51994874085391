import { useTranslation } from 'react-i18next';
import { FiCheck } from 'react-icons/fi';

interface ProgramStepsprops {
  activeIndex: number;
  index: number;
  color: string;
  completed?: boolean;
  label: string;
  completedClass?: string;
  className?: string;
  size?: string;
  onClick?: () => void;
}
export default function ProgramSteps({
  activeIndex,
  index,
  completed = false,
  color,
  label,
  className,
  completedClass,
  size,
}: ProgramStepsprops) {
  const { t, i18n } = useTranslation();

  return (
    <div className="pb-6 ">
      <div
        className={`flex h-5 mx-auto justify-center items-center text-center ${
          completed ? 'gap-x-0' : 'gap-x-1'
        }`}
        key={index}
      >
        {completed && (
          <div className="inline-flex text-center items-center justify-center mx-auto ">
            <FiCheck
              size={12}
              className={`text-white ${i18n.language === 'en' ? 'mr-1' : 'ml-1'}`}
            />
          </div>
        )}
        {activeIndex === index && completed === false && (
          <div>
            <div
              className={`w-2 h-2 ${size} rounded-full  ${
                activeIndex === index && completed === false ? 'bg-primary' : `bg-${color}`
              }`}
            ></div>
          </div>
        )}

        <p
          className={`w-full leading-tight whitespace-nowrap ${className} ${
            activeIndex === index && completed === false ? 'text-primary' : `text-${color}`
          }
            ${completed ? `text-white ${completedClass}` : ''}
          ${
            i18n.language === 'en'
              ? 'font-helveticaMedium text-base mobile:text-[10px]'
              : 'font-arMyriad text-lg mobile:text-[17px]'
          }
          `}
        >
          {`${t(label)}`}
        </p>
      </div>
    </div>
  );
}
