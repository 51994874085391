import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/ui/Button';
import { Checkbox } from 'src/components/common/ui/Checkbox';
import SelectComponent2, { optionsList } from 'src/components/common/ui/CV-select-component';
import GridView from 'src/components/common/ui/grid-view';
import InputField from 'src/components/common/ui/InputField';
import ProfileEditComponent from 'src/components/user/V2/profile-eddit-component';
import SaveCancelButton from 'src/components/user/V2/save-cance-button';
import i18n from 'src/core/configs/i18n';
import {
  CareerLevelEnum,
  EmploymentTypeEnum,
  NoticePeriodArEnum,
  NoticePeriodEnum,
  SpecializationArEnum,
  SpecializationEnum,
} from 'src/core/enums/profile.enum';
import { VariantsEnum, ColorsEnum, SizesEnum } from 'src/core/enums/ui.enums';
import { handleRequestError } from 'src/core/helpers/error.helper';
import useProfileStore from 'src/core/stores/setUserData';
import { CVType } from 'src/core/types/CV.type';
import { putCVService } from 'src/services/CV.service';

import { validationSchema } from './target-job-form.validation';
import { cvInitialValues } from '../initialValues';

function TargetJobContainer() {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const { data, setUserData } = useProfileStore();

  const handleSubmit = async (values: CVType) => {
    try {
      await putCVService(values);
      setUserData({ ...data, ...values });
      setIsEditing(false);
      handleRequestError.success(t('container.CV.confirmation.saved'));
    } catch (error) {
      handleRequestError(error);
    }
  };

  const specializationOptionsList: optionsList[] = Object.entries(SpecializationEnum).map(
    ([key, value]) => ({
      value: value,
      key: key,
    })
  );
  const specializationArOptionsList: optionsList[] = Object.entries(SpecializationArEnum).map(
    ([key, value]) => ({
      value: value,
      key: key,
    })
  );

  const careerLevelOptionsList: optionsList[] = Object.entries(CareerLevelEnum).map(
    ([key, value]) => ({
      value: t(`container.TargetJobContainer.careerLevel.${value}`),
      key: key,
    })
  );

  const noticePeriodOptionsList: optionsList[] = Object.entries(NoticePeriodEnum).map(
    ([key, value]) => ({
      value: value,
      key: key,
    })
  );

  const noticePeriodArOptionsList: optionsList[] = Object.entries(NoticePeriodArEnum).map(
    ([key, value]) => ({
      value: value,
      key: key,
    })
  );

  return (
    <Formik
      initialValues={{ ...cvInitialValues, ...data }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, handleChange, handleBlur, errors, touched, setFieldValue, resetForm }) => (
        <Form>
          <div className="flex flex-col gap-6">
            {!isEditing && (
              <div className="mobile:hidden tablet:hidden">
                <ProfileEditComponent
                  isEdit={isEditing}
                  onEditClick={() => {
                    setIsEditing(!isEditing);
                  }}
                />
              </div>
            )}
            <InputField
              label="container.TargetJobContainer.targetJobTitle"
              name="job_title"
              isValid={touched.job_title && !errors.job_title}
              value={values.job_title}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEditing}
              errorMsg={touched.job_title ? errors.job_title : ''}
            />
            <SelectComponent2
              name="specialization"
              selectLabel="container.TargetJobContainer.specialization"
              options={
                i18n.language === 'en' ? specializationOptionsList : specializationArOptionsList
              }
              value={values.specialization ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMsg={touched.specialization ? errors.specialization : ''}
              isValid={touched.specialization && !errors.specialization}
              disabled={!isEditing}
            />
            <InputField
              label="container.TargetJobContainer.companySector"
              name="company_sector"
              isValid={touched.company_sector && !errors.company_sector}
              value={values.company_sector}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEditing}
              errorMsg={touched.company_sector ? errors.company_sector : ''}
            />
            <GridView column="1">
              <p
                className={`${
                  i18n.language === 'en'
                    ? 'font-helveticaMedium text-base'
                    : 'font-arMyriad text-lg'
                } text-white`}
              >
                {t('container.TargetJobContainer.employmentType')}
              </p>
              {Object.values(EmploymentTypeEnum).map((employmentType) => (
                <Checkbox
                  key={employmentType}
                  name="employment_type"
                  label={`container.TargetJobContainer.employmentType.${employmentType}`}
                  disabled={!isEditing}
                  onChange={() => setFieldValue('employment_type', employmentType)}
                  checked={employmentType === values.employment_type}
                />
              ))}
            </GridView>
            <InputField
              label="container.TargetJobContainer.targetMonthlySalary"
              name="target_salary"
              isValid={touched.target_salary && !errors.target_salary}
              value={values.target_salary}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEditing}
              errorMsg={touched.target_salary ? errors.target_salary : ''}
            />
            <SelectComponent2
              name="level"
              selectLabel="container.TargetJobContainer.careerLevel"
              options={careerLevelOptionsList}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEditing}
              value={values.level ?? ''}
              errorMsg={touched.level ? errors.level : ''}
            />
            <SelectComponent2
              name="notice_period"
              selectLabel="container.TargetJobContainer.noticePeriod"
              options={i18n.language === 'en' ? noticePeriodOptionsList : noticePeriodArOptionsList}
              onChange={handleChange}
              disabled={!isEditing}
              value={values.notice_period ?? ''}
              errorMsg={touched.notice_period ? errors.notice_period : ''}
            />
            <InputField
              label="container.TargetJobContainer.lastSalary"
              name="last_salary"
              isValid={touched.last_salary && !errors.last_salary}
              value={values.last_salary || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEditing}
              errorMsg={touched.last_salary ? errors.last_salary : ''}
            />
            {!isEditing && (
              <div className="flex justify-center w-full desktop:hidden largeLaptop:hidden laptop:hidden items-center mx-auto gap-2">
                <Button
                  variant={VariantsEnum.Filled}
                  color={ColorsEnum.Primary}
                  size={SizesEnum.Medium}
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </Button>
              </div>
            )}
            {isEditing && (
              <SaveCancelButton
                onCancel={() => {
                  resetForm();
                  setIsEditing(false);
                }}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default TargetJobContainer;
