import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActivitiesComponent from 'src/components/user/activities-component';
import ReservationComponent from 'src/components/user/reservation-component';
import i18n from 'src/core/configs/i18n';
import useModeStore from 'src/core/helpers/useModeStore';

function BookingContainer() {
  const { t } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === 'dark';

  const [selectedOption, setSelectedOption] = useState<'firstoption' | 'secondoption'>(
    'firstoption'
  );

  return (
    <div
      className={`border border-1 rounded-2xl largeLaptop:h-[120px] laptop:h-[120px] w-[100%] mx-auto mobile:mx-0 mobile:px-0 mobile:h-[365px] tablet:w-full tablet:h-[400px] ${
        isDarkMode ? 'border-gray-600/50' : 'border-neutral/50'
      } `}
    >
      <div className="flex mobile:grid mobile:grid-cols-1 tablet:flex-col w-60 mobile:w-full laptop:ml-1 mobile:mx-0 tablet:w-full tablet:mx-auto">
        {/* Left Side */}
        <div className="w-72 tablet:w-[440px] flex flex-col mobile:flex-row mobile:w-full tablet:justify-between tablet:flex-row gap-1 p-2">
          <button
            className={`px-2 py-2 w-44 laptop:w-24 rounded-lg text-left mobile:h-10 tablet:text-lg  ${
              i18n.language === 'ar'
                ? 'text-right desktop:pr-2 font-arMyriad text-lg '
                : 'font-helveticaMedium  text-base '
            } ${
              selectedOption === 'firstoption'
                ? i18n.language === 'ar'
                  ? 'bg-gradient-to-l from-secondary/20 text-white '
                  : 'bg-gradient-to-r from-secondary/20 text-white'
                : ' text-neutral-400 '
            }  `}
            onClick={() => setSelectedOption('firstoption')}
          >
            {t('container.user.booking.BookingContainer.Activities')}
          </button>
          <button
            className={`px-2 py-2 w-44 laptop:w-24 rounded-lg text-left mobile:h-10 tablet:text-lg  ${
              i18n.language === 'ar'
                ? ' text-right desktop:pr-2 font-arMyriad text-lg'
                : ' font-helveticaMedium text-base'
            } ${
              selectedOption === 'secondoption'
                ? i18n.language === 'ar'
                  ? 'bg-gradient-to-l from-secondary/20 text-white'
                  : 'bg-gradient-to-r from-secondary/20 text-white'
                : ' text-neutral-400'
            }  `}
            onClick={() => setSelectedOption('secondoption')}
          >
            {t('container.user.booking.BookingContainer.Reservation')}
          </button>
        </div>
        <div className="mobile:px-2">
          <div className="h-32 w-0.5 bg-neutral/10 mx-2 largeLaptop:h-[120px] laptop:h-28 mobile:hidden tablet:hidden"></div>
          <div className="w-[100%] h-0.5 bg-neutral/10 my-0 tablet:w-[480px] tablet:mx-4 desktop:hidden largeLaptop:hidden laptop:hidden"></div>
        </div>
        {/* Right Side */}
        <div>
          {selectedOption === 'firstoption' && <ActivitiesComponent />}
          {selectedOption === 'secondoption' && <ReservationComponent />}
        </div>
      </div>
    </div>
  );
}

export default BookingContainer;
