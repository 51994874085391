import { baseURL, makeRequest } from 'src/core/data-access/http.service';
import { HttpMethods } from 'src/core/enums/httpMethods.enum';

export const garagePlusFormService = async (data: object, reCaptchaToken: string) => {
  return await makeRequest({
    url: `${baseURL}/plus/demo-day`,
    method: HttpMethods.POST,
    data,
    headers: { 'recaptcha-token': reCaptchaToken },
  });
};
