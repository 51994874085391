// eslint-disable-next-line import/named
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { isExpired } from 'react-jwt';
import { appRoutesObj } from 'src/app.paths';

import axiosObject from '../configs/axios.config';
import { HttpMethods } from '../enums/httpMethods.enum';

export const baseURL = process.env.REACT_APP_BACKEND_URL;

export type MakeRequest = {
  url: string;
  method: HttpMethods;
  data?: object | undefined;
  params?: object | undefined;
  headers?: AxiosRequestHeaders | object;
};

export const makeRequest = async (req: MakeRequest) => {
  const refreshToken = localStorage.getItem('refreshToken') as string;

  const { url, method, data, params, headers } = req as AxiosRequestConfig;
  const token = localStorage.getItem('token') as string;
  const TokenExpired = isExpired(token);

  if (TokenExpired && token != null) {
    await axiosObject
      .post(`${baseURL}/user/refresh-token`, {
        refresh_token: refreshToken,
      })
      .then(async (res) => {
        const token = await res.data.access_token;
        localStorage.setItem('token', token);
        const reFreshToken = res.data.refresh_token;
        localStorage.setItem('refreshToken', reFreshToken);
      })
      .catch((error) => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        document.location.href = appRoutesObj.getLoginPagePath();
        return error;
      });
  }
  return axiosObject({
    url,
    method,
    data,
    params,
    headers,
  }).then((res) => {
    return res.data;
  });
};
