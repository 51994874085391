import { onlyLetters_Ar_En } from 'src/core/statics/regex';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .matches(onlyLetters_Ar_En, 'form.validation.first.name.no.numbers')
    .required('form.validation.first.name.required')
    .min(2, 'form.validation.first.name.min.2')
    .max(20, 'form.validation.first.name.max.20'),
  email: Yup.string()
    .trim()
    .required('form.validation.email.required')
    .max(50, 'form.validation.email.max.50')
    .email('form.validation.email.valid.format'),
  title: Yup.string().required('form.validation.title.required'),
  question: Yup.string()
    .required('form.validation.question.required')
    .max(350, 'form.validation.question.max.350'),
});
