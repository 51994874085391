import React from 'react';
import { ImSpinner2 } from 'react-icons/im';
import i18n from 'src/core/configs/i18n';
import { ColorsEnum, SizesEnum, VariantsEnum } from 'src/core/enums/ui.enums';
import './dynamic-classes';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: SizesEnum;
  variant?: VariantsEnum;
  color?: ColorsEnum;
  loading?: boolean;
}
export const Button: React.FC<ButtonProps> = ({
  color = ColorsEnum.Primary,
  size = SizesEnum.Medium,
  variant = VariantsEnum.Filled,
  loading = false,
  ...props
}) => {
  const getSizes = () => {
    switch (size) {
      case SizesEnum.Small:
        return `${
          i18n.language === 'ar'
            ? 'px-7 font-arMyriadBold text-base'
            : 'px-5 font-helveticaBold text-xs'
        } mobile:px-4 mobile:w-1/2 mobile:whitespace-nowrap mobile:py-2.5 tablet:py-2.5 py-2 `;

      case SizesEnum.Medium:
        return `${
          i18n.language === 'ar' ? 'px-14 font-arMyriadBold' : 'px-12 font-helveticaBold'
        } mobile:px-8 py-3 text-base leading-none `;
      case SizesEnum.Large:
        return 'px-9 py-4 text-base';
      default:
        return 'px-5 py-3 text-sm';
    }
  };

  const getVariantWithColor = () => {
    switch (variant) {
      case VariantsEnum.Filled:
        return `text-buttonText-primary bg-${getColorClasses()} hover:bg-${getColorClasses()}-600`;
      case VariantsEnum.Outline:
        return `bg-transparent border border-${getColorClasses()} text-${getColorClasses()} hover:bg-${getColorClasses()} hover:text-white`;
      default:
        return `bg-transparent hover:text-${getColorClasses()}-600 text-${getColorClasses()}`;
    }
  };

  const getColorClasses = () => {
    switch (color) {
      case ColorsEnum.Danger:
        return 'danger';
      case ColorsEnum.Primary:
        return 'primary';
      case ColorsEnum.Secondary:
        return 'secondary';
      case ColorsEnum.White:
        return 'white';
      default:
        return color;
    }
  };

  return (
    <button
      {...props}
      className={`mobile:w-full text-center mx-auto items-center rounded-lg disabled:cursor-not-allowed disabled:opacity-40  ${
        i18n.language === 'en' ? 'font-helveticaBold' : 'font-arMyriadBold'
      } ${getSizes()} ${getVariantWithColor()} ${props.className ?? ''}`}
    >
      <span className={props.className ?? ''}>
        {loading ? (
          <ImSpinner2 size={16} className="animate-spin flex justify-center w-full" />
        ) : (
          props.children
        )}
      </span>
    </button>
  );
};
