import { useTranslation } from 'react-i18next';
import useModeStore from 'src/core/helpers/useModeStore';

interface TabsProps {
  tabs: string[];
  isSubSection?: boolean;
  onSelectTab: (index: number) => void;
  selectedIndex: number;
  children: React.ReactNode[];
}

function Tabs({ tabs, isSubSection, onSelectTab, selectedIndex, children }: TabsProps) {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();

  const safeSelectedIndex = Math.min(children.length - 1, Math.max(0, selectedIndex));

  return (
    <div className="overflow-hidden mx-3">
      <div
        className={`flex py-4 overflow-hidden mobile:overflow-auto ${isSubSection ? '' : 'hidden'}`}
      >
        {tabs.map((title, index) => (
          <div
            key={`tab-${title}-${index}`}
            className="flex items-center justify-center text-center relative w-full"
          >
            <button
              className={`px-3 w-full border-b pb-3 whitespace-nowrap ${
                i18n.language === 'ar' ? 'font-arMyriad text-base' : 'font-helvetica text-xs'
              } ${index === safeSelectedIndex ? 'text-yellow-500' : 'text-white'} ${
                mode === 'dark' ? 'border-gray-600/50' : 'border-neutral/50'
              }`}
              onClick={() => onSelectTab(index)}
            >
              {t(title)}
              {index === safeSelectedIndex && (
                <div className="absolute inset-0 border-b-2 border-yellow-500" />
              )}
            </button>
          </div>
        ))}
      </div>
      <div className="h-[45vh] mobile:max-h-[52vh] mobile:mb-14 overflow-y-auto">
        {children[safeSelectedIndex]}
      </div>
    </div>
  );
}

export default Tabs;
