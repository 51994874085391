import { useTranslation } from 'react-i18next';
import useModeStore from 'src/core/helpers/useModeStore';

function Tabs({ title, onSelect }: { title: string; onSelect: () => void }) {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === 'dark';

  return (
    <div className={` ${isDarkMode ? 'border-gray-600/50' : 'border-neutral/50'}`}>
      <div className="flex py-2">
        <button
          className={`px-4 w-40 mobile:w-[150px] rounded-lg text-white ${
            i18n.language === 'ar'
              ? 'text-right font-arMyriad text-2xl'
              : 'text-left font-helvetica text-base'
          }`}
          onClick={() => onSelect()}
        >
          {t(title)}
        </button>
      </div>
    </div>
  );
}

export default Tabs;
