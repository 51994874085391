import PageSelect from 'src/components/user/page-select';

import AttendansApplyForm from './Attendees Apply/attendans-apply-form';
// import CompanyApplayForm from "./company-apply/company-apply-form";

function DistruptForm() {
  return (
    <div className="h-[540px] laptop:h-auto overflow-y-auto">
      <div className="flex justify-center gap-1 items-center">
        <img
          className="w-auto h-20 cursor-pointer justify-center flex items-center"
          src="/assets/images/disrupt-join.png"
          alt="logo"
        />
      </div>
      <div>
        <PageSelect
          label1="app.shared.attendeesApply"
          // label2="app.shared.copmanyApply"
          page1={<AttendansApplyForm />}
          // page2={<CompanyApplayForm />}
        />
      </div>
    </div>
  );
}

export default DistruptForm;
