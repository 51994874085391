import './markdown.css';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

type MarkdownDisplayComponentProps = {
  data: string;
};

export function MarkdownDisplayComponent({ data }: MarkdownDisplayComponentProps): JSX.Element {
  const [value, setValue] = useState<string>('');
  const { i18n } = useTranslation();

  useEffect(() => {
    setValue(data);
  }, [data]);

  return (
    <>
      <ReactMarkdown
        className={`mde-preview-content-user pt-5 ${
          i18n.language === 'ar' ? 'font-arMyriad text-lg' : 'font-helveticaMedium text-sm'
        }`}
        remarkPlugins={[gfm]}
        // eslint-disable-next-line react/no-children-prop
        children={value}
      />
    </>
  );
}
