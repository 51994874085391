interface NavbarButtonProps {
  iconOrText: JSX.Element | string;
  color: string;
  onClick: () => void;
}

function NavbarButtonComponent({ iconOrText, color, onClick }: NavbarButtonProps) {
  return (
    <button
      onClick={onClick}
      className={`flex flex-col items-center justify-center w-12 h-10 border border-gray-600 border-opacity-50 bg-neutral bg-opacity-10 rounded-lg text-base text-${color} cursor-pointer`}
    >
      {iconOrText}
    </button>
  );
}

export default NavbarButtonComponent;
