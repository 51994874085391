import React from 'react';
import CardPhotoComponent from 'src/components/common/card-photo-component';
import { SkeletonEnum } from 'src/core/enums/skeleton.enum';
import { getPlusCompaniesType } from 'src/core/types/plus.type';

import MediaSkeletonCard from '../startup/start-up-skeleton';

const AllStartupComponent: React.FC<{
  allData: getPlusCompaniesType;
  setSelectedData: (val: string) => void;
  Loading?: boolean;
}> = ({ allData, setSelectedData, Loading }) => {
  return (
    <div className="grid grid-cols-7 laptop:grid-cols-5 text-center place-content-center place-items-center largeLaptop:-mt-6 laptop:-mt-6 mobile:pt-16 mobile:grid-cols-2 mobile:mt-8 mobile:gap-1 mobile:px-2">
      {allData?.result_list.map((val) =>
        Loading ? (
          <MediaSkeletonCard key={val.id} size={SkeletonEnum.SMALL} />
        ) : (
          <div key={val.id}>
            <CardPhotoComponent key={val.id} text={val.startup_name}>
              <img
                src={val.logo}
                alt={val.startup_name}
                width={100}
                onClick={() => setSelectedData(val.id)}
                className="w-20 cursor-pointer rounded-lg h-16 laptop:w-[50px] largeLaptop:w-[45px] mobile:h-[94px] mobile:w-[97px] object-contain"
              />
            </CardPhotoComponent>
          </div>
        )
      )}
    </div>
  );
};

export default AllStartupComponent;
