import { useTranslation } from 'react-i18next';

interface RouteItem {
  path: string;
  label: string;
  sublabel?: string; // Optional property since not all routes have it
}

export default function MenuComponent({ label, path, sublabel }: RouteItem) {
  const { t, i18n } = useTranslation();

  return (
    <a
      className={`text-white text-base px-3 relative flex flex-col pb-3 pt-2 ${i18n.language === 'en' ? 'text-left' : 'text-right'}`}
      href={path}
    >
      {sublabel && <p className={`text-primary text-[8px] font-helveticaLight `}>{t(sublabel)}</p>}
      <p
        className={`leading-none hover:text-primary ${i18n.language === 'en' ? 'font-helveticaMedium text-base' : 'font-arMyriad text-sm'}`}
      >
        {t(label)}
      </p>
    </a>
  );
}
