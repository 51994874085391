import { useTranslation } from 'react-i18next';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function ToastMessage() {
  const { i18n } = useTranslation();

  return (
    <div dir={i18n.language === 'en' ? 'ltr' : 'rtl'}>
      <ToastContainer
        hideProgressBar={true}
        closeOnClick
        pauseOnHover
        transition={Slide}
        theme="colored"
        style={{
          position: 'fixed',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'min(657px, 90vw)',
          padding: '0 16px',
        }}
        toastClassName={(context) =>
          `mt-12 tablet:mt-16 text-tertiary-50 flex flex-row justify-center items-center rounded p-4 text-sm ${
            context?.type === 'success' ? 'bg-green-600' : 'bg-red-500'
          } ${i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'}`
        }
      />
    </div>
  );
}

export default ToastMessage;
