import type { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import useModeStore from 'src/core/helpers/useModeStore';

interface CardBorderGreenComponentProps {
  title: string;
  icon: IconComponent;
}

type IconComponent = ComponentType<{ className?: string }>;

export default function CardBorderGreenComponent({
  title,
  icon: Icon,
}: CardBorderGreenComponentProps) {
  const { i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === 'dark';

  return (
    <div className="flex justify-center items-center gap-x-2">
      <div
        className={`flex items-center justify-center gradient-card-border1 custom-bg-blur rounded-lg w-8 h-8 ${
          isDarkMode ? 'border-gray-600/50' : 'border-neutral/50'
        }`}
      >
        <Icon className="h-4 text-white" />
      </div>
      <span
        className={`text-white ${
          i18n.language === 'en' ? 'font-helveticaMedium text-xs' : 'font-arMyriad text-lg'
        }`}
      >
        {title}
      </span>
    </div>
  );
}
