import { Form } from 'formik';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiArrowLeft } from 'react-icons/pi';
import { useNavigate, useLocation } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import { Button } from 'src/components/common/ui/Button';
import LoginCardComponent from 'src/components/user/login-card-compoent';
import OTPComponent from 'src/components/user/OTP-component';
import { handleRequestError } from 'src/core/helpers/error.helper';
import getLanguageBasedClassName from 'src/core/helpers/languageBasedClassName';
import { OTPDataType } from 'src/core/types/profile-form-type';
import { putVerifyEmail, updateProfile } from 'src/services/profile.service';

import { initialOTPValues } from './profile-initialValues';
import { OTPValidationSchema } from './profile-validationSchema';

function OTPForm() {
  const time = 59;
  const navigate = useNavigate();
  const location = useLocation();
  const profileData = location.state?.profileData;
  const { t, i18n } = useTranslation();
  const handleSubmit = async (values: OTPDataType) => {
    try {
      if (!profileData) {
        const error = 'No profile data found';
        handleRequestError.message(error);
        return;
      }

      // Verify email with OTP
      await putVerifyEmail({ ...profileData, otp: values.otp });

      // Update profile after successful verification
      await updateProfile(profileData);

      handleRequestError.success('The information updated successfully');
      navigate(appRoutesObj.getProfilePagePath());
    } catch (error) {
      setIsError(true);
    }
  };
  const [timer, setTimer] = useState(time);
  const [isError, setIsError] = useState(false);
  const [timerDone, setTimerDone] = useState(false);

  // waiting for the resend button to be explaned by business and ui
  // const handleResend = () => {
  //   setTimerDone(false);
  //   setTimer(time);
  // };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!timerDone && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            setTimerDone(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timer, timerDone]);

  return (
    <div className="flex flex-col justify-center items-center mx-auto py-10 mobile:py-20 tablet:py-20">
      <LoginCardComponent>
        <div className="flex flex-col gap-y-9 justify-center h-full px-24 mobile:px-2 mobile:w-[calc(100vw-32px)]">
          <div className="flex flex-col w-full gap-y-7">
            <PiArrowLeft
              className={`cursor-pointer ${i18n.language === 'en' ? 'rotate-0' : 'rotate-180'}`}
              onClick={() => navigate(appRoutesObj.getProfilePagePath())}
              color="white"
              size={32}
            />

            <div className="flex flex-col gap-y-2">
              <p
                className={`text-white ${getLanguageBasedClassName(
                  'text-3xl font-helveticaBold',
                  'text-4xl font-arMyriad'
                )}`}
              >
                {t('container.OTPFormContainer.verify')}
              </p>
              <p
                className={`text-white  text-base ${getLanguageBasedClassName(
                  'font-helveticaMedium',
                  'font-arMyriad'
                )}`}
              >
                {t('container.OTPFormContainer.Description', { email: profileData?.email })}
              </p>
            </div>
          </div>
          <Formik
            initialValues={initialOTPValues}
            validationSchema={OTPValidationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <Form className="flex flex-col gap-y-5">
                <OTPComponent
                  name="OTP"
                  length={6}
                  value={values.otp}
                  onChange={(e) => setFieldValue('otp', e.target.value)}
                  error={!!(touched.otp && errors.otp) || isError}
                  errorMessage={isError ? 'container.OTPFormContainer.invalidOTP' : errors.otp}
                />
                {/* <p className="text-xs text-white font-helveticaLight">
                  {/* waiting for the resend button to be explaned by business and ui */}
                {/* {timerDone ? (
                    <span className="cursor-pointer hover:text-primary" onClick={handleResend}>
                      {t('container.OTPFormContainer.resend')}
                    </span>
                  ) : ( */}
                {/* {t('container.OTPFormContainer.timer', { seconds: timer })} */}
                {/* )} */}
                {/* </p> */}
                <Button type="submit" className="w-full">
                  {t('container.OTPFormContainer.button.verify')}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </LoginCardComponent>
    </div>
  );
}

export default OTPForm;
