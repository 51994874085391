import { phoneNumber } from 'src/core/statics/regex';
import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    email: Yup.string().email('Please enter a valid email address.').nullable(),
    mobile: Yup.string()
      .matches(phoneNumber, 'Please enter a valid phone number.')
      .min(9)
      .max(15)
      .nullable(),
    country: Yup.string().nullable(),
  });
