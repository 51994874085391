import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiEnvelope } from 'react-icons/bi';
import { BsCheck2Circle } from 'react-icons/bs';
import { PiArrowLeft } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import { Button } from 'src/components/common/ui/Button';
import InputFieldV2 from 'src/components/common/ui/InputFieldV2';
import LoginCardComponent from 'src/components/user/login-card-compoent';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { postSendEmailResetPasswordService } from 'src/services/user.service';

import { forgotPasswordInitialValues } from './forgot-password-form.data';
import { forgotPasswordValidationSchema } from './forgot-password-form.validation';

function ForgotPasswordContainer() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [confirmationMessage, setConfirmationMessage] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      await postSendEmailResetPasswordService(formik.values);
      setConfirmationMessage(true);
    } catch (error) {
      handleRequestError(error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: forgotPasswordInitialValues,
    validationSchema: forgotPasswordValidationSchema(),
    onSubmit: async () => {
      await handleSubmit();
    },
  });

  return (
    <div className="flex flex-col justify-center items-center mx-auto py-10 mobile:py-20 tablet:py-20">
      <LoginCardComponent>
        {confirmationMessage ? (
          <div className="flex flex-col gap-2 justify-center items-center h-full mobile:py-44 mobile:w-[calc(100vw-32px)]">
            <BsCheck2Circle size={61} className="text-secondary" />
            <p
              className={`text-white mobile:text-base text-center mobile:px-10 ${
                i18n.language === 'en' ? 'font-helveticaMedium text-base' : 'font-arMyriad text-2xl'
              }`}
            >
              {t('container.ForgotPasswordContainer.confirmationMessage')}
            </p>
          </div>
        ) : (
          <div className="flex flex-col h-full justify-center mobile:py-[93px] px-24 mobile:px-2 mobile:w-[calc(100vw-32px)]">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <PiArrowLeft
                className={`cursor-pointer ${i18n.language === 'en' ? 'rotate-0' : 'rotate-180'}`}
                onClick={() => navigate(appRoutesObj.getLoginPagePath())}
                color="white"
                size={32}
              />

              <p
                className={`text-white pt-6 ${
                  i18n.language === 'en' ? 'text-3xl font-helveticaBold' : 'text-4xl font-arMyriad'
                }`}
              >
                {t('container.ForgotPasswordContainer.forgotYourPassword')}
              </p>
              <p
                className={`text-white pt-2 pb-8 text-base ${
                  i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'
                }`}
              >
                {t('container.ForgotPasswordContainer.provideAccountEmail')}
              </p>
              <InputFieldV2
                name="email"
                type="text"
                label={`${t('app.shared.email')}`}
                icon1={<BiEnvelope className="text-white" size={20} />}
                isValid={formik.touched.email && !formik.errors.email}
                value={formik.values.email != null ? formik.values.email : ''}
                errorMsg={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                onChange={(e) => {
                  formik.setFieldValue('email', e.target.value);
                  formik.handleChange(e);
                }}
              />
              <Button
                className={`flex flex-row items-center justify-center h-10 w-full my-6 ${
                  i18n.language === 'en' ? 'font-helveticaBold text-xs' : 'font-arMyriad text-base'
                }`}
                type="submit"
              >
                {t('container.ForgotPasswordContainer.submit')}
              </Button>
            </form>
          </div>
        )}
      </LoginCardComponent>
    </div>
  );
}

export default ForgotPasswordContainer;
