import { SupportedFileType } from 'src/services/upload.service';

export const checkSizeAndExtensions = (ext: string, size: number, maxSizeMB: 2 | 3 = 2) => {
  const validExtension: boolean =
    Object.values(SupportedFileType).findIndex((type) => type.toLowerCase() === ext.toLowerCase()) >
    -1;
  const maxImageSize = 10485760 * maxSizeMB; // 2 MB or 3 MB
  const validSize = size < maxImageSize;

  return validExtension && validSize;
};
