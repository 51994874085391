import { useState } from 'react';
import EmptyStateCardComponent from 'src/components/user/V2/empty-state-card';
import useProfileStore from 'src/core/stores/setUserData';

import LanguageView from './language-view';
import LanguageForm from './languageForm';

export default function LanguageContainer() {
  const { data } = useProfileStore();
  const [isLanguage, setIsLanguage] = useState(false);
  const [selectedId, setSelectedId] = useState<string>();
  const languageData = [...(data.language || [])];

  const handleAdd = () => {
    setSelectedId(undefined);
    setIsLanguage(true);
  };

  return (
    <div>
      {languageData.length === 0 && !isLanguage ? (
        <EmptyStateCardComponent
          title="container.languageContainer.noLanguage"
          buttonLabel="container.languageContainer.addLanguage"
          buttonOnClick={() => {
            setIsLanguage(true);
            setSelectedId(undefined);
          }}
        />
      ) : isLanguage ? (
        <LanguageForm setIsLanguage={setIsLanguage} selectedId={selectedId} />
      ) : (
        <LanguageView onAdd={handleAdd} />
      )}
    </div>
  );
}
