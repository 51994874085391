import { Layout } from 'src/components/common/layout-component/layout-component';
import MenueImgComponent from 'src/components/user/media-img-component';

function MediaPage() {
  return (
    <Layout>
      <MenueImgComponent />
    </Layout>
  );
}

export default MediaPage;
