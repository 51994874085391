import type { FormikProps } from 'formik';
import { useState } from 'react';
import { uploadImage } from 'src/services/upload.service';
import { FileTypeEnum } from 'src/services/upload.service';

import { handleRequestError } from '../error.helper';

interface UseImageUploadProps<T = Record<string, unknown>> {
  onChange: (value: string, file?: File) => void;
  name: string;
  formik?: FormikProps<T>;
  maxSizeMB?: number;
}

export const useImageUpload = <T = Record<string, unknown>>({
  onChange,
  name,
  formik,
  maxSizeMB = 3,
}: UseImageUploadProps<T>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);

  const handleFileUpload = async (file: File): Promise<void> => {
    try {
      setIsLoading(true);
      setUploadError(null);

      // Check file size
      const fileSizeMB = file.size / (1024 * 1024);
      if (fileSizeMB > maxSizeMB) {
        setUploadError(`errors.fileSizeExceeded.${maxSizeMB}MB`);
        return;
      }

      // Get file extension
      const imgExtension = file.type.replace('image/', '');

      const filePath = await uploadImage(FileTypeEnum.image, imgExtension, file);

      if (!filePath) {
        setUploadError('errors.uploadFailed');
        return;
      }

      onChange(filePath);
      if (formik?.setFieldValue) {
        formik.setFieldValue(name, filePath);
      }
    } catch (error) {
      handleRequestError(error);
      setUploadError('errors.uploadFailed');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleFileUpload,
    isLoading,
    uploadError,
  };
};

export default useImageUpload;
