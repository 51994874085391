import { useTranslation } from 'react-i18next';
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';

interface LayoutProps {
  title: string;
  description: string;
}

export function SignUpCardComponent({ title, description }: LayoutProps) {
  const { t, i18n } = useTranslation();

  const isArabic = i18n.language === 'ar';

  return (
    <div className="flex flex-col place-self-start pt-6 px-24 mobile:px-1">
      <Link to={appRoutesObj.getLoginPagePath()}>
        {isArabic ? (
          <HiOutlineArrowRight className="text-white" size={32} />
        ) : (
          <HiOutlineArrowLeft className="text-white" size={32} />
        )}
      </Link>
      <div>
        <div className="pb-2 pt-6 w-fit">
          <h1
            className={`${`${
              i18n.language === 'en' ? 'text-3xl' : 'text-4xl'
            }`} ${'text-white' || 'text-white'}
        ${
          i18n.language === 'en'
            ? 'font-helveticaMedium pb-1 font-bold'
            : 'font-arMyriad pb-0 largeLaptop:-mb-1 laptop:-mb-1'
        }
         `}
          >
            {t(`${title}`)}
          </h1>
        </div>
        <div
          className={`text-tertiary-50 pb-8 ${
            i18n.language === 'en'
              ? 'text-base font-helveticaMedium mobile:pr-0'
              : 'text-base font-normal font-arMyriad leading-none'
          } `}
        >
          {t(`${description}`)}
        </div>
      </div>
    </div>
  );
}
