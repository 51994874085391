import { Layout } from 'src/components/common/layout-component/layout-component';
import Startup from 'src/containers/user/startup/startup-container';

function LandingPage() {
  return (
    <Layout>
      <Startup />
    </Layout>
  );
}

export default LandingPage;
