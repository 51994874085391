import { useTranslation } from 'react-i18next';

export default function LinkInputComponent({
  title,
  link,
  onClick,
}: {
  title: string;
  link: string;
  onClick?: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-2 w-fit">
      <div className="text-base text-white font-helvetica">{t(title)}</div>
      <div
        className="text-base text-primary hover:text-primary-600 cursor-pointer"
        onClick={onClick}
      >
        {t(link)}
      </div>
    </div>
  );
}
