import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import { handleRequestError } from 'src/core/helpers/error.helper';
import useUser from 'src/core/hooks/useUser';
import { routes } from 'src/core/statics/navBar-routing';

import MenuComponent from './menu-component';

export default function LoginButtonComponent() {
  const { t, i18n } = useTranslation();
  const { userUsername } = useUser();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  const buttonClassName = `h-10 rounded-lg border border-primary text-primary
    ${i18n.language === 'en' ? 'font-helveticaBold text-xs' : 'font-arMyriad text-base'}`;
  const handleClick = () => {
    if (!userUsername) {
      navigate(appRoutesObj.getLoginPagePath());
    } else {
      setToggle(!toggle);
    }
  };

  const handleLogout = () => {
    try {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      setToggle(false);
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <div>
      <button onClick={handleClick} className={`${buttonClassName} w-[114px] relative`}>
        {userUsername ? (
          <div className="flex justify-center items-center gap-1">
            {userUsername.length > 10 ? userUsername.slice(0, 10) + '...' : userUsername}

            {toggle ? <FiChevronUp /> : <FiChevronDown />}
          </div>
        ) : (
          t('app.shared.login')
        )}
        {toggle && (
          <div className="absolute top-11 right-0 w-44 pt-3 justify-center bg-neutral-800 text-white rounded-lg flex flex-col gap-1 z-10">
            {routes.map((route, index) => (
              <div
                key={route.path}
                onClick={() => route.label === 'navbar.logout' && handleLogout()}
                className=""
              >
                <MenuComponent {...route} />
                {index !== routes.length - 1 && <hr className="border-neutral-700 mx-2" />}
              </div>
            ))}
          </div>
        )}
      </button>
    </div>
  );
}
