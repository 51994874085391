import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/ui/Button';
import { VariantsEnum } from 'src/core/enums/ui.enums';

export default function SaveCancelButton({
  onSave,
  onCancel,
}: {
  onSave?: () => void;
  onCancel?: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center mobile:w-full mx-auto gap-x-3">
      <Button type="submit" className="text-xs mx-1" variant={VariantsEnum.Filled} onClick={onSave}>
        {t('app.shared.save')}
      </Button>
      <Button
        type="button"
        className="text-xs mx-1"
        variant={VariantsEnum.Outline}
        onClick={onCancel}
      >
        {t('app.shared.cancel')}
      </Button>
    </div>
  );
}
