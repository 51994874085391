interface CardPhotoComponent {
  children?: JSX.Element | JSX.Element[];
  text?: string;
}

function CardPhotoComponent({ children, text }: CardPhotoComponent) {
  return (
    <div className="group flex flex-col  gap-2 justify-center items-center">
      <div className="flex h-[72px] w-[74px] bg-white items-center justify-center rounded-2xl">
        {children}
      </div>
      {text && (
        <div className="font-helveticaMedium group-hover:text-primary text-white h-16">{text}</div>
      )}
    </div>
  );
}

export default CardPhotoComponent;
