import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TitleComponent from 'src/components/common/title-component';
import useModeStore from 'src/core/helpers/useModeStore';
import { ImagesPartners } from 'src/core/statics/partner.static';

function AboutTheGarageContainer() {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();

  return (
    <div className="flex flex-col gap-y-12 laptop:gap-y-6 mobile:gap-y-10 laptop:w-auto laptop:px-2 px-4 mobile:px-0 mobile:h-full mobile:pb-8">
      <div className="flex flex-col">
        <TitleComponent title="app.shared.theGarage" color="text-white" />
        <p
          className={`flex flex-col text-neutral-400 ${
            i18n.language === 'ar'
              ? 'text-xl leading-6 laptop:text-sm font-arMyriad'
              : 'text-lg laptop:text-xs font-helveticaMedium'
          }`}
        >
          <span>{t(`container.aboutTheGarageContainer.theGarage.title`)}</span>
          <span>{t(`container.aboutTheGarageContainer.theGarage.subtitle`)}</span>
        </p>
      </div>
      <div className="flex flex-col">
        <TitleComponent title="app.shared.theGaragePartners.businessModel" color="text-white" />
        <p
          className={`flex flex-col text-neutral-400 ${
            i18n.language === 'ar'
              ? 'text-xl leading-6 laptop:text-sm font-arMyriad'
              : 'text-lg laptop:text-xs font-helveticaMedium'
          }`}
        >
          <span>{t('container.aboutTheGarageContainer.businessModel.title')}</span>
        </p>
      </div>
      <div className="flex flex-col">
        <TitleComponent title="app.shared.theGaragePartners.partners" />
        <div className="flex pt-2 gap-x-4 mobile:grid mobile:grid-cols-1 mobile:gap-4">
          {ImagesPartners?.map((item, index) => (
            <Link key={index} to={item.link}>
              <div className="mx-auto rounded-2xl p-4 laptop:p-2 border w-[151px] h-[123px] laptop:w-[110px] laptop:h-[95px] border-gray-600/50 ">
                <img
                  className="object-contain object-center w-full h-24 p-2 laptop:h-20 rounded-xl"
                  src={mode === 'dark' ? item.imgSrc : item.imagSrcLight}
                  alt={`partner ${item.name}`}
                />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AboutTheGarageContainer;
