import { onlyNumbers_En } from 'src/core/statics/regex';
import { onlyLetters_Ar_En } from 'src/core/statics/regex';
import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    job_title: Yup.string()
      .matches(onlyLetters_Ar_En, 'Target job title cannot contain special characters.')
      .nullable(),
    specialization: Yup.string().nullable(),
    company_sector: Yup.string()
      .matches(onlyLetters_Ar_En, 'Company sector cannot contain special characters.')
      .nullable(),
    employment_type: Yup.array().of(Yup.string()).nullable(),
    last_salary: Yup.string()
      .matches(onlyNumbers_En, 'Please enter a valid number for the monthly salary.')
      .nullable(),
    target_salary: Yup.string()
      .matches(onlyNumbers_En, 'Please enter a valid number for the monthly salary.')
      .nullable(),
    level: Yup.string().nullable(),
    notice_period: Yup.string().nullable(),
  });
