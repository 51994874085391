import { baseURL } from 'src/core/data-access/http.service';
import { makeRequest } from 'src/core/data-access/http.service';
import { HttpMethods } from 'src/core/enums/httpMethods.enum';
import {
  ChangePasswordDataType,
  NewPasswordDataType,
  ProfileFormDataType,
  VerifyEmailDataType,
} from 'src/core/types/profile-form-type';

export const updateProfile = async (data: ProfileFormDataType) => {
  return await makeRequest({
    url: `${baseURL}/user/profile/talent/edit`,
    method: HttpMethods.PUT,
    data,
  });
};
export const getProfile = async () => {
  return await makeRequest({
    url: `${baseURL}/user/profile/talent`,
    method: HttpMethods.GET,
  });
};

export const postVerifyPassword = async (data: ChangePasswordDataType) => {
  return await makeRequest({
    url: `${baseURL}/user/profile/verify-password`,
    method: HttpMethods.POST,
    data,
  });
};
export const putVerifyEmail = async (data: Partial<VerifyEmailDataType>) => {
  return await makeRequest({
    url: `${baseURL}/user/profile/verify-email`,
    method: HttpMethods.POST,
    data,
  });
};

export const postChangePasswordService = async (data: Partial<NewPasswordDataType>) => {
  return await makeRequest({
    url: `${baseURL}/user/password/change`,
    method: HttpMethods.POST,
    data,
  });
};
