import { SignUpType } from 'src/core/types/sign-up-type';

export const signUpType: SignUpType = {
  name: '',
  username: '',
  email: '',
  mobile: '',
  password: '',
  confirmPassword: '',
};
