import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  totalCount: number;
  totalPage: number;
  page: number;
  limit: number;
  onPageChange: (page: number) => void;
  paginationInfoText?: string;
};

function Pagination(props: Props) {
  const { t, i18n } = useTranslation();

  const { totalPage, page, onPageChange, limit, totalCount, paginationInfoText } = props;
  const isArabic = i18n.language === 'ar';

  // Ensure the page number is within valid bounds whenever props change
  useEffect(() => {
    if (page > totalPage) {
      onPageChange(totalPage);
    }
  }, [page, totalPage, onPageChange]);

  if (totalCount === 0) return null;

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPage) {
      onPageChange(newPage);
    }
  };

  const renderPageButton = (pageIndex: number) => (
    <li key={pageIndex}>
      <button
        className={`rounded-md border w-10 h-8 border-gray-600/50 ${
          pageIndex === page
            ? 'bg-primary text-black text-sm'
            : 'bg-neutral-500/10 text-white font-sans text-sm '
        }`}
        onClick={() => handlePageChange(pageIndex)}
      >
        {pageIndex}
      </button>
    </li>
  );

  const startPage = Math.floor((page - 1) / 5) * 5 + 1;
  const endPage = Math.min(startPage + 4, totalPage);

  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="grid items-center justify-between">
      <nav aria-label="Page navigation" className={`pt-3 ${isArabic ? 'right-[2%]' : 'left-[2%]'}`}>
        <ul className="inline-flex gap-2">
          <li>
            <button
              className="flex rounded-md border w-10 h-8 items-center border-gray-600/50 bg-neutral-500/10 justify-center"
              onClick={() => handlePageChange(page === 1 ? 1 : page - 1)}
            >
              {isArabic ? (
                <ChevronRightIcon strokeWidth={2} className="h-3 w-3 text-primary" />
              ) : (
                <ChevronLeftIcon strokeWidth={2} className="h-3 w-3 text-primary" />
              )}
            </button>
          </li>

          {pageNumbers.map((pageIndex) => renderPageButton(pageIndex))}

          <li>
            <button
              className="flex rounded-md border w-10 h-8 items-center border-gray-600/50 bg-neutral-500/10 justify-center"
              onClick={() => handlePageChange(page === totalPage ? totalPage : page + 1)}
            >
              {isArabic ? (
                <ChevronLeftIcon strokeWidth={2} className="h-3 w-3 text-primary" />
              ) : (
                <ChevronRightIcon strokeWidth={2} className="h-3 w-3 text-primary" />
              )}
            </button>
          </li>
        </ul>
      </nav>
      {paginationInfoText && (
        <h1 className="text-neutral-500/10 my-9 whitespace-nowrap">
          {t('Showing')} {(page - 1) * limit + 1} {t('to')} {Math.min(page * limit, totalCount)}{' '}
          {t('of')} {totalCount}
        </h1>
      )}
    </div>
  );
}

export default Pagination;
