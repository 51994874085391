import { useState, useEffect } from 'react';
import useProfileStore from 'src/core/stores/setUserData';
import { TrainingType } from 'src/core/types/CV.type';

export const useTrainingData = (selectedId?: string) => {
  const [trainingData, setTrainingData] = useState<TrainingType>();
  const { data } = useProfileStore();

  useEffect(() => {
    if (!selectedId) {
      setTrainingData(undefined);
      return;
    }
    const selected = data?.training?.find((training) => training.id === selectedId);
    setTrainingData(selected);
  }, [selectedId, data]);

  return { trainingData, setTrainingData };
};
