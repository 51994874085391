import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/ui/Button';
import SelectComponent2 from 'src/components/common/ui/CV-select-component';
import { optionsList } from 'src/components/common/ui/CV-select-component';
import DateField from 'src/components/common/ui/DateField2';
import InputField from 'src/components/common/ui/InputField';
import RadioButtonComponent from 'src/components/common/ui/radio-button-component';
import Upload from 'src/components/common/ui/UploadComponent';
import ProfileEditComponent from 'src/components/user/V2/profile-eddit-component';
import SaveCancelButton from 'src/components/user/V2/save-cance-button';
import { GenderEnum, MaritalStatusEnum, NationalityEnum } from 'src/core/enums/profile.enum';
import { VariantsEnum, ColorsEnum, SizesEnum } from 'src/core/enums/ui.enums';
import { handleRequestError } from 'src/core/helpers/error.helper';
import useProfileStore from 'src/core/stores/setUserData';
import { CVType } from 'src/core/types/CV.type';
import { putCVService } from 'src/services/CV.service';

import { validationSchema } from './general-info-form.validation';
import { cvInitialValues } from '../initialValues';

function GeneralInfoContainer() {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const { data, setUserData } = useProfileStore();
  const optionsList: optionsList[] = Object.entries(NationalityEnum).map(([key, value]) => ({
    value: value,
    key: key,
  }));
  const handleSubmit = async (values: CVType) => {
    try {
      await putCVService({ ...data, ...values });
      setUserData({ ...data, ...values });
      setIsEditing(false);
      handleRequestError.success(t('container.CV.confirmation.saved'));
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <Formik
      initialValues={data || cvInitialValues}
      validationSchema={validationSchema()}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, handleChange, handleBlur, errors, touched, setFieldValue, resetForm }) => (
        <Form className="flex flex-col gap-6">
          <div className="flex justify-between">
            {!isEditing && (
              <div className="mobile:hidden tablet:hidden">
                <ProfileEditComponent
                  isEdit={isEditing}
                  onEditClick={() => {
                    setIsEditing(!isEditing);
                  }}
                />
              </div>
            )}
            <div className="flex order-first space-x-4">
              <Upload
                label={t('container.GeneralInfoContainer.profilePhoto')}
                onChange={(filePath: string) => {
                  setFieldValue('image', filePath, true);
                }}
                name="image"
                id="image"
                hint={t('container.GeneralInfoContainer.maximumUploadFileSize')}
                image={values.image ?? data.image ?? ''}
                error={touched.image && errors.image ? (errors.image as string) : undefined}
                disabled={!isEditing}
              />
            </div>
          </div>
          <div>
            <h2 className="text-white pb-4">
              {t('container.GeneralInfoContainer.personalInformation')}
            </h2>

            <div className="grid grid-cols-2 gap-2">
              <InputField
                label="container.GeneralInfoContainer.firstName"
                disabled={!isEditing}
                name="first_name"
                type="text"
                isValid={touched.first_name && !errors.first_name}
                value={values.first_name ?? ''}
                errorMsg={touched.first_name ? errors.first_name : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputField
                label="container.GeneralInfoContainer.lastName"
                disabled={!isEditing}
                name="last_name"
                type="text"
                isValid={touched.last_name && !errors.last_name}
                value={values.last_name ?? ''}
                errorMsg={touched.last_name ? errors.last_name : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <InputField
              name="id_number"
              label="container.GeneralInfoContainer.idNumber"
              value={values.id_number ?? ''}
              isValid={touched.id_number && !errors.id_number}
              errorMsg={touched.id_number ? errors.id_number : ''}
              disabled={!isEditing}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>

          <div className="grid">
            <p className="px-2 text-base text-white">
              {t('container.GeneralInfoContainer.maritalStatus')}
            </p>
            <div className="flex gap-2">
              {Object.values(MaritalStatusEnum).map((marital) => (
                <RadioButtonComponent
                  key={marital}
                  label={`container.GeneralInfoContainer.maritalStatus.${marital}`}
                  name="marital"
                  value={marital}
                  onChange={(e) => setFieldValue('marital', e.target.value)}
                  checked={marital === values.marital}
                  disabled={!isEditing}
                  onBlur={handleBlur}
                />
              ))}
            </div>
          </div>

          <div className="grid">
            <p className="px-2 text-base text-white">
              {t('container.GeneralInfoContainer.gender')}
            </p>
            <div className="flex gap-2">
              {Object.values(GenderEnum).map((gender) => (
                <RadioButtonComponent
                  key={gender}
                  label={`container.GeneralInfoContainer.gender.${gender}`}
                  name="gender"
                  value={gender}
                  onChange={(e) => setFieldValue('gender', e.target.value)}
                  checked={gender === values.gender}
                  disabled={!isEditing}
                  onBlur={handleBlur}
                />
              ))}
            </div>
          </div>

          <div className="grid gap-4">
            <p className="px-2 text-base text-white pb-4">
              {t('container.GeneralInfoContainer.nationality')}
            </p>
            <div className="relative z-50">
              <SelectComponent2
                selectLabel={t('container.GeneralInfoContainer.nationality')}
                name="nationality"
                isValid={touched.nationality && !errors.nationality}
                value={values.nationality ?? ''}
                errorMsg={touched.nationality ? errors.nationality : ''}
                options={optionsList}
                onChange={handleChange}
                disabled={!isEditing}
                onBlur={handleBlur}
              />
            </div>
            <DateField
              label="container.GeneralInfoContainer.dateOfBirth"
              disabled={!isEditing}
              isBirthDate={true}
              name="date_of_birthday"
              value={values.date_of_birthday ?? ''}
              isValid={touched.date_of_birthday && !errors.date_of_birthday}
              errorMsg={touched.date_of_birthday ? errors.date_of_birthday : ''}
              onChange={(e) => setFieldValue('date_of_birthday', e.target.value)}
            />
          </div>
          {!isEditing && (
            <div className="flex justify-center w-full desktop:hidden largeLaptop:hidden laptop:hidden items-center mx-auto gap-2">
              <Button
                variant={VariantsEnum.Filled}
                color={ColorsEnum.Primary}
                size={SizesEnum.Medium}
                onClick={() => setIsEditing(true)}
              >
                Edit
              </Button>
            </div>
          )}
          {isEditing && (
            <SaveCancelButton
              onCancel={() => {
                resetForm();
                setIsEditing(false);
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default GeneralInfoContainer;
