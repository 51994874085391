import React from 'react';
import TitleComponent from 'src/components/common/title-component';
import ActivitiesComponent from 'src/components/user/activities-component';
import ReservationMobileComponent from 'src/components/user/reservation-mobile-componet';

export default function MobileBookingPage() {
  return (
    <div className="h-screen grid grid-flow-row">
      <TitleComponent title="container.user.booking.BookingContainer.Activities" />
      <ActivitiesComponent />
      <TitleComponent title="container.user.booking.BookingContainer.Reservation" />
      <ReservationMobileComponent />
    </div>
  );
}
