import { appRoutesObj } from 'src/app.paths';

export const routes = [
  {
    path: appRoutesObj.getProfilePagePath(),
    label: 'navbar.profile',
  },
  {
    path: '',
    label: 'navbar.careers',
    sublabel: 'navbar.soon',
  },
  {
    path: appRoutesObj.getLoginPagePath(),
    label: 'navbar.logout',
  },
];
