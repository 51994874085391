import React from 'react';
import { Layout } from 'src/components/common/layout-component/layout-component';
import ResetPasswordContainer from 'src/containers/user/reset-password-container/reset-password-container';

function ResetPasswordPage() {
  return (
    <Layout sidebar={false}>
      <ResetPasswordContainer />
    </Layout>
  );
}

export default ResetPasswordPage;
