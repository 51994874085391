import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColorsEnum, SizesEnum } from 'src/core/enums/ui.enums';
import useModeStore from 'src/core/helpers/useModeStore';
interface RadioButtonComponentProps extends React.InputHTMLAttributes<HTMLInputElement> {
  color?: ColorsEnum;
  label?: string;
  radioSize?: SizesEnum;
  labelSize?: SizesEnum;
}

function RadioButtonComponent({
  label,
  radioSize = SizesEnum.Small,
  labelSize = SizesEnum.Large,
  color = ColorsEnum.Primary,
  ...props
}: RadioButtonComponentProps) {
  const { t } = useTranslation();
  const { mode } = useModeStore();

  const getSizes = () => {
    switch (radioSize) {
      case SizesEnum.Small:
        return 'h-5 w-5 before:border-4';
      case SizesEnum.Medium:
        return 'h-6 w-6 before:border-[5px]';
      case SizesEnum.Large:
        return 'h-7 w-7 before:border-[6px]';
      default:
        return 'h-6 w-6 before:border-[5px]';
    }
  };

  const getColor = () => {
    switch (color) {
      case ColorsEnum.Primary:
        return 'before:border-white border-slate-500/50 checked:border-primary checked:bg-primary';
      case ColorsEnum.Secondary:
        return 'before:border-white border-slate-500/50 checked:border-secondary checked:bg-secondary';
      default:
        return 'before:border-white border-slate-500/50 checked:border-primary checked:bg-primary';
    }
  };

  const getSizesText = () => {
    switch (labelSize) {
      case SizesEnum.Small:
        return 'text-xs';
      case SizesEnum.Medium:
        return 'text-sm';
      case SizesEnum.Large:
        return 'text-base';
      default:
        return 'text-sm';
    }
  };

  return (
    <div dir={props.dir}>
      <label
        className={`inline-flex items-center text-center gap-2 ${
          mode === 'dark' ? 'text-gray-500' : 'text-neutral-500'
        } ${getColor()} ${getSizesText()}`}
      >
        <input
          {...props}
          type="radio"
          className={`appearance-none grid place-items-center rounded-full border-[1px] before:rounded-full before:scale-0 before:ease-in-out before:transition-transform checked:before:scale-[1px] ${getSizes()} ${getColor()}`}
        />
        {t(`${label}`)}
      </label>
    </div>
  );
}

export default RadioButtonComponent;
