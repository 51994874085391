import { CVType } from 'src/core/types/CV.type';

export const cvInitialValues: CVType = {
  id: '',
  marital: undefined,
  gender: undefined,
  id_number: '',
  first_name: '',
  last_name: '',
  nationality: '',
  date_of_birthday: '',
  image: '',
  job_title: '',
  specialization: '',
  company_sector: '',
  employment_type: '',
  last_salary: '',
  target_salary: '',
  level: '',
  notice_period: '',
  email: '',
  mobile: '',
  country: '',
  experience: [
    {
      name: '',
      industry: '',
      location: '',
      start_date: '',
      end_date: '',
      description: '',
      no_expired_date: false,
    },
  ],
  education: [
    {
      institution: '',
      country: '',
      city: '',
      gpa_list: '',
      grade: '',
      major: '',
      degree: '',
      completion_date: '',
    },
  ],
  language: [
    {
      language: '',
      proficiency: '',
    },
  ],
  training: [
    {
      name: '',
      institute: '',
    },
  ],
  certificate: [
    {
      name: '',
      issued_in: '',
      valid_until: '',
      no_expired_date: false,
    },
  ],
};
