import { useTranslation } from 'react-i18next';
interface titleComponent {
  title?: string;
  color?: string;
}

function TitleComponent({ title, color }: titleComponent) {
  const { t, i18n } = useTranslation();

  return (
    <div className="pb-3.5 w-fit">
      <h1
        className={` ${color || 'text-white'}
         ${
           i18n.language === 'en'
             ? ' font-helveticaBold text-base laptop:text-sm'
             : ' font-arMyriadBold text-xl laptop:text-lg'
         }`}
      >
        {t(`${title}`)}
      </h1>
      <div className="w-[41px] h-1 bg-primary rounded"></div>
      {/* <div
        className={`h-1 ${i18n.language === "en" ? "mr-4" : "ml-4"
          } bg-primary rounded`}
      ></div> */}
    </div>
  );
}

export default TitleComponent;
