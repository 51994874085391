import { Form, Formik } from 'formik';
import GridView from 'src/components/common/ui/grid-view';
import InputField from 'src/components/common/ui/InputField';
import SaveCancelButton from 'src/components/user/V2/save-cancel-button';
import { handleRequestError } from 'src/core/helpers/error.helper';
import useProfileStore from 'src/core/stores/setUserData';
import { TrainingType } from 'src/core/types/CV.type';
import { getCVService } from 'src/services/CV.service';
import { putCVService } from 'src/services/CV.service';

import { cvInitialValues } from '../initialValues';
import { useTrainingData } from './useTrainingData';

export default function TrainingForm({
  setIsTraining,
  selectedId,
}: {
  setIsTraining: (value: boolean) => void;
  selectedId?: string;
}) {
  const { data, setUserData } = useProfileStore();
  const { trainingData } = useTrainingData(selectedId);

  const handleSubmit = async (values: TrainingType) => {
    try {
      if (selectedId) {
        const original = data.training?.find((training) => training.id === selectedId);
        const isUnchanged =
          original && original.name === values.name && original.institute === values.institute;

        if (isUnchanged) {
          setIsTraining(false);
          return;
        }
      }

      const updatedTraining = selectedId
        ? data.training?.map((train) =>
            train.id === selectedId ? { ...train, ...values } : train
          ) || []
        : [...(data?.training || []), { ...values }];

      await putCVService({
        ...data,
        training: updatedTraining,
      });

      // Get fresh data to ensure we have the complete updated CV
      const updatedCV = await getCVService();
      setUserData(updatedCV);
      handleRequestError.success('container.CV.confirmation.saved');
      setIsTraining(false);
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <Formik
      initialValues={trainingData || (cvInitialValues.training?.[0] as Partial<TrainingType>)}
      enableReinitialize
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, errors, touched }) => (
        <Form className="flex flex-col gap-y-4 px-7 mobile:px-0 h-[50vh] py-5 justify-between">
          <GridView column="1">
            <InputField
              label="container.trainingForm.trainingInstitute"
              name="institute"
              isValid={touched.institute && !errors.institute}
              value={values.institute}
              onChange={handleChange}
              error={!!errors.institute}
              errorMsg={errors.institute}
            />
            <InputField
              label="container.trainingForm.trainingName"
              name="name"
              value={values.name}
              onChange={handleChange}
              isValid={touched.name && !errors.name}
              error={!!errors.name}
              errorMsg={errors.name}
            />
          </GridView>
          <SaveCancelButton
            onCancel={() => {
              setIsTraining(false);
            }}
          />
        </Form>
      )}
    </Formik>
  );
}
