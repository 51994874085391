import i18n from 'src/core/configs/i18n';
import { baseURL, makeRequest } from 'src/core/data-access/http.service';
import { HttpMethods } from 'src/core/enums/httpMethods.enum';
import { NewsTypesEnum } from 'src/core/enums/service.enums';

export const getNewsListService = async (
  lang: string,
  type: NewsTypesEnum,
  offset: number,
  limit: number
) => {
  return await makeRequest({
    url: `${baseURL}/news`,
    method: HttpMethods.GET,
    params: {
      lang: lang,
      news_type: type,
      offset: offset,
      limit: limit,
    },
  });
};

export const getNewsByIdService = async (newsId: string, lang: string) => {
  return await makeRequest({
    url: `${baseURL}/news/${newsId}`,
    method: HttpMethods.GET,
    params: { lang: lang },
  });
};

export const fetchNewsListService = async (type: NewsTypesEnum) => {
  return await makeRequest({
    url: `${baseURL}/news`,
    method: HttpMethods.GET,
    params: { news_type: type, lang: i18n.language.toUpperCase() },
  });
};
