import { onlyLetters_Ar_En, onlyNumbers_En } from 'src/core/statics/regex';
import * as Yup from 'yup';

export const educationSchema = Yup.object().shape({
  institution: Yup.string()
    .matches(onlyLetters_Ar_En, 'Institution name cannot contain special characters.')
    .nullable(),
  country: Yup.string().nullable(),
  city: Yup.string().nullable(),
  gpa_list: Yup.string().nullable(),
  gpa: Yup.string().matches(onlyNumbers_En).nullable(),
  major: Yup.string()
    .matches(onlyLetters_Ar_En, 'Specialization cannot contain special characters.')
    .nullable(),
  degree: Yup.string().nullable(),
  completion_date: Yup.date()
    .test('future-date', 'Date of birth cannot be in the future', (value) => {
      if (!value) return true;
      return new Date(value) <= new Date();
    })
    .nullable(),
});

export const trainingSchema = Yup.object().shape({
  trainingName: Yup.string().nullable(),
  trainingInstitute: Yup.string().nullable(),
});

export const certificateSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .matches(onlyLetters_Ar_En, 'Certificate name cannot contain special characters.'),
  issued_in: Yup.date()
    .nullable()
    .test(
      'valid-issue-date',
      'Please select a valid issue date',
      (value) => !value || value instanceof Date
    ),
  valid_until: Yup.date()
    .nullable()
    .test(
      'valid-expiry-date',
      'Please select a valid expiration date',
      (value) => !value || value instanceof Date
    ),
  no_expired_date: Yup.boolean().default(false),
});
