import React from 'react';
import { Layout } from 'src/components/common/layout-component/layout-component';
import LoginContainer from 'src/containers/user/login-container/login-container';

function LoginPage() {
  return (
    <Layout sidebar={false}>
      <LoginContainer />
    </Layout>
  );
}

export default LoginPage;
