import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheck2Circle } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import useModeStore from 'src/core/helpers/useModeStore';
interface popupProps {
  onClose?: () => void;
  body?: string;
}
export default function Popup(props: popupProps) {
  const { mode } = useModeStore();

  const { t } = useTranslation();
  function refreshPage() {
    window.location.reload();
  }
  return (
    <div
      className={`relative p-4 w-fit ${
        mode === 'dark' ? 'bg-neutral-800 border-neutral/50' : 'bg-neutral-300 border-white/50'
      }  backdrop-blur-xl border border-neutral/50 h-auto py-5 rounded-2xl shadow-2xl`}
    >
      <div className="flex items-end justify-end">
        <IoMdClose
          size={24}
          color={`${mode === 'dark' ? 'white' : ''}`}
          className="cursor-pointer"
          onClick={() => {
            props.onClose;
            refreshPage();
          }}
        />
      </div>
      <div className="flex w-full flex-col justify-center items-center gap-6 text-center">
        <div>
          <BsCheck2Circle size={61} color="#50C5B1" />
        </div>
        <div
          className={`font-helveticaMedium text-base w-96 mobile:w-80    ${
            mode === 'dark' ? 'text-white ' : 'text-slate-900'
          }`}
        >
          {t(`${props.body}`)}
        </div>
      </div>
    </div>
  );
}
