import { BsTwitterX } from 'react-icons/bs';
import { CiFacebook } from 'react-icons/ci';
import { FaInstagram } from 'react-icons/fa';
function Footer() {
  return (
    <div className="flex flex-row justify-between pb-4 pt-2 largeLaptop:pt-2.5 mobile:py-1 mobile:pt-5 tablet:pt-5 mobile:gap-3 tablet:gap-3 mobile:flex-col tablet:flex-col">
      <div className="flex flex-wrap justify-start items-center mobile:justify-center tablet:justify-center mobile:items-start tablet:items-start gap-6 mobile:gap-2">
        <img
          onClick={() => window.open('https://safcsp.org.sa/', '_blank')}
          className="w-[9%] tablet:w-[15%] mobile:w-[25%] cursor-pointer"
          src="/assets/images/Mask group.png"
          alt="logo"
        />
        <img
          onClick={() => window.open('https://www.mcit.gov.sa', '_blank')}
          className="w-[9%] tablet:w-[15%] mobile:w-[25%] cursor-pointer"
          src="/assets/images/Mask group (3).png"
          alt="logo"
        />
        <img
          onClick={() => window.open('https://kacst.gov.sa', '_blank')}
          className="w-[10%] tablet:w-[15%] mobile:w-[25%] cursor-pointer"
          src="/assets/images/kacst.png"
          alt="logo"
        />

        {/* Add more images here */}
      </div>
      <div className="flex items-center justify-end mobile:justify-center tablet:justify-center gap-2">
        <CiFacebook
          onClick={() => window.open('https://www.facebook.com/TheGarageKSA', '_blank')}
          className="text-white cursor-pointer"
          size={20}
        />
        <BsTwitterX
          onClick={() => window.open('https://twitter.com/TheGarageKSA', '_blank')}
          className="text-white cursor-pointer"
          size={16}
        />
        <FaInstagram
          onClick={() => window.open('https://www.instagram.com/thegarageksa/', '_blank')}
          className="text-white cursor-pointer"
          size={16}
        />
      </div>
    </div>
  );
}
export default Footer;
