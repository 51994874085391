import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import SharedSectionComponent from 'src/components/shard-Section';
import useModeStore from 'src/core/helpers/useModeStore';
import { InputList } from 'src/core/statics/inputList';

import NewsSectionComponent from './news-section-component';

function SidebarComponent() {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === 'dark';

  const hasCountUpRun = sessionStorage.getItem('hasCountUpRun') === 'true';

  window.addEventListener('beforeunload', () => {
    sessionStorage.removeItem('hasCountUpRun');
  });

  return (
    <div className="mobile:py-0 tablet:pb-8 flex w-[350px] mobile:overflow-hidden tablet:overflow-hidden mobile:pb-8 h-full largeLaptop:w-[300px] laptop:w-[290px] mobile:w-full tablet:w-full">
      <div
        className={`px-4 mobile:px-0 tablet:px-0 py-4  mobile:py-0 tablet:py-0 flex flex-col  tablet:w-full mobile:border-none border mobile:rounded-none tablet:border-none tablet:rounded-none rounded-2xl
          ${isDarkMode ? 'border-gray-600/50' : 'border-neutral/50'}`}
      >
        <SharedSectionComponent
          component={
            <div className="">
              <p
                className={` text-neutral-400 line-clamp-3 leading-5 text-justify laptop:line-clamp-2 ${
                  i18n.language === 'ar'
                    ? 'font-arMyriad text-lg'
                    : 'font-helveticaMedium text-sm laptop:text-base'
                }`}
              >
                {t('component.user.SidebarComponent.theGarageDescreption')}
              </p>

              <Link to={appRoutesObj.getAboutThegaragePagePath()}>
                <p
                  className={`text-sm laptop:text-xs text-primary underline  laptop:mb-4 largeLaptop:mb-4 largeLaptop:mt-3  ${
                    isDarkMode ? ' hover:text-primary-600' : ' hover:text-primary-50'
                  } ${
                    i18n.language === 'ar'
                      ? 'text-left font-arMyriad mb-6'
                      : 'text-right font-helvetica font-light mb-8'
                  }`}
                >
                  {t('component.user.SidebarComponent.theGarageExplore')}
                </p>
              </Link>
              <div className="grid grid-cols-2 gap-3 font-HelveticaCEMedium">
                {InputList.map((val, index) => (
                  <div
                    key={index}
                    className={`rounded-2xl h-26 py-1 laptop:h-14 largeLaptop:h-14 border bg-neutral bg-opacity-10 mobile:py-3 mobile:h-[110%] ${
                      isDarkMode ? 'border-gray-600/50' : 'border-neutral/50'
                    } `}
                  >
                    <div className="flex flex-col justify-center items-center gap-y-1">
                      <p className="text-lg text-center laptop:text-sm text-secondary font-bold">
                        <CountUp
                          className="text-lg text-center text-secondary font-bold"
                          end={typeof val.Number === 'string' ? parseFloat(val.Number) : val.Number}
                          duration={hasCountUpRun ? 0 : 2}
                          useGrouping={false}
                          onEnd={() => {
                            sessionStorage.setItem('hasCountUpRun', 'true');
                          }}
                        />
                        {i18n.language === 'en'
                          ? ' ' + val.subtext + ' +'
                          : '' + val.subtext + ' +'}
                      </p>
                      <p
                        className={`text-center text-white laptop:text-center ${
                          i18n.language === 'ar'
                            ? 'font-arMyriad text-base  laptop:text-sm'
                            : 'font-helveticaMedium text-sm laptop:text-xs'
                        }`}
                      >
                        {t(`${val.label}`)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
          title="app.shared.aboutTheGarage"
        />
        <div className="mobile:hidden tablet:hidden h-full pt-6 pb-2 largeLaptop:pb-0 largeLaptop:pt-6 laptop:pt-2 laptop:pb-0">
          <NewsSectionComponent />
        </div>
      </div>
    </div>
  );
}

export default SidebarComponent;
