import React from 'react';
import Footer from 'src/components/common/Footer-component/footer-component';
import NewsSectionComponent from 'src/components/common/Sidebar-component/news-section-component';
import SidebarComponent from 'src/components/common/Sidebar-component/sidebar-component';

export default function MobileThegaragePage() {
  return (
    <div className="overflow-visible">
      <SidebarComponent />
      <NewsSectionComponent />
      <Footer />
    </div>
  );
}
