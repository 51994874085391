import { create } from 'zustand';

import { CVType } from '../types/CV.type';

export type ProfileStoreModel = {
  data: CVType;
  setUserData: (data: CVType) => void;
};
const useProfileStore = create<ProfileStoreModel>((set) => ({
  data: {} as CVType,
  setUserData: (data: CVType) => {
    set((state) => ({
      ...state,
      data: data,
    }));
  },
}));
export default useProfileStore;
