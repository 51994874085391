export enum DirectionEnum {
  LTR = 'ltr',
  RTL = 'rtl',
}
export enum SizesEnum {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

export enum VariantsEnum {
  Filled = 'filled',
  Outline = 'outline',
}

export enum ColorsEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
  Success = 'success',
  Warning = 'warning',
  Accent = 'accent',
  Muted = 'muted',
  White = 'white',
}
