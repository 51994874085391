import { useState } from 'react';
import EmptyStateCardComponent from 'src/components/user/V2/empty-state-card';
import useProfileStore from 'src/core/stores/setUserData';

import EducationView from './Education-view';
import EducationForm from './EducationForm';

export default function EducationContainer() {
  const { data } = useProfileStore();
  const [isEducation, setIsEducation] = useState(false);
  const [selectedId, setSelectedId] = useState<string>();
  const educationData = [...(data.education || [])];

  const handleEdit = (id: string) => {
    setSelectedId(id);
    setIsEducation(true);
  };

  const handleAdd = () => {
    setSelectedId(undefined);
    setIsEducation(true);
  };

  return (
    <div>
      {educationData.length === 0 && !isEducation ? (
        <EmptyStateCardComponent
          title="container.EducationContainer.noEducation"
          buttonLabel="container.EducationContainer.addEducation"
          buttonOnClick={() => {
            setIsEducation(true);
            setSelectedId(undefined);
          }}
        />
      ) : isEducation ? (
        <EducationForm setIsEducation={setIsEducation} selectedId={selectedId} />
      ) : (
        <EducationView onEdit={handleEdit} onAdd={handleAdd} />
      )}
    </div>
  );
}
