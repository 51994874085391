import { useTranslation } from 'react-i18next';
import { BiCalendar, BiTimeFive } from 'react-icons/bi';
import { GrLocation } from 'react-icons/gr';

import CardBorderGreenComponent from './card-border-green-component';

interface EventDetailsProps {
  imgSrc?: string;
  imgAlt?: string;
  dateKey: string;
  timeKey: string;
  locationKey: string;
}

export default function EventDetails({
  imgSrc,
  imgAlt = '',
  dateKey,
  timeKey,
  locationKey,
}: EventDetailsProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center">
      {imgSrc && (
        <img
          className="w-auto h-20 justify-center flex items-center mb-6"
          src={imgSrc}
          alt={imgAlt}
        />
      )}
      <div className="flex items-center justify-center gap-x-6 mobile:justify-between mobile:gap-x-4">
        <CardBorderGreenComponent title={t(dateKey)} icon={BiCalendar} />
        <CardBorderGreenComponent title={t(timeKey)} icon={BiTimeFive} />
        <CardBorderGreenComponent title={t(locationKey)} icon={GrLocation} />
      </div>
    </div>
  );
}
