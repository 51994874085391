import { useCallback, useEffect, useState } from 'react';
import { isExpired } from 'react-jwt';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from 'src/components/common/Loading/loading';
import { refreshTokenService } from 'src/core/data-access/token.service';

import { appRoutesObj } from '../../../app.paths';

interface TokenValidationWrapperContainerProps {
  children: JSX.Element;
}

const RefreshTokenUpdateWrapperContainer = ({ children }: TokenValidationWrapperContainerProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const resfreshToken = useCallback(async () => {
    setIsRefreshing(true);
    try {
      const refreshToken = localStorage.getItem('refreshToken') as string;
      const res = await refreshTokenService(refreshToken);
      localStorage.setItem('token', res.access_token);
      localStorage.setItem('refreshToken', res.refresh_token);
    } catch (error) {
      localStorage.clear();
      navigate(appRoutesObj.getLoginPagePath());
    } finally {
      setIsRefreshing(false);
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token') as string;

    if (isExpired(token)) {
      setIsRefreshing(true);
      resfreshToken();
    }
  }, [location, resfreshToken]);

  if (isRefreshing) {
    return <Loading />;
  }

  return children;
};

export default RefreshTokenUpdateWrapperContainer;
