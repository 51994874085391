import { Button } from 'src/components/common/ui/Button';
import GridView from 'src/components/common/ui/grid-view';
import CVCardComponent from 'src/components/user/V2/CV-card-component';
import { VariantsEnum, SizesEnum } from 'src/core/enums/ui.enums';
import { handleRequestError } from 'src/core/helpers/error.helper';
import useProfileStore from 'src/core/stores/setUserData';
import { getCVService, putCVService } from 'src/services/CV.service';

interface WorkExperienceListContainerProps {
  onEdit: (id: string) => void;
  onAdd: () => void;
}

function WorkExperienceListContainer({ onEdit, onAdd }: WorkExperienceListContainerProps) {
  const { data, setUserData } = useProfileStore();

  const handleEdit = (id: string) => {
    onEdit(id);
  };

  const handleDelete = async (id: string) => {
    try {
      const updatedData = data?.experience?.filter((item) => item.id !== id);
      await putCVService({
        ...data,
        experience: updatedData,
      });
      const response = await getCVService();
      setUserData(response);
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <div className="flex flex-col gap-4 h-full w-full">
      <div className="grid order-first justify-end items-start w-full mobile:flex tablet:flex">
        <Button variant={VariantsEnum.Filled} size={SizesEnum.Small} onClick={() => onAdd()}>
          Add experience
        </Button>
      </div>
      <GridView column="2">
        {data?.experience?.map((item) => (
          <CVCardComponent
            key={item.id}
            title={item.name || ''}
            title1={item.industry || ''}
            onEdit={() => handleEdit(item.id || '')}
            onDelete={() => handleDelete(item.id || '')}
          />
        ))}
      </GridView>
    </div>
  );
}

export default WorkExperienceListContainer;
