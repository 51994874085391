import ContactInfoContainer from 'src/containers/user/CV/ContactInformation/contact-info-container';
import GeneralInfoContainer from 'src/containers/user/CV/GeneralInformationcontainer/general-info-container';
import TargetJobContainer from 'src/containers/user/CV/TargetJob/target-job-container';
import WorkExperienceContainer from 'src/containers/user/CV/WorkExperience/work-experience-container';
import EducationPage from 'src/pages/user/CV/Education/education-page';
import LanguagePage from 'src/pages/user/CV/language/LanguagePage';
import TrainingCertificationPage from 'src/pages/user/CV/training & certification/training-certification-page';
import ProfilePage from 'src/pages/user/profile/profile-page';

export interface CvInfo {
  name: string;
  isSubSection?: boolean;
  Sections: Section[];
}
interface Section {
  section: string;
  content: React.ReactNode;
}
export const CvInfo: CvInfo[] = [
  {
    name: 'navbar.profile',
    isSubSection: false,
    Sections: [
      {
        section: '',
        content: <ProfilePage />,
      },
    ],
  },
  {
    name: 'container.CVInfoContainer.myCV',
    isSubSection: true,
    Sections: [
      {
        section: 'container.CVInfoContainer.generalInformation',
        content: <GeneralInfoContainer />,
      },
      {
        section: 'container.CVInfoContainer.targetJob',
        content: <TargetJobContainer />,
      },
      {
        section: 'container.CVInfoContainer.contactInformation',
        content: <ContactInfoContainer />,
      },
      {
        section: 'container.CVInfoContainer.workExperience',
        content: <WorkExperienceContainer />,
      },
      {
        section: 'container.CVInfoContainer.education',
        content: <EducationPage />,
      },
      {
        section: 'container.CVInfoContainer.language',
        content: <LanguagePage />,
      },
      {
        section: 'container.CVInfoContainer.trainingCertifications',
        content: <TrainingCertificationPage />,
      },
    ],
  },
];
