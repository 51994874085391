import { baseURL, makeRequest } from 'src/core/data-access/http.service';
import { HttpMethods } from 'src/core/enums/httpMethods.enum';

export const getProgramsVersionsService = async (lang: string, type: string) => {
  return await makeRequest({
    url: `${baseURL}/programs-versions`,
    method: HttpMethods.GET,
    params: {
      lang: lang,
      type: type,
    },
  });
};
