import axios from 'axios';
import { baseURL, makeRequest } from 'src/core/data-access/http.service';
import { HttpMethods } from 'src/core/enums/httpMethods.enum';

export enum FileTypeEnum {
  startup_profile = 'startup_profile',
  issue = 'issue',
  image = 'image',
}

export enum SponsorType {
  Mobile = 'mobile',
  Ipad = 'ipad',
  Desktop = 'desktop',
}

export type UploadImage = {
  id: string;
  file_path: string;
  upload_url: string;
  image_size: SponsorType;
};

export enum SupportedFileType {
  PDF = 'pdf',
}

export const uploadImage = async (uploadType: FileTypeEnum, extension: string, file: File) => {
  const req = {
    url: baseURL + '/upload',
    method: HttpMethods.POST,
    params: {
      file_type: uploadType,
      file_extension: extension,
    },
    data: {
      original_name: file.name,
    },
    skipHeader: true,
  };

  const data = (await makeRequest(req)) as UploadImage;

  return uploadImageToServer(data, file);
};

const uploadImageToServer = async (data: UploadImage, file: File, signal?: AbortSignal) => {
  const request = {
    url: data.upload_url,
    method: HttpMethods.PUT,
    data: file,
    signal,
  };
  await axios(request);
  return data.file_path;
};
