import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Layout } from 'src/components/common/layout-component/layout-component';
import SignUpFormContainer from 'src/containers/user/login-container/sign-up-container/sign-up-form-container';

function SignUpPage() {
  return (
    <Layout sidebar={false}>
      <div className="flex flex-col justify-center items-center mx-auto mobile:py-12">
        <div className="h-full w-[594px] rounded-2xl border-[1.8px] border-gray-600 border-opacity-50 backdrop-blur-none bg-opacity-10 bg-neutral-800 border-neutral/50 mobile:h-auto mobile:w-auto">
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}>
            <SignUpFormContainer />
          </GoogleReCaptchaProvider>
        </div>
      </div>
    </Layout>
  );
}

export default SignUpPage;
