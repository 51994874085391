export const ImagesPartners = [
  {
    name: 'KAUST1',
    imgSrc: '/assets/images/partners/kaust.png',
    imagSrcLight: '/assets/images/partners/kaust-light.png',
    link: 'https://destinationdeeptech.kaust.edu.sa',
  },
  {
    name: 'google_for_startup',
    imgSrc: '/assets/images/partners/google_for_startup.png',
    imagSrcLight: '/assets/images/partners/google_for_startup-light.png',
    link: 'https://startup.google.com',
  },
  {
    name: 'NTDP',
    imgSrc: '/assets/images/partners/ntdp.png',
    imagSrcLight: '/assets/images/partners/ntdp-light.png',
    link: 'https://ntdp.gov.sa/en',
  },
  {
    name: 'ministry_investment',
    imgSrc: '/assets/images/partners/ministry_investment.png',
    imagSrcLight: '/assets/images/partners/ministry_investment-light.png',
    link: 'https://misa.gov.sa/ar/',
  },
];
