import { Layout } from 'src/components/common/layout-component/layout-component';
import ForgotPasswordContainer from 'src/containers/user/forgot-password-container/forgot-password-container';

function ForgotPasswordPage() {
  return (
    <Layout sidebar={false}>
      <ForgotPasswordContainer />
    </Layout>
  );
}

export default ForgotPasswordPage;
