import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { DirectionEnum } from 'src/core/enums/ui.enums';
import useModeStore from 'src/core/helpers/useModeStore';

export type optionsList = {
  value: string;
  key: string;
};

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  selectLabel: string;
  errorMsg?: string;
  value?: string | number;
  disabled?: boolean;
  options: optionsList[];
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export default function SelectComponent2({
  name,
  value = '',
  selectLabel,
  disabled,
  errorMsg = '',
  options,
  onChange,
  ...props
}: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === 'dark';

  const selectedOption = options.find((opt) => opt.key === value) || { value: '', key: '' };
  const isRTL = i18n.language !== 'en';
  const hasError = errorMsg !== '';

  const handleOptionSelect = (option: optionsList) => {
    setIsOpen(false);
    onChange({
      target: { name, value: option.key },
    } as ChangeEvent<HTMLSelectElement>);
  };

  return (
    <div className="relative w-full custom-dropdown">
      <div
        className={`absolute top-4 text-gray-400 pointer-events-none ${
          isRTL ? 'left-4' : 'right-4'
        }`}
      >
        {isOpen ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
      </div>
      <input
        type="text"
        name={name}
        disabled={disabled}
        value={selectedOption ? String(t(selectedOption.value)) : ''}
        readOnly
        onClick={() => !disabled && setIsOpen(!isOpen)}
        className={`block relative capitalize cursor-pointer text-base h-14 mobile:h-12 rounded-lg px-2.5 pt-5 w-full text-white bg-neutral-500/10 
          ${isRTL ? 'font-arMyriad' : 'font-helveticaMedium'}
          ${hasError ? 'border border-red-500 focus:border-red-500 focus:border-2' : 'border-neutral-500/10'}
          focus:border focus:border-neutral-200 focus:outline-none focus:ring-0`}
        placeholder={props.defaultValue?.toString() || t('app.shared.select') || ''}
      />
      {isOpen && (
        <div
          className={`absolute top-[63px] mobile:top-[50px] z-50 ${
            isDarkMode ? 'bg-stone-900' : 'bg-gray-600'
          } rounded w-full h-fit py-2 px-4`}
        >
          {options.map((option) => (
            <div
              key={option.key}
              className={`flex capitalize hover:text-secondary text-white h-9 items-center cursor-pointer 
                ${isRTL ? 'font-arMyriad' : 'font-helveticaMedium'}`}
              onClick={() => handleOptionSelect(option)}
            >
              {t(option.value)}
            </div>
          ))}
        </div>
      )}
      <label
        className={`absolute ${
          isRTL ? 'font-arMyriad' : 'font-helveticaMedium'
        } text-gray-500 text-base peer-focus:translate-x-1 duration-300 transform -translate-y-4 scale-70 top-4 z-10 origin-[0] start-2.5  ${
          props.dir === DirectionEnum.LTR ? 'left-0' : 'right-0 '
        }`}
      >
        {t(selectLabel)}
      </label>
      <div
        className={`flex justify-between px-2 py-1 text-xs ${
          isRTL ? 'font-arMyriad' : 'font-helveticaMedium'
        } text-gray-900`}
      >
        {hasError && <p className="text-red-500">{t(errorMsg)}</p>}
      </div>
    </div>
  );
}
