import { jwtDecode } from 'jwt-decode';
import { useState, useEffect } from 'react';

type UserType = {
  name: string;
  preferred_username: string;
  email: string;
};

// This is to get the user data from the token directly
export const useUser = () => {
  const token = localStorage.getItem('token');
  const [user, setUser] = useState<UserType>();

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setUser(decodedToken as UserType);
    }
  }, [token]);

  const userEmail = user?.email || '';

  const userUsername = user?.preferred_username || '';

  return { userEmail, userUsername };
};

export default useUser;
