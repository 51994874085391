export enum MaritalStatusEnum {
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE',
}

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}
export enum EmploymentTypeEnum {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  CONTRACT = 'CONTRACT',
  TRAINING = 'TRAINING',
}

export enum NoticePeriodEnum {
  IMMEDIATELY = 'Immediately',
  ONE_MONTH_OR_LESS = '1 Month or Less',
  THREE_MONTHS_OR_LESS = '3 Months or Less',
  SIX_MONTHS_OR_LESS = '6 Months or Less',
  MORE_THAN_SIX_MONTHS = 'More than 6 Months',
  MORE_THAN_ONE_YEAR = 'More than 1 Year',
}

export enum NoticePeriodArEnum {
  IMMEDIATELY = 'فورا',
  ONE_MONTH_OR_LESS = 'شهر أو أقل',
  THREE_MONTHS_OR_LESS = '3 أشهر أو أقل',
  SIX_MONTHS_OR_LESS = '6 أشهر أو أقل',
  MORE_THAN_SIX_MONTHS = 'أكثر من 6 شهور',
  MORE_THAN_ONE_YEAR = 'أكثر من سنة',
}

export enum CareerLevelEnum {
  STUDENT_INTERNSHIP = 'StudentInternship',
  ENTRY_LEVEL = 'EntryLevel',
  MID_CAREER = 'MidCareer',
  MANAGEMENT = 'Management',
  DIRECTOR_HEAD = 'DirectorHead',
  SENIOR_EXECUTIVE = 'SeniorExecutive',
}

export enum Degree {
  HIGH_SCHOOL = 'High School or Equivalent',
  DIPLOMA = 'Diploma',
  BACHELORS = "Bachelor's Degree",
  HIGHER_DIPLOMA = 'Higher Diploma',
  MASTERS = "Master's Degree",
  DOCTORATE = 'Doctorate',
}

export enum DegreeAr {
  HIGH_SCHOOL = 'الثانوية العامة أو ما يعادلها',
  DIPLOMA = 'دبلوم',
  BACHELORS = 'بكالوريوس',
  HIGHER_DIPLOMA = 'دبلوم عالي',
  MASTERS = 'ماجستير',
  DOCTORATE = 'دكتوراة',
}

export enum Proficiency {
  BEGINNER = 'Beginner',
  INTERMEDIATE = 'Intermediate',
  EXPERT = 'Expert',
  NATIVE = 'Native',
}

export enum ProficiencyAr {
  BEGINNER = 'مبتدئ',
  INTERMEDIATE = 'متوسط',
  EXPERT = 'متمرس',
  NATIVE = 'اللغة الأم',
}

export enum GradeScale {
  FOUR_POINT = '4point GBA',
  FIVE_POINT = '5point GBA',
  TWENTY_POINT = '20point GBA',
  PERCENTAGE = ' Percentage (out of 100)',
}

export enum Language {
  ARABIC = 'Arabic',
  ENGLISH = 'English',
  SPANISH = 'Spanish',
  FRENCH = 'French',
  GERMAN = 'German',
  ITALIAN = 'Italian',
  PORTUGUESE = 'Portuguese',
  RUSSIAN = 'Russian',
  CHINESE = 'Chinese',
  JAPANESE = 'Japanese',
  KOREAN = 'Korean',
  HINDI = 'Hindi',
  URDU = 'Urdu',
  BENGALI = 'Bengali',
  PERSIAN = 'Persian (Farsi)',
  THAI = 'Thai',
  TURKISH = 'Turkish',
  INDONESIAN = 'Indonesian',
  VIETNAMESE = 'Vietnamese',
  SWAHILI = 'Swahili',
  DUTCH = 'Dutch',
  GREEK = 'Greek',
  HEBREW = 'Hebrew',
  MALAY = 'Malay',
  FILIPINO = 'Filipino (Tagalog)',
  CZECH = 'Czech',
  HUNGARIAN = 'Hungarian',
  ROMANIAN = 'Romanian',
  SWEDISH = 'Swedish',
  NORWEGIAN = 'Norwegian',
  DANISH = 'Danish',
  FINNISH = 'Finnish',
  SERBIAN = 'Serbian',
  BULGARIAN = 'Bulgarian',
  UKRAINIAN = 'Ukrainian',
  SLOVAK = 'Slovak',
  SLOVENIAN = 'Slovenian',
  LITHUANIAN = 'Lithuanian',
  LATVIAN = 'Latvian',
  ESTONIAN = 'Estonian',
  CATALAN = 'Catalan',
  BASQUE = 'Basque',
  GALICIAN = 'Galician',
  ARMENIAN = 'Armenian',
  AZERBAIJANI = 'Azerbaijani',
  KAZAKH = 'Kazakh',
  KYRGYZ = 'Kyrgyz',
  TAJIK = 'Tajik',
  UZBEK = 'Uzbek',
  PASHTO = 'Pashto',
}

export enum NationalityEnum {
  AFGHAN = 'Afghan',
  ALBANIAN = 'Albanian',
  ALGERIAN = 'Algerian',
  AMERICAN = 'American',
  ANDORRAN = 'Andorran',
  ANGOLAN = 'Angolan',
  ANTIGUAN = 'Antiguan',
  ARGENTINE = 'Argentine',
  ARMENIAN = 'Armenian',
  AUSTRALIAN = 'Australian',
  AUSTRIAN = 'Austrian',
  AZERBAIJANI = 'Azerbaijani',
  BAHAMIAN = 'Bahamian',
  BAHRAINI = 'Bahraini',
  BANGLADESHI = 'Bangladeshi',
  BARBADIAN = 'Barbadian',
  BELARUSIAN = 'Belarusian',
  BELGIAN = 'Belgian',
  BELIZEAN = 'Belizean',
  BENINESE = 'Beninese',
  BHUTANESE = 'Bhutanese',
  BOLIVIAN = 'Bolivian',
  BOSNIAN = 'Bosnian',
  BRAZILIAN = 'Brazilian',
  BRITISH = 'British',
  BRUNEIAN = 'Bruneian',
  BULGARIAN = 'Bulgarian',
  BURKINABE = 'Burkinabe',
  BURUNDIAN = 'Burundian',
  CAMBODIAN = 'Cambodian',
  CAMEROONIAN = 'Cameroonian',
  CANADIAN = 'Canadian',
  CAPE_VERDEAN = 'Cape Verdean',
  CENTRAL_AFRICAN = 'Central African',
  CHADIAN = 'Chadian',
  CHILEAN = 'Chilean',
  CHINESE = 'Chinese',
  COLOMBIAN = 'Colombian',
  COMORIAN = 'Comorian',
  CONGOLESE = 'Congolese',
  COSTA_RICAN = 'Costa Rican',
  CROATIAN = 'Croatian',
  CUBAN = 'Cuban',
  CYPRIOT = 'Cypriot',
  CZECH = 'Czech',
  DANISH = 'Danish',
  DOMINICAN = 'Dominican',
  DUTCH = 'Dutch',
  EAST_TIMORESE = 'East Timorese',
  ECUADORIAN = 'Ecuadorian',
  EGYPTIAN = 'Egyptian',
  EMIRATI = 'Emirati',
  EQUATORIAL_GUINEAN = 'Equatorial Guinean',
  ERITREAN = 'Eritrean',
  ESTONIAN = 'Estonian',
  ESWATINI = 'Eswatini',
  ETHIOPIAN = 'Ethiopian',
  FIJIAN = 'Fijian',
  FILIPINO = 'Filipino',
  FINNISH = 'Finnish',
  FRENCH = 'French',
  GABONESE = 'Gabonese',
  GAMBIAN = 'Gambian',
  GEORGIAN = 'Georgian',
  GERMAN = 'German',
  GHANAIAN = 'Ghanaian',
  GREEK = 'Greek',
  GRENADIAN = 'Grenadian',
  GUATEMALAN = 'Guatemalan',
  GUINEAN = 'Guinean',
  GUYANESE = 'Guyanese',
  HAITIAN = 'Haitian',
  HONDURAN = 'Honduran',
  HUNGARIAN = 'Hungarian',
  ICELANDIC = 'Icelandic',
  INDIAN = 'Indian',
  INDONESIAN = 'Indonesian',
  IRANIAN = 'Iranian',
  IRAQI = 'Iraqi',
  IRISH = 'Irish',
  ITALIAN = 'Italian',
  JAMAICAN = 'Jamaican',
  JAPANESE = 'Japanese',
  JORDANIAN = 'Jordanian',
  KAZAKH = 'Kazakh',
  KENYAN = 'Kenyan',
  KUWAITI = 'Kuwaiti',
  KYRGYZ = 'Kyrgyz',
  LAOTIAN = 'Laotian',
  LATVIAN = 'Latvian',
  LEBANESE = 'Lebanese',
  LESOTHO = 'Lesotho',
  LIBERIAN = 'Liberian',
  LIBYAN = 'Libyan',
  LIECHTENSTEINER = 'Liechtensteiner',
  LITHUANIAN = 'Lithuanian',
  LUXEMBOURGER = 'Luxembourger',
  MALAGASY = 'Malagasy',
  MALAWIAN = 'Malawian',
  MALAYSIAN = 'Malaysian',
  MALIAN = 'Malian',
  MALTESE = 'Maltese',
  MAURITIAN = 'Mauritian',
  MEXICAN = 'Mexican',
  MICRONESIAN = 'Micronesian',
  MOLDOVAN = 'Moldovan',
  MONACAN = 'Monacan',
  MONGOLIAN = 'Mongolian',
  MONTENEGRIN = 'Montenegrin',
  MOROCCAN = 'Moroccan',
  MOZAMBICAN = 'Mozambican',
  NAMIBIAN = 'Namibian',
  NAURUAN = 'Nauruan',
  NEPALESE = 'Nepalese',
  NEW_ZEALANDER = 'New Zealander',
  NICARAGUAN = 'Nicaraguan',
  NIGERIEN = 'Nigerien',
  NIGERIAN = 'Nigerian',
  NORTH_KOREAN = 'North Korean',
  NORWEGIAN = 'Norwegian',
  OMANI = 'Omani',
  PAKISTANI = 'Pakistani',
  PALAUAN = 'Palauan',
  PANAMANIAN = 'Panamanian',
  PAPUA_NEW_GUINEAN = 'Papua New Guinean',
  PARAGUAYAN = 'Paraguayan',
  PERUVIAN = 'Peruvian',
  PHILIPPINE = 'Philippine',
  POLISH = 'Polish',
  PORTUGUESE = 'Portuguese',
  QATARI = 'Qatari',
  ROMANIAN = 'Romanian',
  RUSSIAN = 'Russian',
  RWANDAN = 'Rwandan',
  SAINT_LUCIAN = 'Saint Lucian',
  SALVADORAN = 'Salvadoran',
  SAMOAN = 'Samoan',
  SAN_MARINESE = 'San Marinese',
  SAO_TOMEAN = 'Sao Tomean',
  SAUDI = 'Saudi',
  SENEGALESE = 'Senegalese',
  SERBIAN = 'Serbian',
  SEYCHELLOIS = 'Seychellois',
  SIERRA_LEONEAN = 'Sierra Leonean',
  SINGAPOREAN = 'Singaporean',
  SLOVAK = 'Slovak',
  SLOVENIAN = 'Slovenian',
  SOMALI = 'Somali',
  SOUTH_AFRICAN = 'South African',
  SOUTH_KOREAN = 'South Korean',
  SPANISH = 'Spanish',
  SRI_LANKAN = 'Sri Lankan',
  SUDANESE = 'Sudanese',
  SURINAMESE = 'Surinamese',
  SWEDISH = 'Swedish',
  SWISS = 'Swiss',
  SYRIAN = 'Syrian',
  TAJIK = 'Tajik',
  TANZANIAN = 'Tanzanian',
  THAI = 'Thai',
  TOGOLESE = 'Togolese',
  TONGAN = 'Tongan',
  TRINIDADIAN = 'Trinidadian',
  TUNISIAN = 'Tunisian',
  TURKISH = 'Turkish',
  TURKMEN = 'Turkmen',
  UGANDAN = 'Ugandan',
  UKRAINIAN = 'Ukrainian',
  UNITED_STATES = 'United States',
  URUGUAYAN = 'Uruguayan',
  UZBEK = 'Uzbek',
  VENEZUELAN = 'Venezuelan',
  VIETNAMESE = 'Vietnamese',
  YEMENI = 'Yemeni',
  ZAMBIAN = 'Zambian',
  ZIMBABWEAN = 'Zimbabwean',
}

export enum SpecializationArEnum {
  ENGINEERING_AND_PROJECTS = 'الهندسة و المشاريع',
  FACILITY_MANAGEMENT = 'إدارة مرافق',
  PROJECT_MANAGEMENT = 'إدارة مشاريع',
  FINANCE = 'المالية',
  QUALITY_ASSURANCE = 'ضمان الجودة',
  HUMAN_RESOURCES = 'الموارد البشرية',
  INFORMATION_TECHNOLOGY = 'تقنية المعلومات',
  BUSINESS_ANALYSIS = 'تحليل الأعمال',
  UI_UX_DESIGNER = 'تصميم واجهة و تجربة المستخدم',
  GRAPHIC_DESIGNER = 'تصميم الجرافيك',
  LEGAL = 'القانون',
  MANUFACTURING_AND_EHSS = 'التصنيع و السلامة و الصحة البيئية',
  MARKETING = 'التسويق',
  BUSINESS_MANAGEMENT = 'إدارة الأعمال',
  PROCUREMENT = 'المشتريات',
  SALES_CUSTOMER_MANAGEMENT = 'المبيعات و إدارة العملاء',
  SUPPLY_CHAIN = 'سلسلة التوريد',
  TECHNOLOGY_AND_INNOVATION = 'التكنولوجيا و الإبتكار',
  LOGISTICS_SERVICES = 'الخدمات اللوجستية',
}

export enum Country {
  AFGHANISTAN = 'Afghanistan',
  ALBANIA = 'Albania',
  ALGERIA = 'Algeria',
  ANDORRA = 'Andorra',
  ANGOLA = 'Angola',
  ANTIGUA_AND_BARBUDA = 'Antigua and Barbuda',
  ARGENTINA = 'Argentina',
  ARMENIA = 'Armenia',
  AUSTRALIA = 'Australia',
  AUSTRIA = 'Austria',
  AZERBAIJAN = 'Azerbaijan',
  BAHAMAS = 'Bahamas',
  BAHRAIN = 'Bahrain',
  BANGLADESH = 'Bangladesh',
  BARBADOS = 'Barbados',
  BELARUS = 'Belarus',
  BELGIUM = 'Belgium',
  BELIZE = 'Belize',
  BENIN = 'Benin',
  BHUTAN = 'Bhutan',
  BOLIVIA = 'Bolivia',
  BOSNIA_AND_HERZEGOVINA = 'Bosnia and Herzegovina',
  BOTSWANA = 'Botswana',
  BRAZIL = 'Brazil',
  BRUNEI = 'Brunei',
  BULGARIA = 'Bulgaria',
  BURKINA_FASO = 'Burkina Faso',
  BURUNDI = 'Burundi',
  CABO_VERDE = 'Cabo Verde',
  CAMBODIA = 'Cambodia',
  CAMEROON = 'Cameroon',
  CANADA = 'Canada',
  CENTRAL_AFRICAN_REPUBLIC = 'Central African Republic',
  CHAD = 'Chad',
  CHILE = 'Chile',
  CHINA = 'China',
  COLOMBIA = 'Colombia',
  COMOROS = 'Comoros',
  CONGO = 'Congo (Congo-Brazzaville)',
  COSTA_RICA = 'Costa Rica',
  CROATIA = 'Croatia',
  CUBA = 'Cuba',
  CYPRUS = 'Cyprus',
  CZECH_REPUBLIC = 'Czech Republic',
  DENMARK = 'Denmark',
  DJIBOUTI = 'Djibouti',
  DOMINICA = 'Dominica',
  DOMINICAN_REPUBLIC = 'Dominican Republic',
  ECUADOR = 'Ecuador',
  EGYPT = 'Egypt',
  EL_SALVADOR = 'El Salvador',
  EQUATORIAL_GUINEA = 'Equatorial Guinea',
  ERITREA = 'Eritrea',
  ESTONIA = 'Estonia',
  ESWATINI = 'Eswatini',
  ETHIOPIA = 'Ethiopia',
  FIJI = 'Fiji',
  FINLAND = 'Finland',
  FRANCE = 'France',
  GABON = 'Gabon',
  GAMBIA = 'Gambia',
  GEORGIAN = 'Georgia',
  GERMANY = 'Germany',
  GHANA = 'Ghana',
  GREECE = 'Greece',
  GRENADA = 'Grenada',
  GUATEMALA = 'Guatemala',
  GUINEA = 'Guinea',
  GUINEA_BISSAU = 'Guinea-Bissau',
  GUYANA = 'Guyana',
  HAITI = 'Haiti',
  HONDURAS = 'Honduras',
  HUNGARY = 'Hungary',
  ICELAND = 'Iceland',
  INDIA = 'India',
  INDONESIA = 'Indonesia',
  IRAN = 'Iran',
  IRAQ = 'Iraq',
  IRELAND = 'Ireland',
  ITALY = 'Italy',
  JAMAICA = 'Jamaica',
  JAPAN = 'Japan',
  JORDAN = 'Jordan',
  KAZAKHSTAN = 'Kazakhstan',
  KENYA = 'Kenya',
  KIRIBATI = 'Kiribati',
  KUWAIT = 'Kuwait',
  KYRGYZSTAN = 'Kyrgyzstan',
  LAOS = 'Laos',
  LATVIA = 'Latvia',
  LEBANON = 'Lebanon',
  LESOTHO = 'Lesotho',
  LIBERIA = 'Liberia',
  LIBYA = 'Libya',
  LIECHTENSTEIN = 'Liechtenstein',
  LITHUANIA = 'Lithuania',
  LUXEMBOURG = 'Luxembourg',
  MADAGASCAR = 'Madagascar',
  MALAWI = 'Malawi',
  MALAYSIA = 'Malaysia',
  MALDIVES = 'Maldives',
  MALI = 'Mali',
  MALTA = 'Malta',
  MARSHALL_ISLANDS = 'Marshall Islands',
  MAURITANIA = 'Mauritania',
  MAURITIUS = 'Mauritius',
  MEXICO = 'Mexico',
  MICRONESIA = 'Micronesia',
  MOLDOVA = 'Moldova',
  MONACO = 'Monaco',
  MONGOLIA = 'Mongolia',
  MONTENEGRO = 'Montenegro',
  MOROCCO = 'Morocco',
  MOZAMBIQUE = 'Mozambique',
  MYANMAR = 'Myanmar (Burma)',
  NAMIBIA = 'Namibia',
  NAURU = 'Nauru',
  NEPAL = 'Nepal',
  NETHERLANDS = 'Netherlands',
  NEW_ZEALAND = 'New Zealand',
  NICARAGUA = 'Nicaragua',
  NIGER = 'Niger',
  NIGERIA = 'Nigeria',
  NORTH_KOREA = 'North Korea',
  NORTH_MACEDONIA = 'North Macedonia',
  NORWAY = 'Norway',
  OMAN = 'Oman',
  PAKISTAN = 'Pakistan',
  PALAU = 'Palau',
  PANAMA = 'Panama',
  PAPUA_NEW_GUINEA = 'Papua New Guinea',
  PARAGUAY = 'Paraguay',
  PERU = 'Peru',
  PHILIPPINES = 'Philippines',
  POLAND = 'Poland',
  PORTUGAL = 'Portugal',
  QATAR = 'Qatar',
  ROMANIA = 'Romania',
  RUSSIA = 'Russia',
  RWANDA = 'Rwanda',
  SAINT_KITTS_AND_NEVIS = 'Saint Kitts and Nevis',
  SAINT_LUCIA = 'Saint Lucia',
  SAINT_VINCENT_AND_GRENADINES = 'Saint Vincent and the Grenadines',
  SAMOA = 'Samoa',
  SAN_MARINO = 'San Marino',
  SAO_TOME_AND_PRINCIPE = 'Sao Tome and Principe',
  SAUDI_ARABIA = 'Saudi Arabia',
  SENEGAL = 'Senegal',
  SERBIA = 'Serbia',
  SEYCHELLES = 'Seychelles',
  SIERRA_LEONE = 'Sierra Leone',
  SINGAPORE = 'Singapore',
  SLOVAKIA = 'Slovakia',
  SLOVENIA = 'Slovenia',
  SOLOMON_ISLANDS = 'Solomon Islands',
  SOMALIA = 'Somalia',
}

export enum SpecializationEnum {
  ENGINEERING_AND_PROJECTS = 'Engineering & Projects',
  FACILITY_MANAGEMENT = 'Facility Management',
  PROJECT_MANAGEMENT = 'Project Management',
  FINANCE = 'Finance',
  QUALITY_ASSURANCE = 'Quality Assurance',
  HUMAN_RESOURCES = 'Human Resources',
  INFORMATION_TECHNOLOGY = 'Information Technology',
  BUSINESS_ANALYSIS = 'Business Analysis',
  UI_UX_DESIGNER = 'UI / UX Designer',
  GRAPHIC_DESIGNER = 'Graphic Designer',
  LEGAL = 'Legal',
  MANUFACTURING_AND_EHSS = 'Manufacturing & EHSS',
  MARKETING = 'Marketing',
  BUSINESS_MANAGEMENT = 'Business Management',
  PROCUREMENT = 'Procurement',
  SALES_CUSTOMER_MANAGEMENT = 'Sales/Customer Management',
  SUPPLY_CHAIN = 'Supply Chain',
  TECHNOLOGY_AND_INNOVATION = 'Technology & Innovation',
  LOGISTICS_SERVICES = 'Logistics Services',
}

export enum CompanyIndustryEnum {
  ENGINEERING_AND_PROJECTS = 'Engineering & Projects',
  FINANCE = 'Finance',
  QUALITY_ASSURANCE = 'Quality Assurance',
  HUMAN_RESOURCES = 'Human Resources',
  INFORMATION_TECHNOLOGY = 'Information Technology',
  BUSINESS_ANALYSIS = 'Business Analysis',
  UI_UX_DESIGNER = 'UI / UX Designer',
  GRAPHIC_DESIGNER = 'Graphic Designer',
  LEGAL = 'Legal',
  MANUFACTURING_AND_EHSS = 'Manufacturing & EHSS',
  MARKETING = 'Marketing',
  BUSINESS_MANAGEMENT = 'Business Management',
  PROCUREMENT = 'Procurement',
  SALES_CUSTOMER_MANAGEMENT = 'Sales/Customer Management',
  SUPPLY_CHAIN = 'Supply Chain',
  TECHNOLOGY_AND_INNOVATION = 'Technology & Innovation',
  LOGISTICS_SERVICES = 'Logistics Services',
}

export enum CompanyIndustryArEnum {
  ENGINEERING_AND_PROJECTS = 'الهندسة و المشاريع',
  FINANCE = 'المالية',
  QUALITY_ASSURANCE = 'ضمان الجودة',
  HUMAN_RESOURCES = 'الموارد البشرية',
  INFORMATION_TECHNOLOGY = 'تقنية المعلومات',
  BUSINESS_ANALYSIS = 'تحليل الأعمال',
  UI_UX_DESIGNER = 'تصميم واجهة و تجربة المستخدم',
  GRAPHIC_DESIGNER = 'تصميم الجرافيك',
  LEGAL = 'القانون',
  MANUFACTURING_AND_EHSS = 'التصنيع و السلامة و الصحة البيئية',
  MARKETING = 'التسويق',
  BUSINESS_MANAGEMENT = 'إدارة الأعمال',
  PROCUREMENT = 'المشتريات',
  SALES_CUSTOMER_MANAGEMENT = 'المبيعات و إدارة العملاء',
  SUPPLY_CHAIN = 'سلسلة التوريد',
  TECHNOLOGY_AND_INNOVATION = 'التكنولوجيا و الإبتكار',
  LOGISTICS_SERVICES = 'الخدمات اللوجستية',
}
