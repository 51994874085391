import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import SelectComponent2, { optionsList } from 'src/components/common/ui/CV-select-component';
import GridView from 'src/components/common/ui/grid-view';
import SaveCancelButton from 'src/components/user/V2/save-cancel-button';
import { Language, Proficiency, ProficiencyAr } from 'src/core/enums/profile.enum';
import { handleRequestError } from 'src/core/helpers/error.helper';
import useProfileStore from 'src/core/stores/setUserData';
import { LanguageType } from 'src/core/types/CV.type';
import { putCVService, getCVService } from 'src/services/CV.service';

import { cvInitialValues } from '../initialValues';
import { useLanguageData } from './useLanguageData';

export default function LanguageForm({
  setIsLanguage,
  selectedId,
}: {
  setIsLanguage: (value: boolean) => void;
  selectedId?: string;
}) {
  const { t, i18n } = useTranslation();
  const { data, setUserData } = useProfileStore();
  const { languageData } = useLanguageData(selectedId);
  const languageOptionsList: optionsList[] = Object.entries(Language).map(([key, value]) => ({
    value: value,
    key: key,
  }));

  const ProficiencyOptionsList: optionsList[] = Object.entries(Proficiency).map(([key, value]) => ({
    value: value,
    key: key,
  }));

  const ProficiencyArOptionsList: optionsList[] = Object.entries(ProficiencyAr).map(
    ([key, value]) => ({
      value: value,
      key: key,
    })
  );

  const handleSubmit = async (values: Partial<LanguageType>) => {
    try {
      if (selectedId) {
        const original = data.language?.find((lang) => lang.language === selectedId);
        const isUnchanged =
          original &&
          original.language === values.language &&
          original.proficiency === values.proficiency;

        if (isUnchanged) {
          setIsLanguage(false);
          return;
        }
      }

      const updatedLanguage = selectedId
        ? data.language?.map((lang) =>
            lang.language === selectedId ? { ...lang, ...values } : lang
          ) || []
        : [...(data?.language || []), { ...values }];

      await putCVService({
        ...data,
        language: updatedLanguage,
      });

      const updatedCV = await getCVService();
      setUserData(updatedCV);
      handleRequestError.success(t('container.CV.confirmation.saved'));
      setIsLanguage(false);
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <Formik
      initialValues={languageData || (cvInitialValues.language?.[0] as Partial<LanguageType>)}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, errors, touched }) => (
        <Form className="flex flex-col gap-y-4 px-7 h-[50vh] py-5 mobile:px-0 justify-between">
          <GridView column="1">
            <SelectComponent2
              selectLabel="container.CV.languages"
              name="language"
              isValid={touched.language && !errors.language}
              value={values.language}
              onChange={handleChange}
              errorMsg={errors.language}
              options={languageOptionsList}
            />
            <SelectComponent2
              selectLabel="container.CV.Proficiency"
              name="proficiency"
              isValid={touched.proficiency && !errors.proficiency}
              value={values.proficiency}
              onChange={handleChange}
              errorMsg={errors.proficiency}
              options={i18n.language === 'ar' ? ProficiencyArOptionsList : ProficiencyOptionsList}
            />
          </GridView>
          <SaveCancelButton
            onCancel={() => {
              setIsLanguage(false);
            }}
          />
        </Form>
      )}
    </Formik>
  );
}
