import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { DirectionEnum } from 'src/core/enums/ui.enums';
import useModeStore from 'src/core/helpers/useModeStore';

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  selectLabel: string;
  errorMsg?: string;
  value?: string | number;
  disabled?: boolean;
  isValid?: boolean;
  options: string[];
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export default function SelectComponent({
  name,
  value = '',
  selectLabel,
  disabled,
  errorMsg = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isValid = true,
  options,
  onChange,
  ...props
}: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | number>(value || '');
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === 'dark';

  const getError = () => {
    if (errorMsg !== '') {
      isValid = false;
      return 'border-red-500 focus:border-red-500 focus:border-2 ';
    }
    isValid = true;
    return '';
  };
  const handleInputClick = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionSelect = (option: string) => {
    setSelectedValue(option);
    setIsOpen(false);
    onChange({
      target: { name, value: option },
    } as ChangeEvent<HTMLSelectElement>);
  };
  useEffect(() => {
    setSelectedValue(value || ''); // Update selectedValue when props.value changes this is needed to avoid the select value not updating
  }, [i18n.language, value]);

  return (
    <div className="relative w-full custom-dropdown">
      <div
        className={`${
          i18n.language === 'en' ? 'right-4' : 'left-4'
        } absolute top-4 text-gray-400 pointer-events-none`}
      >
        {isOpen ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
      </div>
      <input
        type="text"
        name={name}
        disabled={disabled}
        value={`${t(selectedValue as string)}`}
        readOnly
        onClick={handleInputClick}
        className={`block relative capitalize cursor-pointer placeholder:text-white focus:border focus:border-neutral-200 text-base h-14 mobile:h-12 rounded-lg px-2.5 pb-0 mobile:pb-0 tablet:pb-0 pt-5 w-full ${
          i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'
        } text-white bg-neutral-500/10 border-neutral-500/10 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
          errorMsg !== '' ? `border ${getError()}` : ''
        }`}
        placeholder={props.defaultValue?.toString() || t('app.shared.select') || ''}
      />
      {isOpen && (
        <div
          className={`absolute top-[63px] mobile:top-[50px] z-50 ${
            isDarkMode ? 'bg-stone-900' : 'bg-gray-600'
          } rounded w-full h-fit py-2 px-4`}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className={`flex capitalize hover:text-secondary text-white h-9 items-center cursor-pointer 
                ${i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'}`}
              onClick={() => handleOptionSelect(option)}
            >
              {t(option)}
            </div>
          ))}
        </div>
      )}
      <label
        className={`absolute ${
          i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad '
        } text-gray-500 text-base peer-focus:translate-x-1 duration-300 transform -translate-y-4 scale-70 top-4 z-10 origin-[0] start-2.5  ${
          props.dir === DirectionEnum.LTR ? 'left-0' : 'right-0 '
        }`}
      >
        {t(selectLabel)}
      </label>
      <div
        className={`flex justify-between px-2 py-1 text-xs ${
          i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'
        } text-gray-900`}
      >
        {errorMsg !== '' ? (
          <p className={`${getError()} text-red-500`}>{t(errorMsg)}</p>
        ) : (
          <p className={`${getError()}`}>{''}</p>
        )}
      </div>
    </div>
  );
}
