import { AcceleratedFormType } from 'src/core/types/accelerated-form-type';

export const acceleratedData: AcceleratedFormType = {
  name: '',
  last_name: '',
  mobile: '',
  email: '',
  product_name: '',
  product_main_idea: '',
  product_differentiation: '',
  idea_inspiration: '',
  target_audience: '',
  key_features: '',
  unique_design_concept: '',
  additional_features: '',
  development_stage: '',
  core_technology: '',
  enhancement_plan: '',
  technical_challenges: '',
  testing_results: '',
  development_methodologies: '',
  user_experience_update_plan: '',
  new_technologies_adoption: '',
  market_size: '',
  market_entry_strategy: '',
  pricing_strategy: '',
  market_research: '',
  marketing_channels: '',
  competition_strategy: '',
  revenue_forecast: '',
  post_launch_market_strategy: '',
  distribution_plan: '',
  required_resources: '',
  suppliers_partners: '',
  product_quality_plan: '',
  logistical_challenges: '',
  essential_logistics: '',
  geographical_expansion: '',
  contingency_plans: '',
  personal_experiences: '',
  alignment_with_values: '',
  life_impact: '',
  personal_challenges: '',
  experience_shaping_vision: '',
  influences_on_creation: '',
  lessons_learned: '',
  contribution_to_life_goals: '',
  future_vision: '',
  market_trends_effect: '',
  personal_professional_goals: '',
  future_collaborations: '',
  product_evolution_over_time: '',
  staying_ahead_of_trends: '',
  product_legacy: '',
  motivation_to_improve: '',
};
