import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PiEnvelopeSimple, PiGlobe, PiPhone, PiVideoCamera } from 'react-icons/pi';
import { useParams, useNavigate } from 'react-router-dom';
import SelectComponent from 'src/components/common/ui/select-component';
import CardComponent from 'src/components/user/card-component';
import { GalleryEnum } from 'src/core/enums/example.enum';
import { SkeletonEnum } from 'src/core/enums/skeleton.enum';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { getPlusCompaniesType, getPlusCompanyByIdResultListType } from 'src/core/types/plus.type';
import { ProgramsVersionsType } from 'src/core/types/user.type';
import { getPlusCompanies, getPlusCompanyById } from 'src/services/plus.service';
import { getProgramsVersionsService } from 'src/services/programs-versions.service';

import AllStartupComponent from './all-startup-component';
import StartUpSkeletonCard from './start-up-skeleton';

export default function StartupPlusContainers() {
  const { t, i18n } = useTranslation();
  const limit = 20;
  const [selectedCohort, setSelectedCohort] = useState<number>(1);
  const [selectedData, setSelectedData] = useState<getPlusCompanyByIdResultListType>();
  const [Loading, setLoading] = useState(true);
  const [allData, setAllData] = useState<getPlusCompaniesType>();
  const { plusID = '' } = useParams<{ plusID: string }>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [programOptions, setProgramOptions] = useState<ProgramsVersionsType[]>([]);
  const programOptionsVersion = programOptions.map((item) => Number(item.version));
  const navigate = useNavigate();
  const maxLength = 200;
  const getPlusList = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getPlusCompanyById(plusID);
      setSelectedData(result);
    } catch (error) {
      handleRequestError(error);
    } finally {
      setLoading(false);
    }
  }, [plusID]);

  const getAllPlusList = useCallback(async () => {
    try {
      const result = await getPlusCompanies(selectedCohort, limit, 0);
      setAllData(result);
      const matchedResult = allData?.result_list.find((data) => data.id)?.id;
      if (plusID === matchedResult) {
        navigate(`/startup/${result?.result_list[0]?.id}`, { replace: true });
      }
    } catch (error) {
      handleRequestError(error);
    }
  }, [selectedCohort]);

  const getVersions = async () => {
    try {
      const data = await getProgramsVersionsService(
        i18n.language.toUpperCase(),
        GalleryEnum.PLUS.toUpperCase()
      );
      setProgramOptions(data);
    } catch (error) {
      handleRequestError(error);
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleChange = (selectedIndex: number) => {
    const selectedVersion = programOptionsVersion[selectedIndex];
    setSelectedCohort(selectedVersion);
    if (selectedVersion) {
      navigate(`/startup/${allData?.result_list[0]?.id}`, { replace: true });
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProgram = programOptions.find(
      (option) =>
        `${t('container.community.cohort')} ${option.name.toLowerCase()}` ===
        e.target.value.toLowerCase()
    );
    if (selectedProgram) {
      handleChange(programOptions.indexOf(selectedProgram));
    }
  };
  const handleStartupClick = (id: string) => {
    setIsExpanded(false);
    navigate(`/startup/${id}`, { replace: true });
  };

  const displayDescription = () => {
    const translatedDescription =
      i18n.language === 'en' ? selectedData?.description_en : selectedData?.description_ar || '';

    if (!isExpanded && translatedDescription && translatedDescription.length > maxLength) {
      return `${translatedDescription.slice(0, maxLength)}...`;
    }

    return translatedDescription;
  };
  const isLong = () => {
    const descriptionLength =
      i18n.language === 'en' ? selectedData?.description_en : selectedData?.description_ar || '';
    const result = (descriptionLength?.length || 0) < maxLength;

    return result;
  };
  useEffect(() => {
    setIsExpanded(isLong());
  }, [selectedData]);
  useEffect(() => {
    getVersions();
    getAllPlusList();
  }, [i18n.language, getAllPlusList]);

  useEffect(() => {
    if (plusID) {
      getPlusList();
    }
  }, [getPlusList, plusID]);

  return (
    <div>
      {/* Company logo */}
      <div className="flex items-center gap-5 mx-auto w-full justify-start px-5 mobile:px-0 mobile:flex-col">
        {Loading ? (
          <StartUpSkeletonCard size={SkeletonEnum.LARGE} />
        ) : (
          <img
            src={selectedData?.logo}
            alt={selectedData?.startup_name}
            className="h-[160px] w-[157px] laptop:h-[140px] bg-gray-200 px-3 py-2 rounded-lg mobile:w-[350px] mobile:h-[180px] mobile:object-contain cursor-pointer"
          />
        )}
        <div className="flex flex-col justify-start">
          {/* Company name and description */}
          {Loading ? (
            <StartUpSkeletonCard size={SkeletonEnum.TEXT} />
          ) : (
            <div>
              <p
                className={`text-white font-helveticaMedium mobile:mt-4 ${
                  i18n.language === 'ar'
                    ? 'text-2xl text-right font-arMyriad'
                    : 'text-base font-helveticaMedium'
                }`}
              >
                {t(`${selectedData?.startup_name}`)}
              </p>
              <span className="pt-2  flex flex-col justify-start text-justify">
                <p
                  className={`items-start mobile:hidden tablet:hidden  text-neutral-400 text-justify h-[79px] ${
                    isExpanded ? 'overflow-auto  ' : 'overflow-hidden text-ellipsis'
                  } ${
                    i18n.language === 'ar'
                      ? 'font-arMyriad text-xl leading-6'
                      : 'font-helveticaMedium text-lg leading-6'
                  }`}
                >
                  {displayDescription()}

                  {!isExpanded && !isLong() && (
                    <button
                      onClick={toggleExpand}
                      className={`text-primary underline bg-transparent px-2 mobile:hidden ${
                        i18n.language === 'ar'
                          ? 'font-arMyriad text-base'
                          : 'font-helveticaMedium text-xs'
                      }`}
                    >
                      {t('containers.StartupPlusContainer.descriptionExpandedButton')}
                    </button>
                  )}
                </p>
              </span>
              <div className="mobile:block tablet:block  h-auto hidden text-neutral-400 text-justify">
                {i18n.language === 'ar'
                  ? selectedData?.description_ar
                  : selectedData?.description_en}
              </div>
            </div>
          )}

          {/* Contact and social media icons */}
          <div className="text-white flex justify-start pt-2 mobile:pt-10">
            <div className="grid grid-cols-4 gap-x-4">
              {/* Phone icon */}
              {selectedData?.mobile !== null && (
                <a
                  className="w-8 h-10 flex justify-center items-center"
                  href={`tel:${selectedData?.mobile}`}
                >
                  <CardComponent isHover={true} chiled={true} icon={<PiPhone />} />
                </a>
              )}

              {/* Email icon */}
              {selectedData?.email && (
                <div className="w-8 h-10 flex justify-center items-center">
                  <a href={`mailto:${selectedData?.email}`}>
                    <CardComponent isHover={true} chiled={true} icon={<PiEnvelopeSimple />} />
                  </a>
                </div>
              )}

              {/* Website icon */}
              {selectedData?.website && (
                <div className="w-8 h-10 flex justify-center items-center">
                  <a href={selectedData?.website} target="_blank" rel="noopener noreferrer">
                    <CardComponent isHover={true} chiled={true} icon={<PiGlobe />} />
                  </a>
                </div>
              )}
              {/* Video icon */}
              {selectedData?.video && (
                <div className="w-8 h-10 flex justify-center items-center">
                  <div onClick={() => window.open(selectedData.video || '')}>
                    <CardComponent
                      isHover={true}
                      chiled={true}
                      icon={<PiVideoCamera size={24} />}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`text-white px-4 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>
        <div className="flex flex-col justify-start w-full tablet:justify-center">
          <div className="w-1/3  pb-5 largeLaptop:w-[45%] laptop:w-[45%] px-4 pt-4 mobile:w-full mobile:px-2 tablet:w-full">
            <SelectComponent
              selectLabel="app.shared.select"
              options={programOptions.map(
                (item) => `${t('container.community.cohort')} ${item.name.toLowerCase()}`
              )}
              name="Plus"
              value={`${t('container.community.cohort')} ${programOptions[selectedCohort - 1]?.name.toLowerCase()}`}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const newValue = e.target.value.indexOf('Cohort');
                setSelectedCohort(Number(e.target.value.slice(newValue + 7)));
                handleSelectChange(e);
              }}
            />
          </div>
          <div>
            <AllStartupComponent
              allData={allData ?? { result_list: [], max_count: null, total_count: 0 }}
              setSelectedData={handleStartupClick}
              Loading={Loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
