import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';
import { appRoutesObj } from 'src/app.paths';
import { Layout } from 'src/components/common/layout-component/layout-component';
import PopupCard from 'src/components/common/PopupCard';
import PermitContainer from 'src/containers/user/Transportation-container/permit-form';

export default function PermitPage() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language') || 'ar');
  }, [i18n]);

  return (
    <Layout sidebar={false}>
      <PopupCard
        link={`${appRoutesObj.getBasePath()}`}
        icon={<IoMdClose size={32} className="text-white" />}
      >
        <>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}>
            <div
              className={`text-white justify-center text-center w-full  pb-10 ${
                i18n.language == 'en' ? 'font-helveticaBold text-lg' : 'font-arMyriad text-[28px]'
              }`}
            >
              {t('app.share.title.TransportationPermits')}
            </div>
            <PermitContainer />
          </GoogleReCaptchaProvider>
        </>
      </PopupCard>
    </Layout>
  );
}
