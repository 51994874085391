import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SkeletonCard from 'src/containers/user/skeleton/skeleton-container';
import { SkeletonEnum } from 'src/core/enums/skeleton.enum';
import { handleRequestError } from 'src/core/helpers/error.helper';
import useModeStore from 'src/core/helpers/useModeStore';
import { ActivitiesElement } from 'src/core/statics/booking.static';
import { DisruputTime } from 'src/core/types/disrupt.type';
import { disruptStatus } from 'src/services/disrupt-service';
import { getDisruptTimeServices } from 'src/services/disrupt-service';

import CardComponent from './card-component';
import DisruptTimeAndDateComponent from './disrupt-time-and-date-component';

export default function ActivitiesComponent() {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [scrollX, setScrollX] = useState<number>(0);
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const [disruptTimeLoading, setDisruptTimeLoading] = useState<boolean>(true);
  const [disruptTime, setDisruptTime] = useState<DisruputTime>();

  const distruptStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await disruptStatus();
      setIsOpen(result.is_open);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    distruptStatus();
  }, [distruptStatus]);
  const isDarkMode = mode === 'dark';

  const handleScroll = () => {
    if (scrollDivRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollDivRef.current;
      const scrollableWidth = scrollWidth - clientWidth;
      const scrollPos = (scrollLeft / scrollableWidth) * 100;
      setScrollX(scrollPos);
    }
  };

  useEffect(() => {
    const div = scrollDivRef.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
      return () => {
        div.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollDivRef.current, scrollX]);

  const maskValue =
    scrollX < 0 && scrollX > -98
      ? 'hover:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-100px),transparent_100%)]'
      : scrollX < -98
        ? 'hover:[mask-image:_linear-gradient(to_left,transparent_0,_black_128px,_black_calc(100%),transparent_0%)]'
        : 'hover:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%),transparent_0%)]';

  const maskValueEng =
    scrollX > 0 && scrollX < 98
      ? 'hover:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-100px),transparent_100%)]'
      : scrollX > 98
        ? 'hover:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%),transparent_0%)]'
        : 'hover:[mask-image:_linear-gradient(to_left,transparent_0,_black_128px,_black_calc(100%),transparent_0%)]';

  const initObj = {
    id: '',
    start_at: null,
    end_at: null,
    disrupt_number: 0,
  };

  const getDisruptTime = useCallback(async () => {
    setDisruptTimeLoading(true);
    try {
      const result = await getDisruptTimeServices();
      setDisruptTime(result);
      setDisruptTimeLoading(false);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);

  useEffect(() => {
    getDisruptTime();
  }, []);

  return (
    <>
      <div
        ref={scrollDivRef}
        className={
          `group flex flex-row w-full desktop:w-[calc(75vw-554px)] 
          largeLaptop:w-[calc(52vw-226px)] laptop:w-[calc(75vw-364px)] flex-nowrap gap-6 overflow-hidden 
          [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-100px),transparent_100%)]
          hover:snap-mandatory hover:snap-x hover:overflow-x-auto hover:px-1 mobile:hidden tablet:hidden ` +
          (i18n.language === 'ar' ? maskValue : maskValueEng)
        }
      >
        <div
          className={`flex flex-row w-fit gap-6 items-center justify-center md:justify-start h-full py-2 group-hover:animate-none ${
            i18n.language === 'ar' ? 'animate-infinite-scroll' : 'animate-infinite-scrollENG'
          }`}
        >
          {ActivitiesElement.map((activity, index) => (
            <div key={index}>
              <Link to={activity.link} key={index}>
                <CardComponent
                  isHover={true}
                  className={`text-white flex-wrap w-[415px] laptop:w-[390px] h-[120px] largeLaptop:h-[100px] rounded-2xl py-3 px-4 laptop:py-1.5 laptop:px-1.5 laptop:h-[100px] from-neutral-900/10 via-neutral-900/10 to-secondary/10 ${
                    i18n.language === 'en' ? 'bg-gradient-to-br' : 'bg-gradient-to-bl'
                  } ${isDarkMode ? 'border border-gray-600/50' : 'border border-neutral/50'}`}
                >
                  <div className="flex flex-row w-full whitespace-nowrap items-center h-full px-3">
                    {activity.ActivitiesType === 'disrupt' ? (
                      <img src={activity.img} alt="distrupt" className="w-24 laptop:w-[100px]" />
                    ) : (
                      <div className="text-start mb-8">
                        <h1 className="text-white text-base font-bold tracking-wider">
                          THE GARAGE
                        </h1>
                        <h2 className="text-secondary text-xs leading-[5px] font-bold">DEMO DAY</h2>
                      </div>
                    )}
                    {isLoading ? (
                      <div className="w-full flex flex-col justify-end items-end gap-2">
                        {Array.from({ length: 2 }).map((_, index) => (
                          <div className="flex justify-cenetr items-center gap-4" key={index}>
                            <div>
                              <SkeletonCard size={SkeletonEnum.ICON} isDarkMode={isDarkMode} />
                            </div>
                            <div className="w-20">
                              <SkeletonCard size={SkeletonEnum.TEXTSHORT} isDarkMode={isDarkMode} />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : isOpen && !disruptTimeLoading ? (
                      <div className="flex justify-end w-full px-6">
                        <DisruptTimeAndDateComponent
                          disruptTime={disruptTime || initObj}
                          date={activity.staticDate ? String(t(activity.staticDate)) : undefined}
                          time={activity.staticTime ? String(t(activity.staticTime)) : undefined}
                        />
                      </div>
                    ) : (
                      <p
                        className={`w-full flex justify-end mx-auto px-10 ${
                          i18n.language === 'en'
                            ? 'font-helveticaMedium text-lg'
                            : 'font-arMyriad text-xl'
                        }`}
                      >
                        {t(`${activity.description}`)}
                      </p>
                    )}
                  </div>
                </CardComponent>
              </Link>
            </div>
          ))}
        </div>
        <div
          aria-hidden="true"
          className={`flex flex-row w-fit gap-6 items-center justify-center md:justify-start h-full py-2 group-hover:animate-none group-hover:hidden ${
            i18n.language === 'ar' ? 'animate-infinite-scroll' : 'animate-infinite-scrollENG'
          }`}
        >
          {ActivitiesElement.map((activity, index) => (
            <div key={index}>
              <Link to={activity.link} key={index}>
                <CardComponent
                  isHover={true}
                  className={`text-white flex-wrap w-[415px] laptop:w-[390px] h-[120px] largeLaptop:h-[100px] rounded-2xl py-3 px-4 laptop:py-1.5 laptop:px-1.5 laptop:h-[100px] from-neutral-900/10 via-neutral-900/10 to-secondary/10 ${
                    i18n.language === 'en' ? 'bg-gradient-to-br' : 'bg-gradient-to-bl'
                  } ${isDarkMode ? 'border border-gray-600/50' : 'border border-neutral/50'}`}
                >
                  <div className="flex flex-row w-full whitespace-nowrap items-center h-full px-3">
                    {activity.ActivitiesType === 'disrupt' ? (
                      <img src={activity.img} alt="distrupt" className="w-24 laptop:w-[100px]" />
                    ) : (
                      <div className="text-start mb-8">
                        <h1 className="text-white text-base font-bold tracking-wider">
                          THE GARAGE
                        </h1>
                        <h2 className="text-secondary text-xs leading-[5px] font-bold">DEMO DAY</h2>
                      </div>
                    )}
                    {isLoading ? (
                      <div className="w-full flex flex-col justify-end items-end gap-2">
                        {Array.from({ length: 2 }).map((_, index) => (
                          <div className="flex justify-cenetr items-center gap-4" key={index}>
                            <div>
                              <SkeletonCard size={SkeletonEnum.ICON} isDarkMode={isDarkMode} />
                            </div>
                            <div className="w-20">
                              <SkeletonCard size={SkeletonEnum.TEXTSHORT} isDarkMode={isDarkMode} />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : isOpen && !disruptTimeLoading ? (
                      <div className="flex justify-end w-full px-6">
                        <DisruptTimeAndDateComponent
                          disruptTime={disruptTime || initObj}
                          date={activity.staticDate ? String(t(activity.staticDate)) : undefined}
                          time={activity.staticTime ? String(t(activity.staticTime)) : undefined}
                        />
                      </div>
                    ) : (
                      <p
                        className={`w-full flex justify-end mx-auto px-10 ${
                          i18n.language === 'en'
                            ? 'font-helveticaMedium text-lg'
                            : 'font-arMyriad text-xl'
                        }`}
                      >
                        {t(`${activity.description}`)}
                      </p>
                    )}
                  </div>
                </CardComponent>
              </Link>
            </div>
          ))}
        </div>
      </div>

      {/* Keep the mobile/tablet view as is */}
      <div className="mx-auto w-full items-center desktop:hidden largeLaptop:hidden laptop:hidden overflow-y-auto">
        <div className="flex flex-col gap-0">
          {ActivitiesElement.map((activity, index) => (
            <Link to={activity.link} key={index}>
              <CardComponent
                isHover={false}
                className={`px-5 pt-4 h-[121px] bg-neutral/10 tablet:w-full mobile:w-full rounded-2xl mb-3
            from-neutral-900/50 via-neutral-900/50 to-secondary/20 ${
              i18n.language === 'en' ? 'bg-gradient-to-br' : 'bg-gradient-to-bl'
            }
            ${isDarkMode ? ' border border-gray-600/50 ' : ' border border-neutral/50'}`}
              >
                <div className="flex flex-row whitespace-nowrap items-center h-full px-3 text-white">
                  <div>
                    {activity.ActivitiesType === 'garagePlus' ? (
                      <div className="text-start mb-8 ">
                        <h1 className="text-white text-base font-bold tracking-wider">
                          THE GARAGE
                        </h1>
                        <h2 className="text-secondary text-xs leading-[5px] font-bold">DEMO DAY</h2>
                      </div>
                    ) : (
                      <img src={activity.img} alt="distrupt" className="w-auto" />
                    )}
                  </div>
                  {isLoading ? (
                    <div className="w-full flex flex-col justify-end items-end gap-2">
                      {Array.from({ length: 2 }).map((_, index) => (
                        <div className="flex justify-cenetr items-center gap-4" key={index}>
                          <div>
                            <SkeletonCard size={SkeletonEnum.ICON} isDarkMode={isDarkMode} />
                          </div>
                          <div className="w-20">
                            <SkeletonCard size={SkeletonEnum.TEXTSHORT} isDarkMode={isDarkMode} />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : isOpen && !disruptTimeLoading ? (
                    <div className="flex justify-end w-full px-6">
                      <DisruptTimeAndDateComponent
                        disruptTime={disruptTime || initObj}
                        date={activity.staticDate ? String(t(activity.staticDate)) : undefined}
                        time={activity.staticTime ? String(t(activity.staticTime)) : undefined}
                      />
                    </div>
                  ) : (
                    <p
                      className={`pt-1 largeLaptop:w-[260px]laptop:text-[10px] laptop:w-[150px] laptop:pb-4 ${
                        i18n.language === 'ar'
                          ? 'font-arMyriad text-base'
                          : 'font-helveticaMedium text-xs'
                      }`}
                    >
                      {t(`${activity.description}`)}
                    </p>
                  )}
                </div>
              </CardComponent>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
