// eslint-disable-next-line import/named
import { atcb_action, ATCBActionEventConfig } from 'add-to-calendar-button';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTwitterX } from 'react-icons/bs';
import { HiLink } from 'react-icons/hi';
import { ImLinkedin2 } from 'react-icons/im';
import { IoMdClose } from 'react-icons/io';
import { appRoutesObj } from 'src/app.paths';
import { SizesEnum } from 'src/core/enums/ui.enums';
import useModeStore from 'src/core/helpers/useModeStore';

import CircleIconComponent from './circle-icon-component';
import { Button } from './common/ui/Button';

interface QRPopupComponentProps {
  onClose?: () => void;
  user_id?: string;
  date?: string; // Expecting ISO 8601 string format for date
}

export default function QRPopupComponent(props: QRPopupComponentProps) {
  const { mode } = useModeStore();
  const { t, i18n } = useTranslation();

  // Ensure the date is provided and correctly formatted
  const startDateTime = props.date ? new Date(props.date) : new Date();
  const startDate = startDateTime.toISOString().split('T')[0];
  const endDateTime = new Date(startDateTime.getTime() + 2 * 60 * 60 * 1000);
  const endDate = endDateTime.toISOString().split('T')[0];

  const config: ATCBActionEventConfig = {
    name: '[Reminder] The Garage Disrupt',
    description: 'This is to remind you of The Garage Disrupt event.',
    startDate: startDate,
    endDate: endDate,
    startTime: '19:00',
    endTime: '21:00',
    location: '', // Add if you have a location
    options: ['Apple', 'Google', 'iCal'],
    timeZone: 'currentBrowser', // or specific time zone string
  };

  useEffect(() => {
    const buttons = document.getElementById('my-default-button');
    if (buttons) {
      buttons.addEventListener('click', () => atcb_action(config, buttons));

      // Cleanup the event listener on component unmount
      return () => {
        if (buttons) {
          buttons.removeEventListener('click', () => atcb_action(config, buttons));
        }
      };
    }
  }, [config]);

  return (
    <div
      className={`relative p-4 w-[45%] laptop:w-[70%] largeLaptop:w-[60%] tablet:w-fit mobile:w-fit mobile:mx-6 ${
        mode === 'dark' ? 'bg-neutral-800 border-neutral/50' : 'bg-neutral-300 border-white/50'
      } backdrop-blur-xl border border-neutral/50 h-auto py-5 rounded-2xl shadow-2xl`}
    >
      <div className="flex items-end justify-end">
        <IoMdClose
          size={32}
          color={`${mode === 'dark' ? 'white' : ''}`}
          className="cursor-pointer"
          onClick={props.onClose}
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        <span
          className={`text-white pb-2 ${
            i18n.language === 'en' ? 'font-helveticaMedium text-base' : 'font-arMyriad text-2xl'
          }`}
        >
          {t('component.QRPopupComponent.confirmAttendance')}
        </span>
        <img src="/assets/images/disrupt.svg" alt="disrupt" className="w-[30%]" />
        <div className="px-3 py-4 my-4 bg-white border border-primary mobile:py-2 mobile:px-2">
          <QRCodeSVG value={props.user_id || ''} bgColor={'#ffffff'} level={'H'} size={170} />
        </div>
        <Button
          size={SizesEnum.Large}
          className="flex items-center"
          id="my-default-button"
          type="button"
        >
          {t('component.QRPopupComponent.addToCalender')}
        </Button>
        <span
          className={`text-white text-center px-36 mobile:px-12 leading-[19.5px] opacity-60 pt-2 ${
            i18n.language === 'en' ? 'font-helveticaMedium text-sm' : 'font-arMyriad text-base'
          }`}
        >
          {t('component.QRPopupComponent.stayTouch')}
        </span>
        <div className="flex items-center justify-center gap-x-2">
          <CircleIconComponent
            icon={ImLinkedin2}
            link={'https://www.linkedin.com/company/thegarageksa'}
          />
          <CircleIconComponent icon={BsTwitterX} link={'https://twitter.com/TheGarageKSA'} />
          <CircleIconComponent icon={HiLink} link={appRoutesObj.getBasePath()} />
        </div>
        <span
          className={`text-xs text-white text-center opacity-60 ${
            i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'
          }`}
        >
          {t('component.QRPopupComponent.allRightReserved')}
        </span>
      </div>
    </div>
  );
}
