import React from 'react';

interface gridProps {
  children: React.ReactNode;
  column: '1' | '2' | '4' | '6';
}
export default function GridView({ children, column }: gridProps) {
  const getColumn = (column: '1' | '2' | '4' | '6') => {
    switch (column) {
      case '1':
        return 'grid-cols-1';
      case '2':
        return 'grid-cols-2';
        break;
      case '4':
        return 'grid-cols-4';
        break;
      case '6':
        return 'grid-cols-6';
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={`w-full grid gap-x-4 gap-y-4 ${getColumn(column)} mobile:grid-cols-1 mobile:gap-9 tablet:grid-cols-1 tablet:gap-2 `}
    >
      {children}
    </div>
  );
}
