import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { appRoutesObj } from 'src/app.paths';
import { Layout } from 'src/components/common/layout-component/layout-component';
import PopupCard from 'src/components/common/PopupCard';
import AcceleratedFormContainer from 'src/containers/user/accelerated-form-container/accelerated-form-container';

export default function AcceleratedFormPage() {
  return (
    <Layout sidebar={false}>
      <PopupCard link={`${appRoutesObj.getBasePath()}`}>
        <>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}>
            <AcceleratedFormContainer />
          </GoogleReCaptchaProvider>
        </>
      </PopupCard>
    </Layout>
  );
}
