import { IoMdClose } from 'react-icons/io';
import { appRoutesObj } from 'src/app.paths';
import { Layout } from 'src/components/common/layout-component/layout-component';
import PopupCard from 'src/components/common/PopupCard';
import GaragePlusContainer from 'src/containers/user/garage-plus-container/garage-plus-container';

function GaragePlusPage() {
  return (
    <Layout>
      <PopupCard
        link={`${appRoutesObj.getBasePath()}`}
        icon={<IoMdClose size={32} className="text-white" />}
      >
        <GaragePlusContainer />
      </PopupCard>
    </Layout>
  );
}

export default GaragePlusPage;
