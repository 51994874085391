import {
  useState,
  useRef,
  KeyboardEvent,
  ClipboardEvent,
  InputHTMLAttributes,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

interface OTPComponentProps extends InputHTMLAttributes<HTMLInputElement> {
  length?: number;
  onComplete?: (otp: string) => void;
  error?: boolean;
  errorMessage?: string;
  value?: string;
  onChange?: (e: { target: { name: string; value: string } }) => void;
  name?: string;
}

export default function OTPComponent({
  length = 4,
  onComplete,
  error = false,
  errorMessage,
  value = '',
  onChange,
  name = 'OTP',
  ...props
}: OTPComponentProps) {
  const [otp, setOtp] = useState<string[]>(
    value ? value.split('').slice(0, length) : new Array(length).fill('')
  );
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { t, i18n } = useTranslation();

  const handleChange = (index: number, inputValue: string) => {
    if (isNaN(Number(inputValue))) return;

    const newOtp = [...otp];
    newOtp[index] = inputValue.substring(inputValue.length - 1);
    setOtp(newOtp);

    const otpValue = newOtp.join('');
    onChange?.({
      target: {
        name,
        value: otpValue,
      },
    });

    if (inputValue && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }

    if (otpValue.length === length && onComplete) {
      onComplete(otpValue);
    }
  };

  useEffect(() => {
    if (value) {
      setOtp(
        value.split('').slice(0, length).concat(new Array(length).fill('').slice(value.length))
      );
    }
  }, [value, length]);

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };
  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, length);
    const newOtp = [...otp];

    for (let i = 0; i < pastedData.length; i++) {
      if (isNaN(Number(pastedData[i]))) continue;
      newOtp[i] = pastedData[i];
    }

    setOtp(newOtp);
    onChange?.({
      target: {
        name,
        value: newOtp.join(''),
      },
    });
    if (newOtp.join('').length === length && onComplete) {
      onComplete(newOtp.join(''));
    }

    inputRefs.current[Math.min(pastedData.length, length - 1)]?.focus();
  };

  const getErrorStyle = () => {
    if (error) {
      return 'border-red-500 focus:border-red-500 focus:ring-red-500/40';
    }
    return 'focus:border-primary focus:ring-yellow-100';
  };

  return (
    <div className={'flex flex-col  gap-1 w-full text-left'}>
      {/* the dir has been pre-set to ltr for the input elements to avoid the issue of the input elements being reversed */}
      <div
        className={`flex gap-7 mobile:gap-4 w-full ${i18n.language === 'ar' && 'justify-end'}`}
        dir="ltr"
      >
        {otp.map((digit, index) => (
          <input
            {...props}
            key={index}
            ref={(ref) => (inputRefs.current[index] = ref)}
            type="text"
            inputMode="numeric"
            maxLength={1}
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            className={`w-12 h-12 text-center border bg-transparent text-white rounded-lg focus:ring-2 focus:outline-none ${otp[index] !== '' && !error && 'border-primary'} ${getErrorStyle()}`}
          />
        ))}
      </div>
      {error && errorMessage && (
        <p
          className={`text-red-500 text-sm  w-full flex ${i18n.language === 'en' ? 'text-sm font-helveticaMedium items-start ' : 'text-xs font-arMyriad items-start'}`}
        >
          {t(errorMessage)}
        </p>
      )}
    </div>
  );
}
