class AppRoutes {
  /**
   * The AppRoutes class defines the `getInstance` method that lets clients access
   * the unique AppRoutes instance.
   */
  private static instance: AppRoutes;
  /**
   * The AppRoutes's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  /**
   * The static method that controls the access to the singleton instance.
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): AppRoutes {
    if (!this.instance) {
      return (AppRoutes.instance = new AppRoutes());
    }
    return this.instance;
  }

  // paths
  getBasePath(): string {
    return '/';
  }

  getExamplePagePath(): string {
    return `/address/:addressId/view`;
  }

  getExamplePageUrl(): string {
    return this.getExamplePagePath();
  }

  // Example on a path with dynamic variables

  getExampleByIdPagePath(): string {
    return `/example/:id`;
  }

  getExampleByIdPageUrl(id: string): string {
    return this.getExampleByIdPagePath().replace(':id', id);
  }
  getMediaPagePath(): string {
    return '/media';
  }

  getTeamPagePath(): string {
    return '/team';
  }
  getTeamPageUrl(): string {
    return this.getTeamPagePath();
  }
  //
  getNewsPagePath(): string {
    return '/news';
  }
  getNewsPageUrl(): string {
    return this.getNewsPagePath();
  }
  //
  getNewsDetailsPagePath(): string {
    return this.getNewsPagePath() + '/:newsId';
  }
  getNewsDetailsPageUrl(newsId: string): string {
    return this.getNewsDetailsPagePath().replace(':newsId', newsId);
  }
  getStartUpPagePath(): string {
    return '/startup';
  }
  getStartUpPath(): string {
    return this.getStartUpPagePath() + '/:plusID';
  }
  getStartUpPageUrl(plusID: string): string {
    return this.getStartUpPath().replace(':plusID', plusID);
  }
  getAboutThegaragePagePath(): string {
    return '/about-us';
  }
  getAboutThegaragePageUrl(): string {
    return this.getAboutThegaragePagePath();
  }

  getHostedPagePath(): string {
    return '/hosted';
  }
  getHostedPageUrl(): string {
    return this.getHostedPagePath();
  }
  getCommonQuestionsPagePath(): string {
    return '/common-questions';
  }

  getCommonQuestionsPageUrl(): string {
    return this.getCommonQuestionsPagePath();
  }

  getQuestionsFormPagePath(): string {
    return '/ask-questions';
  }
  getQuestionsFormPageUrl(): string {
    return this.getQuestionsFormPagePath();
  }
  getBookTourFormPagePath(): string {
    return '/book-tour';
  }
  getBookTourFormPageUrl(): string {
    return this.getBookTourFormPagePath();
  }

  getBeOurPartnerFormPagePath(): string {
    return '/be-our-partner';
  }
  getBeOurPartnerFormPageUrl(): string {
    return this.getBeOurPartnerFormPagePath();
  }
  getBookVenueFormPagePath(): string {
    return '/book-Venue';
  }
  getBookVenueFormPageUrl(): string {
    return this.getBookVenueFormPagePath();
  }

  getMediaPageUrl(): string {
    return this.getMediaPagePath();
  }
  // ./Example

  //incubator
  getInternalProgramsPagePath(): string {
    return '/garage-internal-programs';
  }
  getInternalProgramsPageUrl(): string {
    return this.getInternalProgramsPagePath();
  }

  getIncubatorFormPagePath(): string {
    return '/garage-incubator-form';
  }
  getIncubatorFormPageUrl(): string {
    return this.getIncubatorFormPagePath();
  }
  getDistruptFormPath(): string {
    return '/garage-distrupt-form';
  }
  getDistruptFormPageUrl(): string {
    return this.getDistruptFormPath();
  }
  getPlusFormPagePath(): string {
    return '/garage-plus-form';
  }
  getPlusFormPageUrl(): string {
    return this.getPlusFormPagePath();
  }
  getPlusPagePath(): string {
    return '/plus';
  }
  getPlusPageUrl(): string {
    return this.getPlusPagePath();
  }
  getDisruptPagePath(): string {
    return '/the-garage-disrupt';
  }
  getDisruptPageUrl(): string {
    return this.getDisruptPagePath();
  }
  getIssuePagePath(): string {
    return '/Issue';
  }
  getIssuePageUrl(): string {
    return this.getIssuePagePath();
  }
  getDisruptHiddenPagePath(): string {
    return '/b2bea3a8-f66a-4cd0-8fb0-59e4ad1dbc27/disrupt';
  }
  getPermitHiddenPagePath(): string {
    return '/b2bea3a8-f66a-4cd0-8fb0-59e4ad1dbc27/permit';
  }
  getDisruptHiddenPageUrl(): string {
    return this.getDisruptHiddenPagePath();
  }
  getScanPermitPagePath(): string {
    return '/permit/scan/:permit_id';
  }

  getScanPermitPageUrl(permit_id: string): string {
    return this.getScanPermitPagePath().replace(':permit_id', permit_id);
  }

  getAcceleratedFormPagePath(): string {
    return '/pnu-g';
  }
  getAcceleratedFormPageUrl(): string {
    return this.getAcceleratedFormPagePath();
  }
  getLoginPageUrl(): string {
    return this.getLoginPagePath();
  }
  getLoginPagePath(): string {
    return '/login';
  }

  getForgotPasswordPageUrl(): string {
    return this.getForgotPasswordPagePath();
  }

  getForgotPasswordPagePath(): string {
    return '/forgot-password';
  }
  getResetPasswordPagePath(): string {
    return '/reset-password/:key';
  }

  getResetPasswordPageUrl(key: string): string {
    return this.getResetPasswordPagePath().replace(':key', encodeURIComponent(key));
  }

  getSignUpPageUrl(): string {
    return this.getSignUpPagePath();
  }

  getSignUpPagePath(): string {
    return '/sign-up';
  }
  getProfilePagePath(): string {
    return '/profile';
  }
  getChangePasswordPagePath(): string {
    return '/change-password';
  }
  getNewPasswordPagePath(): string {
    return '/new-password';
  }
  getOTPVerificationPagePath(): string {
    return '/otp-verification';
  }
  getCvInfoPagePath(): string {
    return '/CV';
  }
  getCvInfoPageUrl(): string {
    return this.getCvInfoPagePath();
  }
  //the garage plus application page
  getGaragePlusPath(): string {
    return '/the-garage-plus';
  }
  getGaragePlusPageUrl(): string {
    return this.getGaragePlusPath();
  }
  //the garage plus application form page
  getGaragePlusFormPath(): string {
    return '/the-garage-plus-form';
  }
  getGaragePlusFormPageUrl(): string {
    return this.getGaragePlusFormPath();
  }
}
export const appRoutesObj = AppRoutes.getInstance();
