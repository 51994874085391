import { makeRequest, baseURL } from 'src/core/data-access/http.service';
import { HttpMethods } from 'src/core/enums/httpMethods.enum';
import { CVType } from 'src/core/types/CV.type';

export const putCVService = async <T>(data: Partial<T>) => {
  return await makeRequest({
    url: `${baseURL}/user/profile/cv`,
    method: HttpMethods.PUT,
    data,
  });
};

export const getCVService = async (): Promise<CVType> => {
  return await makeRequest({
    url: `${baseURL}/user/profile/cv`,
    method: HttpMethods.GET,
  });
};
