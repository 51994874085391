import useModeStore from 'src/core/helpers/useModeStore';

interface cardComponent {
  isHover?: boolean;
  greenBorder?: boolean;
  children?: JSX.Element | JSX.Element[];
  icon?: JSX.Element | JSX.Element[];
  chiled?: boolean;
  className?: string;
}

function CardComponent({
  isHover = false,
  chiled = false,
  greenBorder,
  children,
  icon,
  ...props
}: cardComponent) {
  const { mode } = useModeStore();
  const isDarkMode = mode === 'dark';

  const getHover = () => {
    switch (isHover) {
      case true:
        return `gradient-border3 rounded-2xl border ${
          isDarkMode ? 'border-gray-600/50' : 'border-neutral/50'
        } `;
      default:
        return 'rounded-2xl border border-neutral-600/50';
    }
  };
  const getGreenBorder = () => {
    switch (greenBorder) {
      case true:
        return `gradient-border6 rounded-2xl  ${isDarkMode ? '' : 'border-neutral/50'} `;
      default:
        return '';
    }
  };
  const getchiled = () => {
    switch (chiled) {
      case true:
        return 'rounded-lg gradient-borderChild';
      default:
        return 'rounded-2xl';
    }
  };

  return (
    <div
      className={`rounded-2xl ${getHover()} ${getchiled()} ${getGreenBorder()}${props.className}`}
    >
      {children}
      <div className="flex items-center justify-center w-1 h-1 mx-auto">
        <div className="text-2xl">{icon}</div>
      </div>
    </div>
  );
}

export default CardComponent;
