export enum NewsTypesEnum {
  NEWS_LETTER = 'news_letter',
  NEWS_VIDEO = 'news_video',
}

export enum IdentityEnum {
  PASSPORT_NUMBER = 'PASSPORT_NUMBER',
  RESIDENCY_NUMBER = 'RESIDENCY_NUMBER',
  ID_NUMBER = 'ID_NUMBER',
}

export enum ResetStatusTypesEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum PlatformEnum {
  WEB = 'WEB',
}

export enum RoleEnum {
  INVESTOR = 'investor',
  FOUNDER = 'founder',
  ECOSYSTEM_ENABLER = 'ecosystemEnabler',
  GOVERNMENT = 'government',
  PRIVATE_SECTOR = 'privateSector',
  NON_PROFIT_SECTOR = 'non-profitSector',
}
