import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { IoMdClose } from 'react-icons/io';
import { appRoutesObj } from 'src/app.paths';
import PopupTapsComponent from 'src/components/common/pop-up-taps-components';
import i18n from 'src/core/configs/i18n';
import { opengraphMetaTags } from 'src/core/helpers/example.helper';

import NewsContainer from './news-container';
import VideoContainer from './video-container';

interface NewsPage {
  name: string;
  component: JSX.Element;
}

const newsPages: NewsPage[] = [
  {
    name: 'container.user.community.communityNews',
    component: <NewsContainer />,
  },
  {
    name: 'container.user.community.LatestEvents',
    component: <VideoContainer />,
  },
];
function NewsAndVideoContainer() {
  const { t } = useTranslation();
  const [selectedPage, setSelectedPage] = useState<JSX.Element>(newsPages[0].component);
  const handlePageChange = (component: JSX.Element) => {
    setSelectedPage(component);
  };
  const [index, setIndex] = useState<number>(0);

  return (
    <>
      <Helmet
        title={`${t('app.shared.theGarage')} | ${t('container.user.community.communityNews')}`}
        meta={[
          ...opengraphMetaTags({
            title: `${t('app.shared.theGarage')} | ${t('container.user.community.communityNews')}`,
            description: `${t('component.user.SidebarComponent.theGarageDescreption')}`,
            logo: '/assets/images/logo.png',
          }),
          {
            name: 'description',
            content: `${t('component.user.SidebarComponent.theGarageDescreption')}`,
          },
        ]}
      />
      <PopupTapsComponent
        index={index}
        link={`${appRoutesObj.getBasePath()}`}
        icon={<IoMdClose size={32} className="text-white" />}
        taps={
          <div className="flex gap-4 mobile:gap-0">
            {newsPages.map((items, index) => (
              <div
                key={index}
                onClick={() => {
                  handlePageChange(items.component);
                  setIndex(index);
                }}
                className={` ${
                  selectedPage === items.component
                    ? 'text-primary bg-gray-400/5 rounded-t-2xl mobile:backdrop-filter-none mobile:bg-gradient-to-b mobile:from-[rgba(120,113,108,0.1)] mobile:to-[rgba(120,113,108,0.1)]'
                    : 'text-white'
                }
            ${i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'}
            text-center text-base items-center py-1 px-4 w-[162px] h-10 cursor-pointer mobile:w-fit mobile:text-[13px]`}
              >
                {t(`${items.name}`)}
              </div>
            ))}
          </div>
        }
      >
        {selectedPage}
      </PopupTapsComponent>
    </>
  );
}

export default NewsAndVideoContainer;
