/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardPhotoComponent from 'src/components/common/card-photo-component';
import SelectComponent from 'src/components/common/ui/select-component';
import { GalleryEnum } from 'src/core/enums/example.enum';
import { SkeletonEnum } from 'src/core/enums/skeleton.enum';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { disruptCompnies } from 'src/core/types/disrupt.type';
import { ProgramsVersionsType } from 'src/core/types/user.type';
import { getDisruptByVersion } from 'src/services/disrupt-service';
import { getProgramsVersionsService } from 'src/services/programs-versions.service';

import StartUpSkeletonCard from './start-up-skeleton';

function StartupDisruptContainer() {
  const { t, i18n } = useTranslation();
  const paginationLimit = 100;
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<disruptCompnies>();
  const [programOptions, setProgramOptions] = useState<ProgramsVersionsType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getDisruptVersion = async () => {
    try {
      setLoading(true);
      const disrupt = await getProgramsVersionsService(
        i18n.language.toUpperCase(),
        GalleryEnum.DISRUPT.toUpperCase()
      );
      setProgramOptions(disrupt);
    } catch (error) {
      handleRequestError(error);
    } finally {
      setLoading(false);
    }
  };

  const getDisrupt = async (version: number) => {
    try {
      setLoading(true);
      const disrupt = await getDisruptByVersion(version, 0, paginationLimit);
      setData(disrupt);
    } catch (error) {
      handleRequestError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVersionChange = (selectedVersion: number) => {
    setPage(selectedVersion);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProgram = programOptions.find(
      (option) => `${t('app.shared.disrupt')} ${option.version}` === e.target.value
    );
    if (selectedProgram) {
      handleVersionChange(Number(selectedProgram.version));
    }
  };
  useEffect(() => {
    getDisruptVersion();
    getDisrupt(page);
  }, [page]);
  return (
    <div className="h-[545px] mobile:h-auto laptop:h-auto">
      <div className="flex justify-end w-full mobile:justify-center tablet:justify-center">
        <div className="w-1/3 px-4 pt-4 mobile:w-full tablet:w-full">
          <SelectComponent
            selectLabel="app.shared.select"
            options={programOptions.map(
              (item) => `${t('app.shared.disrupt')} ${item.version.toLocaleLowerCase()}`
            )}
            defaultValue={`${t('app.shared.disrupt')} ${programOptions[0]?.version || ''}`}
            name="disrupt"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              handleSelectChange(e);
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-7 gap-y-10 pt-6 mobile:grid-cols-3 mobile:mt-8 tablet:grid-cols-6 laptop:grid-cols-6 place-content-center place-items-center">
        {data?.result_list.map((val, index: number) =>
          loading ? (
            <StartUpSkeletonCard size={SkeletonEnum.SMALL} key={index} />
          ) : (
            <div key={index}>
              <CardPhotoComponent>
                <img
                  src={val.image}
                  alt={val.image}
                  className="w-20 rounded-lg h-16 laptop:w-[50px] largeLaptop:w-[45px] mobile:h-[94px] mobile:w-[97px] object-contain"
                />
              </CardPhotoComponent>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default StartupDisruptContainer;
