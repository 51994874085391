import * as Yup from 'yup';

export const resetPasswordValidationSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .required('form.validation.resetPassword.required')
      .min(8, 'form.validation.resetPassword.min.8')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[%@$!*?&])[A-Za-z\d%@$!*?&]{8,}$/,
        'form.validation.resetPassword.conditions'
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password')], 'form.error.resetConfirmPassword.matching')
      .required('form.validation.resetPassword.required'),
  });
