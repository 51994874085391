import { BookTourList } from 'src/core/types/tour.type';

export const bookTourList: BookTourList = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNo: '',
  hearAboutGarage: '',
  reasonToVisit: '',
  position: '',
  company: '',
};
