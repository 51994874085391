import { onlyLetters_Ar_En } from 'src/core/statics/regex';
import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object({
    image: Yup.string().nullable(),
    id_number: Yup.string()
      .nullable()
      .transform((value) => (value ? value.trim().replace(/[^A-Za-z0-9]/g, '') : null))
      .test('id-format', 'Please check theD/Passport Number     .', (value) => {
        if (!value) return true;
        return value.startsWith('1') || value.startsWith('2')
          ? /^\d{10}$/.test(value)
          : /^[A-Za-z0-9]{6,12}$/.test(value);
      })
      .optional(),
    first_name: Yup.string()
      .nullable()
      .matches(onlyLetters_Ar_En, 'First name cannot contain special characters.')
      .optional(),
    last_name: Yup.string()
      .nullable()
      .matches(onlyLetters_Ar_En, 'Last name cannot contain special characters.')
      .optional(),
    gender: Yup.string().nullable().optional(),
    marital_status: Yup.string().nullable().optional(),
    nationality: Yup.string().nullable().optional(),
    date_of_birthday: Yup.string()
      .nullable()
      .test('future-date', 'Date of birth cannot be in the future', (value) => {
        if (!value) return true;
        return new Date(value) <= new Date();
      })
      .optional(),
  });
