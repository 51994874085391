import { PermitType } from 'src/core/types/user.type';

export const initialValues: PermitType = {
  name: '',
  email: '',
  mobile: '',
  vehicle: '',
  nationality: '',
  equipment: '',
  quantity: undefined,
  description: '',
  notes: '',
  permit_date: '',
  reason: '',
  transfer_from: '',
  transfer_to: '',
  refer_name: '',
  refer_mobile: '',
};
