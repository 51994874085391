import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColorsEnum } from 'src/core/enums/ui.enums';
export interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  value?: string;
  checkboxSize?: boolean;
  disabled?: boolean;
  color?: ColorsEnum;
}
export function Checkbox({
  label = '',
  checkboxSize = false,
  color = ColorsEnum.Primary,
  ...props
}: ICheckboxProps) {
  const { t } = useTranslation();

  const getCheckboxSize = () => {
    switch (checkboxSize) {
      case true:
        return 'h-6 w-6';
      case false:
        return 'h-5 w-5';
      default:
        return 'h-6 w-6';
    }
  };

  return (
    <label htmlFor={props?.id} className="flex text-gray-500 items-center gap-x-2 w-fit">
      <div className="relative flex justify-center items-center">
        <input
          {...props}
          type="checkbox"
          name={props.name}
          value={props.value}
          className={`appearance-none peer justify-center items-baseline rounded bg-transparent checked:bg-${color} border border-gray-500 checked:border-0 ${getCheckboxSize()} ${
            props.disabled === true &&
            'disabled:bg-transparent disabled:checked:border disabled:border-gray-500/50'
          }`}
        />
        <svg
          className="absolute inset-0 w-5 h-5 text-white hidden peer-checked:block pointer-events-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </div>
      {t(label)}
    </label>
  );
}
export default Checkbox;
