export const getDemoDayInNumbersData = [
  {
    label: 'container.GaragePlusContainer.demoDayInNumbers.label1',
    Number: '30',
  },
  {
    label: 'container.GaragePlusContainer.demoDayInNumbers.label2',
    Number: '17',
  },
  {
    label: 'container.GaragePlusContainer.demoDayInNumbers.label3',
    Number: '11',
  },
  {
    label: 'container.GaragePlusContainer.demoDayInNumbers.label4',
    Number: '6',
  },
];
