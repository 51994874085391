import * as Yup from 'yup';

export const loginValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .required('form.validation.email.required')
      .email('form.validation.email.valid.format'),
    password: Yup.string().trim().required('form.validation.password.required'),
  });
