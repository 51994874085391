import EventDetails from 'src/components/user/event-details-components';
import PageSelect from 'src/components/user/page-select';

import RegistrationForm from './registration-form/registration-form';

function GaragePlusForm() {
  return (
    <div className="h-[550px] laptop:h-auto overflow-y-auto">
      <EventDetails
        imgSrc="/assets/images/joining-garage-demo-day.svg"
        imgAlt="logo"
        dateKey="component.plusTimeAndDateComponent.date"
        timeKey="component.plusTimeAndDateComponent.time"
        locationKey="component.plusTimeAndDateComponent.location"
      />
      <PageSelect label1="container.registrationForm.formTitle" page1={<RegistrationForm />} />
    </div>
  );
}

export default GaragePlusForm;
