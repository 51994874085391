import { useState, useEffect } from 'react';
import useProfileStore from 'src/core/stores/setUserData';
import { LanguageType } from 'src/core/types/CV.type';

export const useLanguageData = (selectedId?: string) => {
  const [languageData, setLanguageData] = useState<LanguageType>();
  const { data } = useProfileStore();

  useEffect(() => {
    if (!selectedId) {
      setLanguageData(undefined);
      return;
    }
    const selected = data?.language?.find((edu) => edu.language === selectedId);
    setLanguageData(selected);
  }, [selectedId, data]);

  return { languageData, setLanguageData };
};
