import { useState, useCallback, useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { appRoutesObj } from 'src/app.paths';
import { Layout } from 'src/components/common/layout-component/layout-component';
import Loading from 'src/components/common/Loading/loading';
import PopupCard from 'src/components/common/PopupCard';
import RegistrationCloseComponents from 'src/components/user/registration-close-component';
import GaragePlusForm from 'src/containers/user/garage-plus-container/garage-plus-form';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { opengraphMetaTags } from 'src/core/helpers/example.helper';

function GaragePlusFormPage() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pageTitle = `${t('app.shared.theGarage')} | ${t('container.GaragePlusContainer.plusOverView')}`;
  const pageDescription = t('component.user.SidebarComponent.theGarageDescreption');

  const checkGaragePlusStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      setIsOpen(true);
    } catch (error) {
      handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    checkGaragePlusStatus();
  }, [checkGaragePlusStatus]);

  return (
    <Layout>
      {isLoading ? (
        <Loading />
      ) : isOpen ? (
        <>
          <Helmet
            title={pageTitle}
            meta={[
              ...opengraphMetaTags({
                title: pageTitle,
                description: pageDescription,
                logo: '/assets/images/logo.png',
              }),
              {
                name: 'description',
                content: pageDescription,
              },
            ]}
          />
          <PopupCard
            link={appRoutesObj.getGaragePlusPageUrl()}
            icon={
              i18n.language === 'en' ? (
                <AiOutlineArrowRight size={32} className="text-white" />
              ) : (
                <AiOutlineArrowLeft size={32} className="text-white" />
              )
            }
          >
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}
            >
              <GaragePlusForm />
            </GoogleReCaptchaProvider>
          </PopupCard>
        </>
      ) : (
        <RegistrationCloseComponents link={appRoutesObj.getGaragePlusPageUrl()} />
      )}
    </Layout>
  );
}

export default GaragePlusFormPage;
