import React from 'react';
import { Layout } from 'src/components/common/layout-component/layout-component';
import OTPForm from 'src/containers/user/profile-container/OTP-form';
function OTPVerificationPage() {
  return (
    <Layout sidebar={false}>
      <OTPForm />
    </Layout>
  );
}

export default OTPVerificationPage;
