import React from 'react';
import { Button } from 'src/components/common/ui/Button';
import GridView from 'src/components/common/ui/grid-view';
import CVCardComponent from 'src/components/user/V2/CV-card-component';
import { SizesEnum } from 'src/core/enums/ui.enums';
import { VariantsEnum } from 'src/core/enums/ui.enums';
import { handleRequestError } from 'src/core/helpers/error.helper';
import useProfileStore from 'src/core/stores/setUserData';
import { getCVService, putCVService } from 'src/services/CV.service';

interface Props {
  onAdd: () => void;
}

export default function LanguageView({ onAdd }: Props) {
  const { data, setUserData } = useProfileStore();

  const handleDelete = async (index: number) => {
    try {
      const updatedData = data?.language?.filter((_, i) => i !== index);
      await putCVService({
        ...data,
        language: updatedData,
      });
      const response = await getCVService();
      setUserData(response);
    } catch (error) {
      handleRequestError(error);
    }
  };

  return (
    <div className="flex flex-col gap-4 h-full w-full">
      <div className="grid order-first justify-end items-start w-full mobile:flex tablet:flex">
        {/* content tranlsation missing from BA*/}
        <Button variant={VariantsEnum.Filled} size={SizesEnum.Small} onClick={() => onAdd()}>
          Add language
        </Button>
      </div>

      <GridView column="2">
        {data?.language?.map((item, index) => (
          <CVCardComponent
            key={item.id || index}
            title={item.language || ''}
            title1={item.proficiency || ''}
            title2={''}
            onDelete={() => handleDelete(index)}
          />
        ))}
      </GridView>
    </div>
  );
}
