import { Layout } from 'src/components/common/layout-component/layout-component';
import NewsAndVideoContainer from 'src/containers/user/news-container/news-and-video-container';

function NewsPage() {
  return (
    <Layout>
      <NewsAndVideoContainer />
    </Layout>
  );
}

export default NewsPage;
