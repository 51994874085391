import { useTranslation } from 'react-i18next';
import { BiCalendar, BiTimeFive } from 'react-icons/bi';
import { DisruputTime } from 'src/core/types/disrupt.type';

import CardBorderGreenComponent from './card-border-green-component';

function DisruptTimeAndDateComponent({
  disruptTime,
  date,
  time,
}: {
  disruptTime?: DisruputTime;
  date?: string;
  time?: string;
}) {
  const { i18n } = useTranslation();
  const dateObj = new Date(disruptTime?.start_at || '');
  const isValidDate = !isNaN(dateObj.getTime());
  const formattedDate =
    date ||
    (isValidDate
      ? dateObj.toLocaleString(i18n.language === 'ar' ? 'ar-EG' : 'en-GB', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        })
      : '01-01-2024');

  const formattedTime =
    time ||
    (isValidDate
      ? dateObj
          .toLocaleString(i18n.language === 'ar' ? 'ar-EG' : 'en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          })
          .toUpperCase()
      : '00:00 AM');

  return (
    <div className="flex flex-col justify-center items-start gap-3">
      <CardBorderGreenComponent title={formattedDate || ''} icon={BiCalendar} />
      <CardBorderGreenComponent title={formattedTime || ''} icon={BiTimeFive} />
    </div>
  );
}

export default DisruptTimeAndDateComponent;
