import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { opengraphMetaTags } from './core/helpers/example.helper';
import LandingPage from './pages/user/LandingPage/landing-page';
// import MobilePage from "./pages/user/Mobile-page";

function App() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet
        title={t('app.shared.theGarage') as string}
        meta={[
          ...opengraphMetaTags({
            title: `${t('app.shared.theGarage')}`,
            description: `${t('component.user.SidebarComponent.theGarageDescreption')}`,
            logo: '/assets/images/logo.png',
          }),
          {
            name: 'description',
            content: `${t('component.user.SidebarComponent.theGarageDescreption')}`,
          },
        ]}
      />
      <div className="w-full h-full">
        <LandingPage />
      </div>
    </>
  );
}

export default App;
