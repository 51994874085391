import React, { ReactElement } from 'react';

interface LoginCardProps {
  children: ReactElement | JSX.Element;
}

function LoginCardComponent({ children }: LoginCardProps) {
  return (
    <div className="h-[481px] w-[594px] mobile:py-12 tablet:py-12 rounded-2xl border-[1.8px] border-gray-600 border-opacity-50 bg-opacity-50 bg-neutral-800 border-neutral/50 mobile:h-auto mobile:w-auto">
      {children}
    </div>
  );
}

export default LoginCardComponent;
