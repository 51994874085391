import { makeRequest, baseURL } from 'src/core/data-access/http.service';
import { HttpMethods } from 'src/core/enums/httpMethods.enum';

export const postIssuesServices = async (type: string, data: object, reCaptchaToken: string) => {
  return await makeRequest({
    url: `${baseURL}/issue/${type}`,
    method: HttpMethods.POST,
    data,
    headers: { 'recaptcha-token': reCaptchaToken },
  });
};
