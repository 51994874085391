import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit3 } from 'react-icons/fi';

export default function ProfileEditComponent({
  title,
  isEdit,
  onEditClick,
}: {
  title?: string;
  isEdit: boolean;
  onEditClick: () => void;
}) {
  const { t, i18n } = useTranslation();

  return (
    <div className={`flex flex-row items-center  ${title ? 'justify-between' : 'justify-end'}`}>
      {title && (
        <div
          className={`text-base text-white ${i18n.language === 'en' ? 'font-helvetica text-base' : 'font-arMyriad text-lg'}`}
        >
          {t(title)}
        </div>
      )}
      {!isEdit && (
        <div
          onClick={onEditClick}
          className={`flex flex-col items-center justify-center w-12 h-10 border border-gray-600 border-opacity-50 bg-neutral bg-opacity-10 rounded-lg text-base text-primary cursor-pointer`}
        >
          <FiEdit3 size={18} />
        </div>
      )}
    </div>
  );
}
