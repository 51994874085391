import { useState } from 'react';
import EmptyStateCardComponent from 'src/components/user/V2/empty-state-card';
import useProfileStore from 'src/core/stores/setUserData';

import CertificateForm from './CertificateForm';
import TrainingForm from './TrainingForm';
import TrainingAndCertificationView from './traning-cerification-view';

export default function TrainingCerificationContainer() {
  const { data } = useProfileStore();
  const [isCertificate, setIsCertificate] = useState(false);
  const [isTraining, setIsTraining] = useState(false);
  const [selectedId, setSelectedId] = useState<string>();

  const allData = [...(data.training || []), ...(data.certificate || [])];

  const handleEdit = (id: string) => {
    setSelectedId(id);
    if (data.certificate?.some((item) => item.id === id)) {
      setIsCertificate(true);
      setIsTraining(false);
    } else {
      setIsTraining(true);
      setIsCertificate(false);
    }
  };

  const handleAddTraining = () => {
    setSelectedId(undefined);
    setIsTraining(true);
  };

  const handleAddCertificate = () => {
    setSelectedId(undefined);
    setIsCertificate(true);
  };

  return (
    <div>
      {allData.length === 0 && !isTraining && !isCertificate ? (
        <EmptyStateCardComponent
          title="container.trainingCerificationContainer.noTrainingAndCertification"
          buttonLabel="Add training"
          buttonOnClick={handleAddTraining}
          button1Label="Add certification"
          button1OnClick={handleAddCertificate}
        />
      ) : isCertificate ? (
        <CertificateForm setIsCertificate={setIsCertificate} selectedId={selectedId} />
      ) : isTraining ? (
        <TrainingForm setIsTraining={setIsTraining} selectedId={selectedId} />
      ) : (
        <TrainingAndCertificationView
          onEdit={handleEdit}
          onAddTraining={handleAddTraining}
          onAddCertificate={handleAddCertificate}
        />
      )}
    </div>
  );
}
