import { useTranslation } from 'react-i18next';
import { BiCheck } from 'react-icons/bi';
interface PasswordCheckComponentProps {
  value: string;
  text: string;
  rxp: string;
}

function PasswordCheckComponent(prop: PasswordCheckComponentProps) {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex">
      <p
        className={`${
          prop.value.match(prop.rxp)
            ? 'text-green-500 text-xs font-normal leading-4'
            : 'text-red-500 text-xs	font-normal leading-4'
        } ${i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad px-3'}`}
      >
        {t(prop.text)}
      </p>
      {prop.value.match(prop.rxp) ? <BiCheck className="text-green-500" size={18} /> : ''}
    </div>
  );
}

export default PasswordCheckComponent;
