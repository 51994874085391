import SharedSectionComponent from 'src/components/shard-Section';

import BookingContainer from './landing/Booking-container';

function Booking() {
  return (
    <div className="tablet:px-24 mobile:pt-2 tablet:pt-3">
      <SharedSectionComponent
        component={<BookingContainer />}
        title={'container.user.booking.bookintTitle'}
      />
    </div>
  );
}

export default Booking;
