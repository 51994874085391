import { baseURL, makeRequest } from 'src/core/data-access/http.service';
import { HttpMethods } from 'src/core/enums/httpMethods.enum';

export const plusFormApplication = async (data: object, reCaptchaToken: string) => {
  return await makeRequest({
    url: `${baseURL}/plus/application`,
    method: HttpMethods.POST,
    data,
    headers: { 'recaptcha-token': reCaptchaToken },
  });
};

export const plusStatus = async () => {
  return await makeRequest({
    url: `${baseURL}/plus/status`,
    method: HttpMethods.GET,
  });
};

export const getPlusCompanyById = async (plusID: string) => {
  return await makeRequest({
    url: `${baseURL}/plus/${plusID}`,
    method: HttpMethods.GET,
  });
};

export const getPlusCompanies = async (plus_id: number, limit: number, offset: number) => {
  return await makeRequest({
    url: `${baseURL}/plus/company/${plus_id}`,
    method: HttpMethods.GET,
    params: {
      offset: offset,
      limit: limit,
    },
  });
};
export const getVersion = async (language: string, type: string) => {
  return await makeRequest({
    url: `${baseURL}/programs-versions`,
    method: HttpMethods.GET,
    params: {
      language: language,
      type: type,
    },
  });
};
