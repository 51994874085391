import { useState, useEffect } from 'react';
import { handleRequestError } from 'src/core/helpers/error.helper';
import useProfileStore from 'src/core/stores/setUserData';
import { CertificationType } from 'src/core/types/CV.type';
import { putCVService, getCVService } from 'src/services/CV.service';

export const useCertificationsData = (selectedId?: string) => {
  const [certificationData, setCertificationData] = useState<CertificationType>();
  const { data, setUserData } = useProfileStore();

  useEffect(() => {
    if (!selectedId) {
      setCertificationData(undefined);
      return;
    }
    const selected = data?.certificate?.find((cert) => cert.id === selectedId);
    setCertificationData(selected);
  }, [selectedId, data]);

  const updateCertification = async (values: Partial<CertificationType>) => {
    try {
      // check if the values are the same as the original data when editing
      if (selectedId) {
        const original = data.certificate?.find((cert) => cert.id === selectedId);
        const isUnchanged =
          original &&
          original.name === values.name &&
          original.issued_in === (values.issued_in?.length ? values.issued_in : null) &&
          original.valid_until === (values.valid_until?.length ? values.valid_until : null) &&
          original.no_expired_date === values.no_expired_date;

        if (isUnchanged) {
          return true;
        }
      }

      const newCertificate = {
        name: values.name?.length ? values.name : '',
        issued_in: values.issued_in?.length ? values.issued_in : null,
        valid_until: values.valid_until?.length ? values.valid_until : null,
        no_expired_date: values.no_expired_date ?? false,
      };

      const updatedCertificates = selectedId
        ? data.certificate?.map((cert) =>
            cert.id === selectedId ? { ...cert, ...newCertificate } : cert
          ) || []
        : [...(data?.certificate || []), { ...newCertificate }];

      await putCVService({
        ...data,
        certificate: updatedCertificates,
      });
      const response = await getCVService();
      setUserData(response);
      setCertificationData(response);
      handleRequestError.success('container.CV.confirmation.saved');
      return true;
    } catch (error) {
      handleRequestError(error);
      return false;
    }
  };

  return { certificationData, updateCertification };
};
