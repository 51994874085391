import Lottie from 'lottie-react';

import loading from './loading.json';

function Loading() {
  return (
    <div className="h-full flex flex-col mx-auto justify-center items-center text-center">
      <div className="h-12 w-12">
        <Lottie animationData={loading} loop={true} />
      </div>
      <p className="text-xl text-primary font-helveticaMedium">Loading</p>
    </div>
  );
}

export default Loading;
