import { t } from 'i18next';
import { toast } from 'react-toastify';
import { ErrorDetailType, ErrorType } from 'src/core/types/error.type';

/**
 *
 * @param error
 * @description handles all requests catch errors
 */
export const handleRequestError = (error: unknown) => {
  toast.dismiss();
  toast(getErrorMessage((error as ErrorType)?.response?.data?.detail));
};

handleRequestError.message = (message: string) => {
  toast.dismiss();
  toast(t(message));
};

handleRequestError.success = (message: string) => {
  toast.dismiss();
  toast.success(t(message), { icon: false });
};
/**
 * @param error
 * @description
 * @returns error string
 */
export const getErrorMessage = (error: string | ErrorDetailType) => {
  if (!error) {
    return `${t('form.messages.error')}`;
  }

  if (typeof error === 'string') {
    return error;
  }

  if ((error as ErrorDetailType)?.error_code) {
    if ((error as ErrorDetailType)?.error_code == 'common_2') {
      return `${t('form.messages.error.common_2')}`;
    } else if ((error as ErrorDetailType)?.error_code == 'common_3') {
      return `${t('form.messages.error.common_3')}`;
    } else if ((error as ErrorDetailType)?.error_code == 'common_5') {
      return `${t('form.messages.error.common_5')}`;
    } else if ((error as ErrorDetailType)?.error_code == 'common_14') {
      return `${t('form.messages.error.common_14')}`;
    } else if ((error as ErrorDetailType)?.error_code == 'common_15') {
      return `${t('form.messages.error.common_15')}`;
    } else if ((error as ErrorDetailType)?.error_code == 'user-7') {
      return `${t('form.messages.error.user-7')}`;
    }
    return (error as ErrorDetailType)?.error_message;
  }

  return `${t('form.messages.error')}`;
};

export const getErrorMessage2 = (error: string | ErrorDetailType, t: (key: string) => string) => {
  if (!error) {
    return t('form.messages.error');
  }

  if (typeof error === 'string') {
    return error;
  }

  if ((error as ErrorDetailType)?.error_code) {
    const code: string = (error as ErrorDetailType)?.error_code;

    return t(`${code}`);
  }

  return t('form.messages.error');
};
