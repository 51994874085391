import { baseURL, makeRequest } from 'src/core/data-access/http.service';
import { HttpMethods } from 'src/core/enums/httpMethods.enum';

export const getGalleryService = async (gallery_type: string, limit: number, offset: number) => {
  const type = gallery_type ? { gallery_type: gallery_type } : {};

  return makeRequest({
    url: `${baseURL}/gallery`,
    method: HttpMethods.GET,
    params: { limit: limit, offset: offset, ...type },
  });
};
