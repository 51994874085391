import i18n from 'src/core/configs/i18n';

interface CarouselCard {
  CompanyName: string;
  CompanyDescription: string;
  imgSrc: string;
}
function CarouselCard({ CompanyName, CompanyDescription, imgSrc }: CarouselCard) {
  return (
    <div
      className={`flex flex-col gap-y-3 h-fit lg:gap-y-0 lg:grid lg:gap-x-12 xl:gap-x-1 ${
        i18n.language === 'ar' ? 'lg:-translate-x-6' : 'lg:translate-x-6'
      } lg:grid-cols-5`}
    >
      {/* Image ! */}
      <div
        className={`flex self-center  xl:w-[90%] laptop:h-28 w-full lg:col-span-2 lg:row-span-3 justify-center rounded-xl bg-white lg:items-center`}
      >
        <img
          className={`h-36 laptop:h-full w-[50%] object-contain lg:w-24`}
          src={imgSrc}
          alt={CompanyName}
        />
      </div>
      <p
        className={`${
          i18n.language === 'ar' ? 'font-arMyriad text-xl' : 'font-helveticaMedium text-base'
        }`}
      >
        {CompanyName}
      </p>

      <div className={'w-full lg:w-[80%] lg:col-span-3 lg:row-span-2'}>
        <p
          className={`text-neutral-400 line-clamp-3 text-justify laptop:line-clamp-2  ${
            i18n.language === 'ar' ? 'font-arMyriad text-xl' : 'font-helveticaMedium text-base'
          }`}
        >
          {CompanyDescription}
        </p>
      </div>
    </div>
  );
}

export default CarouselCard;
