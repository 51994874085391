import React from 'react';
import { Layout } from 'src/components/common/layout-component/layout-component';
import ChangePasswordContainer from 'src/containers/user/profile-container/change-password-container';

export default function ChangePasswordPage() {
  return (
    <Layout sidebar={false}>
      <ChangePasswordContainer />
    </Layout>
  );
}
