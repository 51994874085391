import { useCallback, useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { appRoutesObj } from 'src/app.paths';
import { Layout } from 'src/components/common/layout-component/layout-component';
import Loading from 'src/components/common/Loading/loading';
import PopupCard from 'src/components/common/PopupCard';
import RegistrationCloseComponents from 'src/components/user/registration-close-component';
import BookVenueFormContainer from 'src/containers/user/book-venue-container/book-venue-form-container';
import { handleRequestError } from 'src/core/helpers/error.helper';
import { getEventsBookStatusService } from 'src/services/events-book.service';

function BookVenueFormPage() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const BookVenueStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await getEventsBookStatusService();
      setIsOpen(result.is_open);
      setIsLoading(false);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);

  useEffect(() => {
    BookVenueStatus();
  }, [BookVenueStatus]);

  return (
    <Layout>
      {isLoading ? (
        <Loading />
      ) : isOpen === true ? (
        <PopupCard
          icon={
            <IoMdClose
              size={32}
              className="text-white"
              onClick={() => {
                navigate(-1);
              }}
            />
          }
        >
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}>
            <BookVenueFormContainer />
          </GoogleReCaptchaProvider>
        </PopupCard>
      ) : (
        <RegistrationCloseComponents link={appRoutesObj.getBasePath()} />
      )}
    </Layout>
  );
}

export default BookVenueFormPage;
