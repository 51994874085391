import { baseURL, makeRequest } from 'src/core/data-access/http.service';
import { HttpMethods } from 'src/core/enums/httpMethods.enum';

export const bookTourApplication = async (data: object, reCaptchaToken: string) => {
  return await makeRequest({
    url: `${baseURL}/book-tour`,
    method: HttpMethods.POST,
    data,
    headers: { 'recaptcha-token': reCaptchaToken },
  });
};

export const bookTourStatus = async () => {
  return await makeRequest({
    url: `${baseURL}/book-tour/status`,
    method: HttpMethods.GET,
  });
};
