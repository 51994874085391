import { Navigate } from 'react-router-dom';

import { appRoutesObj } from '../../../app.paths';
import RefreshTokenUpdateWrapperContainer from '../refresh-token-update-wrapper-container/refresh-token-update-wrapper-container';

interface AuthAccessWrapperContainerProps {
  children: JSX.Element;
}

const AuthAccessWrapperContainer = ({ children }: AuthAccessWrapperContainerProps) => {
  const token = localStorage.getItem('token') as string;

  if (!token) {
    return <Navigate to={appRoutesObj.getLoginPagePath()} />;
  }

  return <RefreshTokenUpdateWrapperContainer>{children}</RefreshTokenUpdateWrapperContainer>;
};

export default AuthAccessWrapperContainer;
