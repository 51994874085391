import { baseURL, makeRequest } from 'src/core/data-access/http.service';
import { HttpMethods } from 'src/core/enums/httpMethods.enum';
import { PlatformEnum } from 'src/core/enums/service.enums';
import { EmailType, loginFormType, ResetPasswordType } from 'src/core/types/user.login.type';

export const postLoginService = async (data: loginFormType, reCaptchaToken: string) => {
  return makeRequest({
    url: `${baseURL}/user/login`,
    method: HttpMethods.POST,
    data,

    headers: { 'recaptcha-token': reCaptchaToken },
  }).then((res) => {
    const token = res['access_token'];
    localStorage.setItem('token', token);
    const refreshToken = res['refresh_token'];
    localStorage.setItem('refreshToken', refreshToken);
  });
};

export const refreshTokenService = async (refreshToken: string) => {
  return await makeRequest({
    url: `${baseURL}/user/refresh-token`,
    method: HttpMethods.POST,
    data: { refresh_token: refreshToken },
  });
};

export const postSendEmailResetPasswordService = async (data: EmailType) => {
  return await makeRequest({
    url: `${baseURL}/user/reset/${PlatformEnum.WEB}`,
    method: HttpMethods.POST,
    data,
  });
};

export const getResetPasswordStatusService = async (key: string) => {
  return await makeRequest({
    url: `${baseURL}/user/reset/${key}`,
    method: HttpMethods.GET,
  });
};

export const putResetPasswordService = async (key: string, data: ResetPasswordType) => {
  return await makeRequest({
    url: `${baseURL}/user/reset/${key}`,
    method: HttpMethods.PUT,
    data,
    params: {
      key: key,
    },
  });
};
