import { onlyLetters_Ar_En, onlyNumbers_En } from 'src/core/statics/regex';
import * as Yup from 'yup';
export const validationSchema = (startupProfileCopy: HTMLInputElement) =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(onlyLetters_Ar_En, 'form.validation.first.name.no.numbers')
      .required('form.validation.first.name.required')
      .min(2, 'form.validation.first.name.min.2')
      .max(20, 'form.validation.first.name.max.20'),
    lastName: Yup.string()
      .trim()
      .matches(onlyLetters_Ar_En, 'form.validation.last.name.no.numbers')
      .required('form.validation.last.name.required'),
    email: Yup.string()
      .trim()
      .required('form.validation.email.required')
      .max(50, 'form.validation.email.max.50')
      .email('form.validation.email.valid.format'),
    phoneNo: Yup.string()
      .trim()
      .required('form.validation.mobile.required')
      .matches(onlyNumbers_En, 'form.validation.mobile.no.numbers')
      .min(9, 'form.validation.mobile.min.9')
      .max(15, 'form.validation.mobile.max.15'),

    gender: Yup.string().trim().required('form.validation.gender.required'),

    nationality: Yup.string().trim().required('form.validation.nationlity.required'),
    idType: Yup.string().trim().required('form.validation.idType.required'),
    idNumber: Yup.string()
      .trim()
      .required('form.validation.idnum.required')
      .when('idType', {
        is: 'app.shared.Passport',
        then: Yup.string()
          .required('form.validation.passport.required')
          .min(6, 'form.validation.passport.min.6')
          .max(20, 'form.validation.passport.max.20'),
      })
      .when('idType', {
        is: 'app.shared.iqamaNo',
        then: Yup.string()
          .matches(onlyNumbers_En, 'form.validation.iqama.numbers.only')
          .required('form.validation.iqama.required')
          .min(10, 'form.validation.iqama.match.10')
          .max(10, 'form.validation.iqama.match.10'),
      })
      .when('idType', {
        is: 'app.shared.idNo',

        then: Yup.string()
          .matches(onlyNumbers_En, 'form.validation.idnum.numbers.only')
          .required('form.validation.idnum.required')
          .min(10, 'form.validation.idnum.min.10')
          .max(10, 'form.validation.idnum.min.10'),
      }),

    startupName: Yup.string()
      .trim()
      .required('form.validation.company.name.required')
      .max(50, 'form.validation.company.name.max.50'),
    yearOfEstablishment: Yup.number().required('form.validation.year.of.establishment.required'),

    startupSize: Yup.string().required('form.validation.startup.size.required'),
    country: Yup.string().required('form.validation.Country.required'),
    startupMajor: Yup.string().required('form.validation.industry.required'),
    website: Yup.string()
      .trim()
      .url('form.validate.website.format')
      .required('form.validate.website.required'),

    aboutStartup: Yup.string()
      .required('form.validation.about.company.required')
      .max(500, 'form.validation.about.company.max.500'),

    startupProfile: Yup.mixed()
      .required('form.validation.company.file.required')
      .test(
        'fileSize',
        'form.validation.company.file.size',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (value) => value && startupProfileCopy.files![0].size < 2000000
      ),
    hearAboutGarage: Yup.string().required('form.validation.hearAboutTheGarage.required'),
    reasonToJoin: Yup.string()
      .max(350, 'form.validate.JoinTheGarage.max.350')
      .required('form.validate.JoinTheGarage.required'),

    businessModel: Yup.string().required('form.validate.business.model.required'),
    numberOfCustomers: Yup.string()
      .matches(onlyNumbers_En, 'form.validate.number.of.customer.numbers.only')
      .required('form.validate.number.of.customer.required'),

    howManyFullTimeEmployees: Yup.string()
      .matches(onlyNumbers_En, 'form.validate.number.of.employees.numbers.only')
      .required('form.validate.number.of.employees.required'),
    stageYourProduct: Yup.string().required('form.validate.product.stage.required'),
    startupCurrentValuation: Yup.string().required('form.validate.company.Valuation.required'),
    lastFundraisingRound: Yup.string().required('form.validate.company.Valuation.required'),

    startup_location: Yup.string().required('form.validate.company.location.required'),
  });
