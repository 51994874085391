import { IoMdClose } from 'react-icons/io';
import { appRoutesObj } from 'src/app.paths';
import { Layout } from 'src/components/common/layout-component/layout-component';
import PopupCard from 'src/components/common/PopupCard';
import DisruptContainer from 'src/containers/user/disrupt-container/disrupt-container';

function DisruptPage() {
  return (
    <Layout>
      <PopupCard
        link={`${appRoutesObj.getBasePath()}`}
        icon={<IoMdClose size={32} className="text-white" />}
      >
        <DisruptContainer />
      </PopupCard>
    </Layout>
  );
}

export default DisruptPage;
