import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Checkbox from 'src/components/common/ui/Checkbox';
import DateField from 'src/components/common/ui/DateField2';
import GridView from 'src/components/common/ui/grid-view';
import InputField from 'src/components/common/ui/InputField';
import SaveCancelButton from 'src/components/user/V2/save-cancel-button';
import { CertificationType } from 'src/core/types/CV.type';

import { cvInitialValues } from '../initialValues';
import { useCertificationsData } from './useCertifcationData';
import { certificateSchema } from '../validation.schema';

export default function CertificateForm({
  setIsCertificate,
  selectedId,
}: {
  setIsCertificate: (value: boolean) => void;
  selectedId?: string;
}) {
  const { certificationData, updateCertification } = useCertificationsData(selectedId);
  const [noExpiredDate, setNoExpiredDate] = useState(certificationData?.no_expired_date ?? false);

  const handleSubmit = async (values: Partial<CertificationType>) => {
    const success = await updateCertification(values);
    if (success) {
      setIsCertificate(false);
    }
  };

  useEffect(() => {
    setNoExpiredDate(certificationData?.no_expired_date ?? false);
  }, [certificationData]);

  return (
    <Formik
      validationSchema={certificateSchema}
      initialValues={
        certificationData ??
        ({
          ...cvInitialValues.certificate?.[0],
          no_expired_date: false,
        } as Partial<CertificationType>)
      }
      enableReinitialize
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, errors, touched, setFieldValue }) => {
        return (
          <Form className="flex flex-col gap-y-4 px-7 mobile:px-0 h-[50vh] py-5 justify-between">
            <GridView column="1">
              <InputField
                label="Name of Certificate"
                name="name"
                isValid={touched.name && !errors.name}
                value={values.name}
                onChange={handleChange}
                error={!!errors.name}
                errorMsg={errors.name}
              />
              <DateField
                label="container.certificateForm.dateOfCertificate"
                name="issued_in"
                value={values.issued_in ?? ''}
                isValid={touched.issued_in && !errors.issued_in}
                onChange={(e) => setFieldValue('issued_in', e.target.value)}
                acceptPastDate
                acceptFutureDate
                error={!!errors.issued_in}
                errorMsg={errors.issued_in}
              />
              {!noExpiredDate && (
                <DateField
                  label="container.certificateForm.dateOfCertificate"
                  name="valid_until"
                  value={values.valid_until ?? ''}
                  isValid={touched.valid_until && !errors.valid_until}
                  onChange={(e) => setFieldValue('valid_until', e.target.value)}
                  acceptPastDate
                  acceptFutureDate
                  errorMsg={touched.valid_until ? errors.valid_until : ''}
                />
              )}
              <Checkbox
                label="container.CV.Certificate.no.expiration"
                name="no_expired_date"
                checked={noExpiredDate}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setNoExpiredDate(isChecked);
                  setFieldValue('no_expired_date', isChecked);
                  if (isChecked) {
                    setFieldValue('valid_until', null);
                  }
                }}
              />
            </GridView>
            <SaveCancelButton onCancel={() => setIsCertificate(false)} />
          </Form>
        );
      }}
    </Formik>
  );
}
