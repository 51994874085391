import i18n from 'src/core/configs/i18n';

import NavbarButtonComponent from '../Navbar-component/navbar-button-component';

function ChangeLanguage() {
  const changeLanguage = () => {
    if (i18n.language === 'ar') {
      i18n.changeLanguage('en');
      localStorage.setItem('language', 'en');
    } else {
      i18n.changeLanguage('ar');
      localStorage.setItem('language', 'ar');
    }
  };

  return (
    <>
      <NavbarButtonComponent
        onClick={changeLanguage}
        iconOrText={i18n.language === 'ar' ? 'EN' : 'AR'}
        color={'primary'}
      />
    </>
  );
}
export default ChangeLanguage;
