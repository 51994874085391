import { Layout } from 'src/components/common/layout-component/layout-component';
import CVInfoContainer from 'src/containers/user/CV/cv-info-container';

function CVPage() {
  return (
    <Layout sidebar={false}>
      <CVInfoContainer />
    </Layout>
  );
}

export default CVPage;
