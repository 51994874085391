import { baseURL, makeRequest } from './http.service';
import { HttpMethods } from '../enums/httpMethods.enum';

export const refreshTokenService = async (refreshToken: string) => {
  return await makeRequest({
    url: `${baseURL}/user/refresh-token`,
    method: HttpMethods.POST,
    data: { refresh_token: refreshToken },
  });
};
