import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useModeStore from 'src/core/helpers/useModeStore';
import { reservationItems } from 'src/core/statics/booking.static';

import CardComponent from './card-component';

export default function ReservationMobileComponent() {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === 'dark';

  return (
    <div>
      {/* //mobile */}
      <div className="mx-auto items-center desktop:hidden largeLaptop:hidden laptop:hidden overflow-y-auto">
        <div className="flex flex-col gap-0">
          {reservationItems.map((item, index) => (
            <Link to={item.link} key={index}>
              <CardComponent
                isHover={false}
                className={`px-5 pt-4 h-[121px] bg-neutral/10 tablet:w-full mobile:w-full rounded-2xl mb-3
                from-neutral-900/50 via-neutral-900/50 to-secondary/20 ${
                  i18n.language === 'en' ? 'bg-gradient-to-br' : 'bg-gradient-to-bl'
                }
                ${isDarkMode ? ' border border-gray-600/50 ' : ' border border-neutral/50'}`}
              >
                <div className="text-white">
                  <div>
                    <h1
                      className={`-mt-0.5 pb-1${
                        i18n.language === 'ar'
                          ? ' text-right font-arMyriad text-[19px]'
                          : ' text-left w-56 font-helveticaMedium text-base'
                      }`}
                    >
                      {t(`${item.title}`)}
                    </h1>
                    <p
                      className={`text-xs h-8 pb-2 ${
                        i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad text-right'
                      }`}
                    >
                      {t(`${item.description}`)}
                    </p>
                  </div>
                </div>
              </CardComponent>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
