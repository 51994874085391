import { SkeletonEnum } from 'src/core/enums/skeleton.enum';

function SkeletonCard({
  size,
  isDarkMode,
}: {
  size?: SkeletonEnum;
  isDarkMode?: boolean;
}): JSX.Element {
  switch (size) {
    case SkeletonEnum.SMALL:
      return (
        <div
          className={
            'flex mb-3 h-[72px] w-[74px] animate-pulse items-center justify-center rounded-2xl bg-neutral-800 laptop:h-24 largeLaptop:h-[120px]'
          }
        />
      );
    case SkeletonEnum.LARGE:
      return (
        <div
          className={
            'flex h-[160px] w-[157px] laptop:h-[140px] animate-pulse items-center justify-center  bg-neutral-800  px-3 py-2 rounded-lg mobile:w-[350px] mobile:h-[180px]'
          }
        />
      );
    case SkeletonEnum.TEXT:
      return (
        <div className="flex flex-col gap-y-4 w-full">
          <div
            className={
              'flex h-5 w-[450px] animate-pulse items-center justify-center rounded-2xl bg-neutral-800'
            }
          />
          <div
            className={
              'flex h-5 w-44 animate-pulse items-center justify-center rounded-2xl bg-neutral-800'
            }
          />
        </div>
      );
    case SkeletonEnum.ICON:
      return (
        <div
          className={`flex h-9 w-9 animate-pulse items-center justify-center rounded-full ${isDarkMode ? 'bg-neutral-900' : 'bg-neutral-700'}`}
        />
      );
    case SkeletonEnum.TEXTSHORT:
      return (
        <div
          className={`h-5 w-full animate-pulse items-center justify-center rounded-2xl ${isDarkMode ? 'bg-neutral-900' : 'bg-neutral-700'}`}
        />
      );
    default:
      return <div></div>;
  }
}

export default SkeletonCard;
