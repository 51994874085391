/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface PopupCardProps {
  children: ReactElement;
  link?: string;
  icon?: JSX.Element;
  sidebar?: boolean;
}
function PopupCard({ children, icon, link, sidebar }: PopupCardProps) {
  const { i18n } = useTranslation();

  return (
    <div className={`w-full ${sidebar ? 'px-3' : 'px-0'}`}>
      <div className="h-full w-full largeLaptop:h-auto rounded-2xl p-2 border-1 mx-auto border border-gray-600/50 mobile:p-0 mobile:bg-transparent mobile:rounded-none mobile:border-none tablet:mt-6 tablet:bg-transparent tablet:rounded-none tablet:border-none">
        <div className="flex items-end justify-end pt-3 pb-2">
          <Link to={link!}>{icon}</Link>
        </div>
        <div
          className={`overflow-y-auto tablet:h-auto mobile:h-auto px-2 ${
            i18n.language === 'en'
              ? 'largeLaptop:h-[512px] laptop:h-[410px] h-[545px]'
              : 'largeLaptop:h-[520px] laptop:h-[407px] h-[550px]'
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default PopupCard;
