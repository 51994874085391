import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import LoginCardComponent from 'src/components/user/login-card-compoent';

import LoginFormContainer from './login-form-container';

function LoginContainer() {
  return (
    <div className="flex flex-col justify-center items-center mx-auto py-10 mobile:py-20 tablet:py-20">
      <LoginCardComponent>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}>
          <LoginFormContainer />
        </GoogleReCaptchaProvider>
      </LoginCardComponent>
    </div>
  );
}

export default LoginContainer;
